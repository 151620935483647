import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { log } from '@soundtrack/utils/log'
import { type FlagsResponse } from '#app/graphql/graphql'
import { tracking } from '#app/lib/tracking'
import { authSlice } from '#app/store/auth'
import type { AppThunk, RootState } from '#app/store/index'
import * as persistence from '#app/store/lib/redux-persistence'
import { createSelector } from '#app/store/redux'
import { FLAG_DEFINITIONS } from './definitions'
import {
  flagDefinitionsToEntries,
  flagOptions,
  flagTrackingProps,
  parseBackendFlag,
} from './lib'
import { applyFlag } from './lib/applyFlag'
import { FLAG_MAP } from './lib/constants'
import {
  type FlagEntries,
  type FlagName,
  type SelectFlagOptions,
} from './types'

export const DEFAULT_STATE = {
  /** The version for this store. Changing this will clear any persisted flags and replace with defaults + syncs from server */
  version: 3 as number,
  /** A kind of session key for the currently returned flags */
  trackingKey: null as string | null,
  /** These flag names were received from the server */
  serverFlags: [] as string[],
  /** A token used in the `applyFlags` mutation */
  resolveToken: null as string | null,
  /** When the flags are supposed to expire, evaluated when the app resumes */
  expiresAt: null as string | null,
  /** The actual flags and their values */
  entries: flagDefinitionsToEntries(FLAG_MAP),
} as const

type SliceState = typeof DEFAULT_STATE

interface SetFlagsPayload {
  flags: Partial<FlagEntries>
  meta?: {
    trackingKey?: SliceState['trackingKey']
    expiresAt?: SliceState['expiresAt']
    resolveToken?: SliceState['resolveToken']
  }
  isManualChange?: boolean
}

const slice = createSlice({
  name: 'flags',
  initialState: DEFAULT_STATE,

  reducers: {
    setFlags: (state, action: PayloadAction<SetFlagsPayload>) => {
      const { flags, meta, isManualChange = true } = action.payload

      if (isManualChange) {
        for (const flagName in flags) {
          const flag = flags[flagName]
          if (flagOptions(flagName).track) {
            tracking.track('Flags - Set Manually', flagTrackingProps(flag))
          }
        }
      }

      if (meta) {
        state.trackingKey = meta.trackingKey ?? state.trackingKey
        state.expiresAt = meta.expiresAt ?? state.expiresAt
        state.resolveToken = meta.resolveToken ?? state.resolveToken
      }

      // We know flags are fetched from the server when a `resolveToken` is passed
      if (action.payload.meta?.resolveToken) {
        state.serverFlags = Object.keys(flags)
      }

      Object.entries(flags).forEach(([key, value]) => {
        if (
          key in FLAG_MAP &&
          // Safe-guard so we don't accidentally update flag in store to null or primitive values
          (value as unknown) != null &&
          typeof value === 'object'
        ) {
          // Don't allow the server to overwrite flags that are "dirty" (have been changed client-side)
          if (!isManualChange && state.entries[key]?.state === 'dirty') {
            return
          }
          state.entries[key] = value
          if (isManualChange) {
            state.entries[key].state = 'dirty'
          }
        }
      })
    },

    toggleFlag: (state, action: PayloadAction<FlagName>) => {
      const flag =
        state.entries[action.payload] ?? FLAG_DEFINITIONS[action.payload]
      flag.props.enabled = !flag.props.enabled
      flag.state = 'dirty' // Toggling is always a manual action and marks the flag as being modified
      tracking.track('Flags - Toggled', flagTrackingProps(flag))
    },

    resetFlags: () => DEFAULT_STATE,
  },

  extraReducers: (builder) => {
    builder.addCase(persistence.actions.rehydrate, (state, action) => {
      if (action.payload.key !== 'syb:flags') {
        return state
      }

      // Check `version` on initial state and persisted state. If they don't match, use defaults.
      const old = JSON.parse(action.payload.state) as SliceState | undefined
      if (old && old.version !== DEFAULT_STATE.version) {
        return DEFAULT_STATE
      }

      // Flags that were persisted but are no longer defined in the client should be cleaned up,
      // as well as any flags that have been fetched more than 30 days ago but haven't been seen since.
      const now = new Date()
      const thirtyDaysAgo = new Date(now)
      thirtyDaysAgo.setDate(now.getDate() - 30)
      const thirtyDaysAgoISOString = thirtyDaysAgo.toISOString()
      state.entries = Object.fromEntries(
        Object.entries(state.entries).filter(
          ([, flag]) =>
            flag.name in FLAG_MAP &&
            (!flag.fetchedAt || flag.fetchedAt > thirtyDaysAgoISOString),
        ),
      ) as FlagEntries

      // We may have added new flags, make sure we add them to the state
      state.entries = {
        ...flagDefinitionsToEntries(FLAG_MAP),
        ...state.entries,
      }

      return state
    })
    builder.addCase(authSlice.actions.reset, () => {
      return DEFAULT_STATE
    })
  },
})

export const reducer = slice.reducer

export const actions = {
  ...slice.actions,

  setFlag<K extends FlagName, V extends FlagEntries[K]>(key: K, value: V) {
    return slice.actions.setFlags({
      flags: { [key]: value },
    })
  },

  setFlagsFromBackend(data: FlagsResponse): AppThunk {
    return (dispatch, getState) => {
      const { expiresAt, resolveToken, trackingKey } = data
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const flags = data.flags || []
      const flagsMap = flags.reduce(
        (acc, flagResponse) => {
          // Don't allow API to overwrite certain flags
          if (flagOptions(flagResponse.name).clientOnly) {
            return acc
          }

          // We parse the backend structure into one that's more ergonomic to work with
          const flag = parseBackendFlag(flagResponse)

          acc[flagResponse.name] = flag
          return acc
        },
        {} as Partial<FlagEntries>,
      )

      tracking.mixpanel?.register({
        'Flags Session ID': data.trackingKey,
      })

      dispatch(
        slice.actions.setFlags({
          flags: flagsMap,
          meta: { expiresAt, resolveToken, trackingKey },
          isManualChange: false,
        }),
      )
    }
  },
}

export default actions

const selectAllFlagEntries = createSelector(
  (state): FlagEntries => state.flags.entries,
  (flags): FlagEntries => ({ ...FLAG_MAP, ...flags }) satisfies FlagEntries,
)

/** Returns a single flag by name */
const selectFlagEntry = createSelector(
  [
    selectAllFlagEntries,
    (state, flagName: FlagName) => flagName,
    (state, flagName: FlagName, options?: SelectFlagOptions) =>
      options?.skipApply, // Invalidate the selector cache when this option changes
  ],
  (flagEntries, flagName, skipApply) => {
    if (skipApply !== true) {
      applyFlag(flagName)
    }
    return flagEntries[flagName]
  },
) as <T extends FlagName>(
  state: RootState,
  flagName: T,
  options?: SelectFlagOptions,
) => FlagEntries[T]

export const selectors = {
  /** Returns all flags, including default fallback */
  all: selectAllFlagEntries,

  /** Returns a single flag by name */
  flag: selectFlagEntry,

  /** Returns whether the passed flag is enabled */
  flagEnabled: (
    state: RootState,
    flagName: FlagName,
    options?: SelectFlagOptions,
  ): boolean => {
    const flagEntry = selectFlagEntry(state, flagName, options)
    if (flagEntry?.props && !('enabled' in flagEntry.props)) {
      log.warn(
        `[flags] Flag "${flagEntry.name}" is checked for being enabled, but it is missing the \`enabled\` property. Defaulting to \`false\``,
      )
    }
    return flagEntry?.props.enabled === true
  },

  expiresAt: (state: RootState) => state.flags.expiresAt,

  flagsToTrack: createSelector(selectAllFlagEntries, (flagEntries) => {
    return Object.values(flagEntries).filter(
      (flag) => flagOptions(flag.name).track,
    )
  }),
}
