import { apollo, graphql } from '#app/apollo/index'
import { t } from '#app/lib/i18n/index'
import { actions as musicLibraryActions } from '#app/lib/music-library'
import toast from '#app/lib/toast'
import { store } from '#app/store/index'
import { addTracksToPlaylist } from '#app/store/playlist-editor-updatePartialTracklist'
import type { useDispatch } from '#app/store/redux'
import {
  actions,
  selectors as spotifySelectors,
} from '#app/store/spotify-token'
import { type DNDItem, type DNDItemType } from './schema'

/**
 * The drop item handler takes care of the mapping between item types (Tracks, Playlists, Schedules, etc) and
 * the corresponding handler for dropping items of that type on it.
 *
 * Extend it with the allowed drop types for each target type.
 */

type DropHandlerMap = Partial<Record<DNDItemType, DropHandler | undefined>>

export type DropHandlerOptions = {
  dispatch: ReturnType<typeof useDispatch>
  trackingContext: object
}

type DropHandler = (
  target: DNDItem,
  items: string[],
  options: DropHandlerOptions,
) => void

/**
 * Get the drop handlers for a target type.
 */
export function getDropHandlers(targetType: DNDItemType): DropHandlerMap {
  switch (targetType) {
    case 'Playlist':
      return {
        Track: dropTracksOnPlaylist,
      }
    case 'Library':
      return {
        Playlist: addPlaylistToLibrary,
        SpotifyPlaylists: addSpotifyPlaylistsToLibrary,
      }
  }

  return {}
}

/**
 * Add drop handlers below
 */
const dropTracksOnPlaylist: DropHandler = (target, tracks, { dispatch }) => {
  dispatch(
    addTracksToPlaylist({
      trackIDs: tracks,
      playlistID: target.id,
    }),
  )
}

const addPlaylistToLibrary: DropHandler = (target, playlists, { dispatch }) => {
  if (playlists.length === 0) {
    return
  }

  return dispatch(
    musicLibraryActions.add(playlists[0]!, {
      libraryId: target.id,
    }),
  )
}

const addSpotifyPlaylistsToLibrary: DropHandler = async (
  target,
  playlists,
  { dispatch, trackingContext = {} },
) => {
  const spotifyToken = spotifySelectors.token(store.getState())

  let connectionId = spotifyToken?.connectionId

  if (!connectionId) {
    const result = await dispatch(
      actions.createSpotifyConnection(trackingContext),
    )

    connectionId =
      typeof result.payload === 'object' && result.payload.connectionId
  }

  if (playlists.length === 0 || !connectionId) {
    return
  }

  toast.promise(
    apollo.mutate({
      mutation: ImportSpotifyPlaylistsByDragDoc,
      variables: {
        playlistUris: playlists,
        connectionId,
      },
    }),
    {
      loading: t('yourMusic.toasts.addingSpotifyPlaylist', false),
      success: t('yourMusic.toasts.added', false),
      error: t('yourMusic.toasts.failedToImportSpotifyPlaylistError', false),
    },
  )
}

const ImportSpotifyPlaylistsByDragDoc = graphql(/* GraphQL */ `
  mutation ImportSpotifyPlaylistsByDrag(
    $connectionId: String!
    $playlistUris: [String!]!
  ) {
    importSpotifyPlaylists(
      connectionId: $connectionId
      spotifyPlaylistUris: $playlistUris
      addToLibrary: true
    ) {
      playlist {
        id
      }
      errors {
        errorCode
        errorMessage
      }
    }
    traceId
  }
`)
