const $$splitComponentImporter = () => import(/* webpackChunkName: "route-setup-guide" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/setup-guide/route.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
const $$splitLoaderImporter = () => import(/* webpackChunkName: "route-setup-guide" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/setup-guide/route.tsx?tsr-split');
import { lazyFn } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { ensureLoggedIn } from '#app/route-lib';
export const Route = createFileRoute('/setup-guide')({
  beforeLoad: ({
    location
  }) => {
    ensureLoggedIn(location);
  },
  loader: lazyFn($$splitLoaderImporter, 'loader'),
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr)
});