const $$splitComponentImporter = () => import(/* webpackChunkName: "route-create" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/create/ai-station.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';
import { ensureLoggedIn, ensureNotOnStarterPlan } from '#app/route-lib';
export const Route = createFileRoute('/create/ai-station')({
  validateSearch: zodSearchValidator(z.object({
    prompt: fallback(z.string(), '').optional()
  })),
  staticData: {
    meta: 'marvinPlaylistFromPrompt.stationMeta'
  },
  beforeLoad: ({
    location,
    context
  }) => {
    ensureLoggedIn(location);
    ensureNotOnStarterPlan(context.store);
  },
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr)
});