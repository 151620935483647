import { scope } from '@soundtrack/utils/log'
import { type ParsedLocation, redirect } from '@tanstack/react-router'
import React from 'react'
import overrides from '#app/lib/overrides'
import { type Store, store } from '#app/store/index'
import { authSlice, currentAccountSlice, tysonSlice } from '#app/store/reducers'
import { useSelector } from './store/redux'

const log = scope('route-lib')
log.level = 'info'

/**
 * Call this function in the `beforeLoad()` of a route to redirect them to the
 * login page if they are not logged in.
 *
 * Disclaimer: The route component should be wrapped with {@link loggedInGuard}
 * to ensure that it gets unmounted immediately after user is logged out.
 * Otherwise the component can re-render while logged out and trigger unexpected
 * side effects, such as HTTP requests without a valid auth token.
 *
 * @example
 * ```ts
 * import { ensureLoggedIn, loggedInGuard } from '#app/lib/route-lib'
 * export const Route = createFileRoute('/user')({
 *   beforeLoad: ({ location }) => {
 *     ensureLoggedIn(location)
 *   },
 *   component: loggedInGuard(UserSettings),
 * })
 * ```
 */
export function ensureLoggedIn(location: ParsedLocation) {
  return loggedInOr(IS_ELECTRON ? '/electron/login' : '/login', location)
}

/**
 * Wrap a React component with this function to ensure that the component is
 * only rendered while logged in. Renders nothing when logged out.
 * Use {@link ensureLoggedIn} to redirect users away from the route.
 */
export function loggedInGuard<Props extends {}>(
  Component: React.ComponentType<Props>,
): React.FunctionComponent<Props> {
  LoggedInGuard.displayName = `LoggedInGuard(${Component.displayName || Component.name || '?'})`
  function LoggedInGuard(props: Props) {
    const loggedIn = useSelector(authSlice.selectors.loggedIn)
    return loggedIn ? React.createElement(Component, props) : null
  }
  return LoggedInGuard
}

// Get type of first param of `redirect` function
type RedirectOptions = Parameters<typeof redirect>[0]

export function loggedInOr(
  to: string | RedirectOptions,
  location: ParsedLocation,
  /** Whether the current pathname should strictly match passed `to` */
  strictRouteMatch = false,
) {
  // If logged in as a user, we don't need to check anything else
  if (authSlice.selectors.loggedIn(store.getState())) {
    return
  }

  // In Electron we should consider being "logged in" if we're paired with a code
  if (IS_ELECTRON) {
    const playerId = overrides.get<string>('player-id')
    if (playerId && tysonSlice.selectors.paired(playerId)(store.getState())) {
      return
    }
  }

  const options: RedirectOptions = !to || typeof to === 'string' ? { to } : to

  const isAlreadyAtPath = strictRouteMatch
    ? location.pathname === options.to
    : options.to && location.pathname.startsWith(options.to)
  if (isAlreadyAtPath) {
    log.debug(`loggedInOr: Already at expected path ${to}`)
    return
  }

  overrides.set('redirect-to', location.href)
  log.debug(`loggedInOr: redirecting to ${to}`)

  throw redirect({
    replace: true,
    ...options,
    search: options.search,
  })
}

export function loggedOutOr(to: string) {
  if (authSlice.selectors.loggedIn(store.getState())) {
    log.debug(`loggedOutOr: redirecting to ${to}`)
    throw redirect({ to, replace: true })
  }
}

export function ensureNotOnStarterPlan(store: Store) {
  const isOnStarterPlan =
    currentAccountSlice.selectors.plan(store.getState()) === 'starter'

  if (isOnStarterPlan) {
    throw redirect({
      to: '/create',
      replace: true,
    })
  }
}

/**
 * When defining params parsing the `stringify` methods is required.
 * If all params are already strings, we can simply pass these through.
 */
export function noopStringify(
  params: Record<string, string>,
): Record<string, string> {
  return params
}
