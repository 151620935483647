import { Models } from '@soundtrackyourbrand/capsule'
import {
  prepareExperiment as prepare,
  storedVariation,
} from '@soundtrackyourbrand/experiments.js'
import { getCookie } from '@soundtrackyourbrand/tracking.js'
import { selectors as currentAccount } from '#app/store/current-account'
import overrides from './overrides'

const { isSYB } = Models.Account

export const ORIGINAL = 'original'
export const TREATMENT = 'treatment'
export const NOT_PARTICIPATING = 'not-participating'

const VARIATIONS = [ORIGINAL, TREATMENT, NOT_PARTICIPATING]

const randomParticipant = Math.random().toString(36).substring(2)

const e2eVariantSelector = (variant: string) => () =>
  overrides.get<boolean>('is-e2e') ? variant : undefined

export const skipOnboardingEmails = prepare({
  name: 'GE63: Skip Onboarding Emails',
  variations: [ORIGINAL, TREATMENT],
  variationSelector: e2eVariantSelector(ORIGINAL),
  participantSelector: () => randomParticipant,
})

export const extendedTrial = prepare({
  name: 'GE65: 30-day trial',
  variations: [ORIGINAL, TREATMENT, NOT_PARTICIPATING],
  weights: [1, 1, 0],
  variationSelector: (state, params) => {
    if (overrides.get('is-e2e')) return NOT_PARTICIPATING
    if (overrides.get('voucher')) return NOT_PARTICIPATING
    return params.stored
  },
  participantSelector: currentAccount.id,
})

export const expertRE13 = prepare({
  name: 'RE13: Product Expert - 6+ locations v3',
  variations: [ORIGINAL, TREATMENT, NOT_PARTICIPATING],
  weights: [1, 1, 0],
  variationSelector: (state, params) => {
    // No e2e users
    if (overrides.get<boolean>('is-e2e')) return NOT_PARTICIPATING

    const account = currentAccount.account(state)
    const distributorSYB = isSYB(account?.get('distributor'))
    // No users with external distributor - distributor handle their support
    if (!distributorSYB) return NOT_PARTICIPATING

    return params.stored
  },
  participantSelector: currentAccount.id,
})

export const expertRE14 = prepare({
  name: 'RE14: Product Expert - UX research v3',
  variations: [ORIGINAL, TREATMENT, NOT_PARTICIPATING],
  weights: [9, 1, 0],
  variationSelector: (state, params) => {
    if (overrides.get<boolean>('is-e2e')) return NOT_PARTICIPATING

    const account = currentAccount.account(state)
    const distributorSYB = isSYB(account?.get('distributor'))
    // No users with external distributor - distributor handle their support
    if (!distributorSYB) return NOT_PARTICIPATING

    return params.stored
  },
  participantSelector: currentAccount.id,
})

export const expertRE15 = prepare({
  name: 'RE15: Product Expert - 1 location',
  variations: [ORIGINAL, TREATMENT, NOT_PARTICIPATING],
  weights: [1, 1, 0],
  variationSelector: (state, params) => {
    // No e2e users
    if (overrides.get<boolean>('is-e2e')) return NOT_PARTICIPATING
    const account = currentAccount.account(state)
    if (!isSYB(account?.get('distributor'))) return NOT_PARTICIPATING // External distributors handle support
    return params.stored
  },
  participantSelector: currentAccount.id,
})

export const sideNavLayout = prepare({
  name: 'MX: Side nav layout',
  // TREATMENT got accidentally assigned to many users and now behaves as NOT_PARTICIPATING
  variations: [ORIGINAL, TREATMENT, NOT_PARTICIPATING, 'employee-opt-in'],
  weights: [0, 0, 1, 0],
  variationSelector: e2eVariantSelector(NOT_PARTICIPATING),
  participantSelector: currentAccount.id,
})

export const noSpotifyImportForB2B = prepare({
  name: 'GE72: Remove Import from Spotify step for B2B group',
  variations: [ORIGINAL, TREATMENT, NOT_PARTICIPATING],
  weights: [1, 1, 0],
  variationSelector: e2eVariantSelector(NOT_PARTICIPATING),
  participantSelector: currentAccount.id,
})

export const starterPlan = prepare({
  name: 'RE09: Starter Plan',
  variations: [ORIGINAL, TREATMENT, NOT_PARTICIPATING],
  weights: [0, 0, 0],
  participantSelector: currentAccount.id,
})

export const starterPlanV2 = prepare({
  name: 'RE10: Starter Plan',
  variations: [ORIGINAL, TREATMENT, NOT_PARTICIPATING],
  weights: [0, 1, 0],
  variationSelector: (state, params) => {
    const account = currentAccount.account(state)
    const oldVariation = storedVariation(state, starterPlan.name)
    const billingVoucher = account?.get('billing_voucher')
    if (oldVariation === ORIGINAL || billingVoucher) {
      return NOT_PARTICIPATING
    }
    // Ensures that the variation assigned matches the one from the storefront
    const expCookie = getCookie('syb.exp') || ''
    const variation = expCookie
      .split(';')
      .find((e) => e.startsWith('RE10: Starter Plan'))
      ?.split('~')[1]

    if (variation && VARIATIONS.includes(variation)) {
      return variation
    }
    return params.stored
  },
  participantSelector: currentAccount.id,
})
