// This module can and should be completely replaces with the Temporal when
// it exists, for now it missuse the Intl formatting APIs to get access to
// the browsers time zone database

const offsetPattern = /GMT([+-])(\d{2}):(\d{2})/

/**
 * Return the offset of the specified timezone in minutes from UTC
 */
export function getTimezoneOffset(date: Date, timeZone: string) {
  // Force en-US to get a stable offset format to parse
  const local = new Intl.DateTimeFormat('en-US', {
    timeZone,
    timeZoneName: 'longOffset',
  })
  const offsetString = local
    .formatToParts(date)
    .find((p) => p.type === 'timeZoneName')
  if (!offsetString) return 0
  const [, sign, hours, minutes] = offsetString.value.match(offsetPattern) ?? []
  let offset = +hours * 60 + +minutes || 0
  if (sign === '-') {
    offset *= -1
  }

  return offset
}

/**
 * Mutates date so that it is at the start of the day it's currently at, in the
 * passed time zone
 */
export function makeStartOfDay(date: Date, timeZone: string) {
  const offset = getTimezoneOffset(date, timeZone)
  const minutesFromMidnight = date.getUTCHours() * 60 + date.getUTCMinutes()
  const error = (minutesFromMidnight + offset) % 1440
  date.setUTCMinutes(date.getUTCMinutes() - error, 0, 0)
  return date
}

/**
 * Checks if passed dates are on the same day
 */
export function isSameDay(a: Date, b: Date) {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  )
}

/**
 * Returns the number of days between a and b, returns a negative value
 * if b is later than a. Returns NaN if a or b is invalid.
 */
export function diffDays(a: Date, b: Date) {
  const val = Math.round((+a - +b) / (1000 * 60 * 60 * 24))
  // Avoids -0
  if (val === 0) return 0
  return val
}
