import * as storage from '@soundtrackyourbrand/browser-storage.js'
import { getCookie, setCookie } from '@soundtrackyourbrand/tracking.js'
import queryString from 'query-string'

const data = {}

type PrimitiveValue = string | number | null | boolean | undefined
type Source = {
  get: (key: string) => PrimitiveValue
  set: (key: string, value: PrimitiveValue) => void
  remove: (key: string) => void
}

const localSource: Source = {
  get: (k) => storage.local.getItem(k),
  set: (k, v) => storage.local.setItem(k, v),
  remove: (k) => storage.local.removeItem(k),
}

const SOURCES = {
  default: localSource,
  cookie: {
    get: (k) => getCookie(k),
    set: (k, v) => setCookie(k, v),
    remove: (k) => setCookie(k, null),
  },
  cookie1h: {
    get: (k) => getCookie(k),
    set: (k, v) => setCookie(k, v, false, '1h'),
    remove: (k) => setCookie(k, null),
  },
  local: localSource,
  session: {
    get: (k) => storage.session.getItem(k),
    set: (k, v) => storage.session.setItem(k, v),
    remove: (k) => storage.session.removeItem(k),
  },
  memory: {
    get: () => null,
    set: () => null,
    remove: () => null,
  },
} satisfies Record<string, Source>

export const MAP = {
  /** Tells storefront that user has logged in */
  session: SOURCES.cookie,
  /** Voucher during signup */
  voucher: SOURCES.cookie,
  /** Tier preference during signup */
  tier: {
    get: (key) => {
      const value = SOURCES.session.get(key)
      const numberMatch = /(?:tier-?)?([123])/.exec(value || '')
      return numberMatch ? 'tier-' + numberMatch[1] : ''
    },
    set: (key, value) => {
      //s Accept format `tier-X`, `tierX`, or simply `X`
      const numberMatch = /(?:tier-?)?([123])/.exec(value || '')
      return SOURCES.session.set(key, 'tier-' + numberMatch?.[1])
    },
    remove: () => SOURCES.session.remove('tier'),
  },
  /** Billing cycle during signup */
  'billing-cycle': SOURCES.session,
  /** Plan during first checkout */
  plan: SOURCES.session,
  /** Overrides store.country */
  country: SOURCES.cookie1h,
  /** Language */
  lang: SOURCES.cookie,
  /** Experiment assignment */
  exp: SOURCES.memory,
  /** Return url set when redirecting to /login */
  'redirect-to': SOURCES.session,
  /** Return sso token */
  'sso-token': SOURCES.memory,
  /** Query parameter to force hide header+footer and sidebar layout when =0 */
  chrome: SOURCES.memory,
  /** Query parameter to force hide sidebar when =0 */
  sidebar: SOURCES.memory,
  /** Query parameter to force hide header when =0 */
  header: SOURCES.memory,
  /** Enables dark mode */
  'mono-container': SOURCES.memory,
  /** Set to true when in pairing-guide onboarding */
  'pairing-guide': SOURCES.session,
  /** Vendor of the current connect flow */
  'connect-vendor': SOURCES.session,
  /** Used with v1 pairing flow (referral=sonos or referral=linkplay). Deprecated for sonos */
  'pairing-code': SOURCES.session,
  /** Used with referral=generic */
  'general-redirect-url': SOURCES.session,
  /** Used with referral=linkplay */
  'linkplay-app-id': SOURCES.session,
  /** Used with referral=sonos (v2 flow) */
  'sonos-household-id': SOURCES.session,
  /** Used with referral=sonos (v2 flow) */
  'sonos-linking-code': SOURCES.session,
  /** Timestamp when a reload has been attempted to recover from an error */
  'reloaded-at': SOURCES.session,
  /** Remember last locations view type */
  'locations-viewtype': SOURCES.session,
  /** Remember if NoAccountModal has already been dismissed */
  'no-accounts-dismissed': SOURCES.session,
  /** Simulate adyen payment error */
  'test-purchase-error': SOURCES.memory,
  /** Allows passing in an auth token to immediately login */
  'auth-token': SOURCES.memory,
  /** Overrides the platform/contentful-config for editorial APIs */
  platform: SOURCES.memory,
  /** Overrides the whole editorial agent used for editorial APIs */
  'editorial-agent': SOURCES.memory,
  /** Skip caching of editorial APIs */
  nocache: SOURCES.memory,
  /** Sets the x-canary header on requests */
  canary: SOURCES.memory,
  /** Makes editorial cards use the raw API color as background */
  'raw-api-color': SOURCES.memory,
  'no-optimistic-updates': SOURCES.memory,
  'player-id': SOURCES.session,
  /** Useful for disabling the new user ID migration */
  'legacy-user-id': SOURCES.session,
  'remote-zone-id': SOURCES.session,
  /** Used with redirects from storefront, typically in onboarding */
  'ai-playlist-prompt': SOURCES.session,
  'is-e2e': {
    get: (key: string) => {
      if (typeof key !== 'string') {
        return false
      }
      return ['yes', 'true'].includes(SOURCES.session.get(key))
    },
    set: SOURCES.session.set,
    remove: SOURCES.session.remove,
  },
  /** Passed as `session` to Flags API headers so we can assign variants to logged out users */
  'session-id': SOURCES.cookie,
} as const

type MapKey = keyof typeof MAP

export function detect() {
  const query = queryString.parse(window.location.search)
  ;(Object.keys(MAP) as MapKey[]).forEach((opt) => {
    const value = query[opt]

    // Only primitive values are supported
    if (Array.isArray(value)) {
      return
    }

    if ((value as unknown) !== undefined) {
      set(opt, value === null ? '1' : value)
    } else {
      get(opt)
    }
  })

  return data
}

export function get<T extends PrimitiveValue>(opt: MapKey): T | undefined {
  return data[opt] === undefined
    ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (data[opt] = (MAP[opt] || SOURCES.default).get(opt) || null)
    : data[opt]
}

export function set<T extends PrimitiveValue>(opt: MapKey, value: T): T {
  data[opt] = value
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  ;(MAP[opt] || SOURCES.default).set(opt, value)
  return value
}

export function remove(opt: MapKey) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const def = MAP[opt] || SOURCES.default
  const value = data[opt] === undefined ? def.get(opt) : data[opt]
  delete data[opt]
  def.remove(opt)
  return value
}

export default {
  detect,
  get,
  set,
  remove,
  SOURCES,
  MAP,
}
