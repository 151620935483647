import { storedVariation } from '@soundtrackyourbrand/experiments.js';
import { useLocation, useRouter } from '@tanstack/react-router';
import expert_1323598535 from '#app/assets/product-expert/expert_1323598535.jpeg';
import expert_1501604298 from '#app/assets/product-expert/expert_1501604298.jpeg';
import expert_1578088666 from '#app/assets/product-expert/expert_1578088666.png';
import { expertRE13, expertRE14, expertRE15 } from '#app/lib/experiments';
import { selectors as accountSelectors } from '#app/store/current-account';
import { useSelector } from '#app/store/redux';
export const EXPERIMENT_CONFIG = {
  RE13: expertRE13,
  RE14: expertRE14,
  RE15: expertRE15
};
export const EXPERTS = {
  jennie: {
    calenderURL: 'https://meetings-eu1.hubspot.com/jennie-andren',
    name: 'Jennie',
    expertId: '1501604298',
    actionKeys: ['ama', 'meeting'],
    image: expert_1501604298,
    featureFlagId: 'product-expert-1501604298'
  },
  RE15: {
    // `EXPERTS.jennie`, duplicated as RE15, shows same card for 1+ loc accounts
    calenderURL: 'https://meetings-eu1.hubspot.com/jennie-andren',
    name: 'Jennie',
    expertId: '1501604298',
    actionKeys: ['ama', 'meeting'],
    image: expert_1501604298,
    featureFlagId: 'product-expert-1501604298'
  },
  RE13: {
    calenderURL: 'https://meetings-eu1.hubspot.com/carl-pettersson',
    name: 'Carl',
    actionKeys: ['ama', 'meeting'],
    expertId: '1323598535',
    featureFlagId: 'product-expert-1323598535',
    image: expert_1323598535
  },
  RE14: {
    href: 'https://calendar.app.google/RMuQdR9mWYWDBDTu8',
    expertId: '1578088666',
    featureFlagId: 'product-expert-1578088666',
    name: 'Erik',
    image: expert_1578088666,
    actionKeys: ['feedback', 'googleCalendar']
  }
};

/**
 * Prevent rendering lower prio cards when risking multiple cards on shared experiment routes.
 * This also avoids triggering misleading Mixpanel events on shared routes.
 * If routes change, condition should be updated/replaced by more sustainable solution handling floating card positioning or card prio/hierarchy.
 */
export function useCardCollides(id, potential) {
  const router = useRouter();
  const location = useLocation();
  const isRE13 = useSelector(state => storedVariation(state, EXPERIMENT_CONFIG.RE13.name)) === 'treatment' && id === 'RE13';
  const hasRE14 = useSelector(state => storedVariation(state, EXPERIMENT_CONFIG.RE14.name)) === 'treatment';
  const isRE15 = useSelector(state => storedVariation(state, EXPERIMENT_CONFIG.RE15.name)) === 'treatment' && id === 'RE15';
  if (!isRE13 && !isRE15) return false;
  const routeId = router.getMatch(location.pathname)?.routeId;
  if (isRE13 && hasRE14) return routeId === '/home';
  return !!(isRE15 && potential > 1 && !!routeId.startsWith('/setup-guide')); // When RE15 and potential is higher than 1, the user should see the regular card for setup guide instead of rendering the experiment card
}
export function useLaunchAsExperiment(config, potential) {
  const isRE13 = useSelector(state => storedVariation(state, EXPERIMENT_CONFIG.RE13.name)) === 'treatment';
  const account = useSelector(accountSelectors.account);
  if (!config.isExperiment) return false;
  if (!account) return false;
  // Created during the past 24 hours?
  const isNew = new Date(account.get('created_at')).valueOf() - Date.now() < 24 * 60 * 60e3;
  switch (config.id) {
    case 'RE15':
      return potential === 1;
    case 'RE13':
      return !account.getIn(['feature_flags', 'onboarding_enabled']);
    case 'RE14':
      return isRE13 && !isNew;
    default:
      return true;
  }
}
export function useHasPotential(configPotential, accountPotential) {
  return accountPotential >= configPotential;
}
/** Update useCardCollides if changing routes */
export const REQS = [{
  id: 'jennie',
  isExperiment: false,
  potential: 2,
  routes: ['/setup-guide']
}, {
  id: 'RE15',
  isExperiment: true,
  potential: 1,
  routes: ['/setup-guide']
}, {
  id: 'RE13',
  isExperiment: true,
  potential: 6,
  routes: ['/home', '/search', '/create', '/your-music', '/accounts/$accountId/your-music/playlists', '/accounts/$accountId/your-music/schedules', '/accounts/$accountId/settings/overview', '/accounts/$accountId/settings/subscriptions', '/accounts/$accountId/settings/billing', '/accounts/$accountId/settings/users', '/accounts/$accountId/zones', '/messaging', '/settings']
}, {
  id: 'RE14',
  isExperiment: true,
  potential: 6,
  routes: ['/home']
}];