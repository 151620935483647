import type { ApolloClient } from '@apollo/client'
import {
  type AnyAction,
  type PayloadAction,
  createSlice,
} from '@reduxjs/toolkit'
import { desktopSlice } from '@soundtrack/desktop-shared-components/desktopSlice'
import { type DesktopTypes } from '@soundtrack/desktop-shared-types'
import { Selectors } from '@soundtrackyourbrand/capsule'
import type { UserAgent } from '@soundtrackyourbrand/capsule/dist/utils/editorial/headers'
import { graphql } from '#app/graphql/index'
import base64 from '#app/lib/base64'
import overrides from '#app/lib/overrides'
import { isManualPlaylist } from '#app/lib/source'
import { formatTimezone } from '#app/lib/timezone'
import { createAndStoreUUIDv4 } from '#app/lib/uuidv4'
import { createSelector } from '#app/store/redux'
import type { RootState } from '.'
import { authSlice } from './auth'
import { selectors as currentAccount } from './current-account'
import { startReduxListening } from './middleware/listener'

const initialPlayerConfig: undefined | DesktopTypes.PlayerConfig = window.Tyson
  ? { type: 'local' }
  : overrides.get('remote-zone-id')
    ? {
        type: 'remote',
        zoneId: overrides.get('remote-zone-id') as string,
      }
    : undefined

type ChurnFeedback = {
  category?: string
  reason?: string
  detailedText?: string
  reactivateAt?: Date
}

const slice = createSlice({
  name: 'misc',

  initialState: {
    /** Height of the desktop app titlebar, if applicable. */
    titleBarHeight: 0,
    /** Height of the sticky header. */
    headerHeight: 0,
    lastUpdatedPlaylistId: null as string | null,
    locationsQuery: {} as Record<string, any>,
    churnFeedback: {} satisfies ChurnFeedback as ChurnFeedback,
    /** In the player, do we show the total track duration, or time remaining? */
    playerShowTimeRemaining: false,
    playerConfig: initialPlayerConfig,
    /**
     * Override the current market in track lists to show matchrate (unavailable
     * songs in that market)
     */
    marketOverride: null as string | null,
  },

  reducers: {
    updateTitleBarHeight: (state, action: PayloadAction<number>) => {
      state.titleBarHeight = action.payload
    },

    updateHeaderHeight: (state, action: PayloadAction<number>) => {
      state.headerHeight = action.payload
    },

    updateLocationsQuery: (
      state,
      action: PayloadAction<{ query: Record<string, any>; replace?: boolean }>,
    ) => {
      const { query, replace = false } = action.payload
      state.locationsQuery = replace
        ? query
        : { ...state.locationsQuery, ...query }
    },

    setLastUpdatedPlaylistId: (state, action: PayloadAction<string>) => {
      state.lastUpdatedPlaylistId = action.payload
    },

    setChurnFeedback: (state, action: PayloadAction<ChurnFeedback>) => {
      state.churnFeedback = action.payload
    },

    setPlayerShowTimeRemaining: (state, action: PayloadAction<boolean>) => {
      state.playerShowTimeRemaining = action.payload
    },

    setPlayerConfig(
      state,
      action: PayloadAction<DesktopTypes.PlayerConfig | undefined>,
    ) {
      state.playerConfig = action.payload
    },

    updateMarketOverride: (state, action: PayloadAction<string | null>) => {
      state.marketOverride = action.payload || null
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type === 'SELECT_ACCOUNT' || action.type === 'UNSELECT_ACCOUNT',
      (state) => {
        state.locationsQuery = {}
      },
    )
  },
})

startReduxListening({
  type: 'REQUEST_TRACKLIST_UPDATE_SUCCESS',
  effect: (action: AnyAction, { dispatch, getState }) => {
    const globalState = getState()
    const id = action.query.collection
    const collection = globalState.entities.collection.getIn(['data', id])
    if (isManualPlaylist(collection)) {
      dispatch(actions.setLastUpdatedPlaylistId(id))
    }
  },
})

export const { reducer } = slice
export const actions = {
  // this works around this bug:
  // https://github.com/reduxjs/redux-toolkit/issues/1806
  // it seems to be a bug with our current @reduxjs/toolkit version but it is not fixed yet as of v2.2.7
  ...slice.actions,
}

export default actions

const LastUpdatedPlaylistMetaFragment = graphql(/* GraphQL */ `
  fragment Playlist_LastUpdatedMeta on Playlist {
    id
    name
    composerType
  }
`)

interface AppUserAgent extends Omit<UserAgent, 'country'> {
  session?: string
  /** Country is set to required in Capsule but in reality it's not always set.. */
  country?: string
}

export const selectors = {
  titleBarHeight: (state: RootState) => state.misc.titleBarHeight,
  headerHeight: (state: RootState) => state.misc.headerHeight,
  /** Get all the sticky content up top. */
  topStickyContentHeight: (state: RootState) =>
    state.misc.titleBarHeight + state.misc.headerHeight,
  locationsQuery: (state: RootState) => state.misc.locationsQuery,
  churnFeedback: (state: RootState) => state.misc.churnFeedback,
  lastUpdatedPlaylist: (apollo: ApolloClient<any>) => (state: RootState) => {
    const id = state.misc.lastUpdatedPlaylistId
    if (!id) return null
    const metadata = apollo.readFragment({
      id,
      fragment: LastUpdatedPlaylistMetaFragment,
    })
    return metadata?.composerType === 'manual' ? metadata : null
  },
  playerShowTimeRemaining: (state: RootState) =>
    state.misc.playerShowTimeRemaining,
  editorialAgent: createSelector(
    [
      Selectors.currentUserId,
      currentAccount.id,
      authSlice.selectors.zoneId,
      currentAccount.tier,
      currentAccount.country,
      currentAccount.businessType,
      desktopSlice.selectors.debugFlags,
    ],
    (
      userId: string | undefined,
      accountId,
      zoneId,
      tier,
      country,
      businessType,
      debugFlags,
    ): AppUserAgent => {
      const platformOverride =
        debugFlags.platformOverride ??
        (overrides.get('platform') as string | undefined)
      const agent = getEditorialAgentOverride()
      if (agent) {
        return agent
      }

      return {
        platform:
          platformOverride ||
          (SYB.env === 'production'
            ? 'business' + (tier === 3 ? '' : '-t2')
            : 'test-staging-platform'),
        platformVersion: SYB.version,
        locale: 'EN',
        tz: formatTimezone(),
        country,
        account: accountId,
        user: userId,
        soundZone: zoneId,
        businessType,
        /** This makes us able to target visitors in the Flags API (Confidence) before they have signed up.
        As long as they don't clear cookies they should keep the same treatment assignment.
        Currently this comes with a limitation of not being to able to link the session.id in Confidence
        to a distinct_id from Mixpanel. Going forward we should consider finding a solution where we could
        potentially re-use the distinct_id from mixpanel as the session.id in Confidence for better analysis. */
        session: overrides.get('session-id') || createAndStoreUUIDv4(),
      } satisfies AppUserAgent
    },
  ),
  playerConfig: (state: RootState) => state.misc.playerConfig,
  marketOverride: (state: RootState) => state.misc.marketOverride,
}

function getEditorialAgentOverride() {
  const rawAgent = overrides.get<string>('editorial-agent')
  if (!rawAgent) {
    return null
  }
  if (rawAgent) {
    try {
      const json = base64.decode(rawAgent)
      const agent = JSON.parse(json)
      return agent
    } catch (e) {
      console.error('Failed to parse editorial agent', rawAgent, e)
      return null
    }
  }
}
