// Source: https://stackoverflow.com/a/9851769/1527562
const { userAgent } = window.navigator

/**
 * values specific to certain browsers that are not covered by typescript
 * type definitions
 */
// @ts-ignore redux-devtools adds @types/chrome to the project, but it is not actually available in other platforms
const windowFlexible = window as Omit<typeof window | 'chrome'> &
  Record<string, any> & {
    // redux-devtools adds @types/chrome to the project, but it is not actually available in other platforms
    // @ts-ignore
    chrome: undefined | typeof window.chrome
  }

export const isAppleDevice = /iPhone|iPad|iPod|Macintosh/i.test(userAgent)

export const isMac = /Macintosh/i.test(userAgent)

export const isIOS =
  /iPhone|iPad|iPod/i.test(userAgent) && !windowFlexible.MSStream

export const isAmazon = /Silk/i.test(userAgent)

export const isAndroid = /Android/i.test(userAgent)

export const isWindows = /Win/i.test(userAgent)

export const isChromeOS = /\bCrOS\b/.test(userAgent)

// Opera 8.0+
export const isOpera =
  (!!windowFlexible.opr && !!windowFlexible.opr.addons) ||
  !!windowFlexible.opera ||
  userAgent.indexOf(' OPR/') >= 0

// Firefox 1.0+
export const isFirefox = typeof windowFlexible.InstallTrigger !== 'undefined'

// Safari 3.0+
export const isSafari = /\bVersion\/([\d._]+).*\b(Mobile.*)?Safari\b/.test(
  userAgent,
)

// Internet Explorer 6-11
export const isIE = /MSIE |Trident\//.test(userAgent)

// Edge 20+
export const isEdge = !isIE && !!windowFlexible.StyleMedia

// Chrome 1+
export const isChrome =
  !!windowFlexible.chrome &&
  (!!windowFlexible.chrome.webstore || !!windowFlexible.chrome.runtime)

// Blink engine detection
export const isBlink = (isChrome || isOpera) && !!window.CSS

// Silk (amazon devices browser)
// https://docs.aws.amazon.com/silk/latest/developerguide/user-agent.html
export const isSilk = /\bSilk\b/.test(userAgent)

/* eslint-disable @typescript-eslint/no-unnecessary-condition */

export const browserName =
  (isChrome && 'Chrome') ||
  (isEdge && 'Edge') ||
  (isIE && 'Internet Explorer') ||
  (isSafari && 'Safari') ||
  (isFirefox && 'Firefox') ||
  (isOpera && 'Opera') ||
  (isSilk && 'Silk') ||
  null

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#Mobile_Tablet_or_Desktop
export const isMobile = /Mobi/i.test(userAgent)

export const devicePlatform =
  (isSilk && 'amazon') ||
  (isAndroid && 'android') ||
  (isIOS && 'ios') ||
  (isMac && 'macos') ||
  (isWindows && 'windows') ||
  null

const reducedMotionMedia = window.matchMedia('(prefers-reduced-motion: reduce)')
export const prefersReducedMotion = () => reducedMotionMedia.matches
