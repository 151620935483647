/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SpotifyCallbackImport } from './routes/spotify-callback'
import { Route as SignupImport } from './routes/signup'
import { Route as DownloadImport } from './routes/download'
import { Route as AccountImport } from './routes/account'
import { Route as UserRouteImport } from './routes/user/route'
import { Route as SetupGuideRouteImport } from './routes/setup-guide/route'
import { Route as SearchRouteImport } from './routes/search/route'
import { Route as PartnerPortalRouteImport } from './routes/partner-portal/route'
import { Route as HomeRouteImport } from './routes/home/route'
import { Route as ElectronRouteImport } from './routes/electron/route'
import { Route as DiscoverRouteImport } from './routes/discover/route'
import { Route as DevRouteImport } from './routes/dev/route'
import { Route as CreateRouteImport } from './routes/create/route'
import { Route as loggedOutRouteImport } from './routes/(logged-out)/route'
import { Route as IndexImport } from './routes/index'
import { Route as PartnerPortalIndexImport } from './routes/partner-portal/index'
import { Route as HomeIndexImport } from './routes/home/index'
import { Route as DiscoverIndexImport } from './routes/discover/index'
import { Route as DevIndexImport } from './routes/dev/index'
import { Route as CreateIndexImport } from './routes/create/index'
import { Route as AddonsIndexImport } from './routes/addons/index'
import { Route as UserEditImport } from './routes/user/edit'
import { Route as UserChangePasswordImport } from './routes/user/change-password'
import { Route as UserChangeEmailImport } from './routes/user/change-email'
import { Route as SignupSplatImport } from './routes/signup.$'
import { Route as SetupGuideUserInviteImport } from './routes/setup-guide/user-invite'
import { Route as SetupGuideSpotifyImportImport } from './routes/setup-guide/spotify-import'
import { Route as SetupGuideSeeOptionsImport } from './routes/setup-guide/see-options'
import { Route as SetupGuideQrAppLoginImport } from './routes/setup-guide/qr-app-login'
import { Route as SetupGuidePairImport } from './routes/setup-guide/pair'
import { Route as SetupGuideExplicitSettingImport } from './routes/setup-guide/explicit-setting'
import { Route as SetupGuideCreatePlaylistImport } from './routes/setup-guide/create-playlist'
import { Route as SetupGuideChoosePlanImport } from './routes/setup-guide/choose-plan'
import { Route as SetupGuideBusinessDescriptionImport } from './routes/setup-guide/business-description'
import { Route as SearchSplatImport } from './routes/search/$'
import { Route as PartnerPortalAccountIdImport } from './routes/partner-portal/$accountId'
import { Route as HomePageImport } from './routes/home/$page'
import { Route as ElectronSelectImport } from './routes/electron/select'
import { Route as ElectronBrowserOpenedImport } from './routes/electron/browser-opened'
import { Route as DiscoverSplatImport } from './routes/discover/$'
import { Route as DevTooltipsImport } from './routes/dev/tooltips'
import { Route as DevToastsImport } from './routes/dev/toasts'
import { Route as DevSurveysImport } from './routes/dev/surveys'
import { Route as DevSentryImport } from './routes/dev/sentry'
import { Route as DevSandboxImport } from './routes/dev/sandbox'
import { Route as DevPriceBreakdownImport } from './routes/dev/price-breakdown'
import { Route as DevPlaybackImport } from './routes/dev/playback'
import { Route as DevFlagsImport } from './routes/dev/flags'
import { Route as DevDragDropImport } from './routes/dev/drag-drop'
import { Route as CreateSyncedPlaylistImport } from './routes/create/synced-playlist'
import { Route as CreateScheduleImport } from './routes/create/schedule'
import { Route as CreatePlaylistImport } from './routes/create/playlist'
import { Route as CreateImportSpotifyPlaylistImport } from './routes/create/import-spotify-playlist'
import { Route as CreateAiStationImport } from './routes/create/ai-station'
import { Route as CreateAiPlaylistImport } from './routes/create/ai-playlist'
import { Route as miscSsoImport } from './routes/(misc)/sso'
import { Route as miscNpsSurveyImport } from './routes/(misc)/nps-survey'
import { Route as miscLogoutImport } from './routes/(misc)/logout'
import { Route as loggedOutForgotPasswordImport } from './routes/(logged-out)/forgot-password'
import { Route as AccountsAccountIdRouteImport } from './routes/accounts.$accountId/route'
import { Route as SetupGuidePairIndexImport } from './routes/setup-guide/pair.index'
import { Route as ElectronLoginIndexImport } from './routes/electron/login.index'
import { Route as AccountsAccountIdIndexImport } from './routes/accounts.$accountId/index'
import { Route as miscNpsSurveyIndexImport } from './routes/(misc)/nps-survey.index'
import { Route as miscLegalCertificateIndexImport } from './routes/(misc)/legal-certificate.index'
import { Route as loggedOutLoginIndexImport } from './routes/(logged-out)/login.index'
import { Route as loggedOutInvitationIndexImport } from './routes/(logged-out)/invitation.index'
import { Route as SetupGuidePairActionImport } from './routes/setup-guide/pair.$action'
import { Route as PartnerPortalAccountIdCreateImport } from './routes/partner-portal/$accountId.create'
import { Route as ElectronSelectAccountIdImport } from './routes/electron/select.$accountId'
import { Route as ElectronLoginPairingCodeImport } from './routes/electron/login.pairing-code'
import { Route as ElectronLoginEmailImport } from './routes/electron/login.email'
import { Route as DiscoverScheduleIdImport } from './routes/discover/schedule.$id'
import { Route as DiscoverMusicMusicIdImport } from './routes/discover/music.$musicId'
import { Route as DiscoverAlbumReleaseIdImport } from './routes/discover/album.$releaseId'
import { Route as CreateStationFromTagsImport } from './routes/create/station.from-tags'
import { Route as CreateStationFromPlaylistImport } from './routes/create/station.from-playlist'
import { Route as AddonsPromoSsoImport } from './routes/addons/promo.sso'
import { Route as AddonsPromoMessagingImport } from './routes/addons/promo.messaging'
import { Route as AddonsPromoHifiImport } from './routes/addons/promo.hifi'
import { Route as AddonsPromoCurationImport } from './routes/addons/promo.curation'
import { Route as AddonsActivatedAddonImport } from './routes/addons/activated.$addon'
import { Route as miscSsoSplatImport } from './routes/(misc)/sso.$'
import { Route as miscReferralCodeImport } from './routes/(misc)/referral.$code'
import { Route as miscNpsSurveyVariantImport } from './routes/(misc)/nps-survey.$variant'
import { Route as miscLegalCertificatePrintImport } from './routes/(misc)/legal-certificate.print'
import { Route as miscInvoiceUuidImport } from './routes/(misc)/invoice.$uuid'
import { Route as loggedOutVerifyEmailTokenImport } from './routes/(logged-out)/verify-email.$token'
import { Route as loggedOutResetPasswordTokenImport } from './routes/(logged-out)/reset-password.$token'
import { Route as loggedOutInvitationTokenImport } from './routes/(logged-out)/invitation.$token'
import { Route as AccountsAccountIdZonesRouteImport } from './routes/accounts.$accountId/zones/route'
import { Route as AccountsAccountIdYourMusicRouteImport } from './routes/accounts.$accountId/your-music/route'
import { Route as AccountsAccountIdSettingsRouteImport } from './routes/accounts.$accountId/settings/route'
import { Route as AccountsAccountIdOnboardingRouteImport } from './routes/accounts.$accountId/onboarding/route'
import { Route as AccountsAccountIdMessagingRouteImport } from './routes/accounts.$accountId/messaging/route'
import { Route as AccountsAccountIdCheckoutRouteImport } from './routes/accounts.$accountId/checkout/route'
import { Route as DiscoverMusicMusicIdIndexImport } from './routes/discover/music.$musicId.index'
import { Route as AccountsAccountIdYourMusicIndexImport } from './routes/accounts.$accountId/your-music/index'
import { Route as AccountsAccountIdUpgradePlanIndexImport } from './routes/accounts.$accountId/upgrade-plan/index'
import { Route as AccountsAccountIdSettingsIndexImport } from './routes/accounts.$accountId/settings/index'
import { Route as AccountsAccountIdMessagingIndexImport } from './routes/accounts.$accountId/messaging/index'
import { Route as AccountsAccountIdLocationsIndexImport } from './routes/accounts.$accountId/locations/index'
import { Route as AccountsAccountIdChoosePlanIndexImport } from './routes/accounts.$accountId/choose-plan/index'
import { Route as AccountsAccountIdChangePlanIndexImport } from './routes/accounts.$accountId/change-plan/index'
import { Route as pairingGuideConnectVendorIndexImport } from './routes/(pairing-guide)/connect.$vendor/index'
import { Route as loggedOutLoginSamlIndexImport } from './routes/(logged-out)/login.saml.index'
import { Route as ElectronSelectAccountIdLocationIdImport } from './routes/electron/select.$accountId.$locationId'
import { Route as DiscoverScheduleIdEditImport } from './routes/discover/schedule.$id.edit'
import { Route as DiscoverMusicMusicIdEditImport } from './routes/discover/music.$musicId.edit'
import { Route as DiscoverCategoriesTypeCategoryIdImport } from './routes/discover/categories.$type.$categoryId'
import { Route as AccountsAccountIdZonesZoneIdImport } from './routes/accounts.$accountId/zones/$zoneId'
import { Route as AccountsAccountIdYourMusicMusicIdImport } from './routes/accounts.$accountId/your-music_.$musicId'
import { Route as AccountsAccountIdVoucherCodeImport } from './routes/accounts.$accountId/voucher.$code'
import { Route as AccountsAccountIdSettingsZonePresetsImport } from './routes/accounts.$accountId/settings/zone-presets'
import { Route as AccountsAccountIdSettingsUsersImport } from './routes/accounts.$accountId/settings/users'
import { Route as AccountsAccountIdSettingsSamlImport } from './routes/accounts.$accountId/settings/saml'
import { Route as AccountsAccountIdSettingsReferralsImport } from './routes/accounts.$accountId/settings/referrals'
import { Route as AccountsAccountIdSettingsOverviewImport } from './routes/accounts.$accountId/settings/overview'
import { Route as AccountsAccountIdOnboardingStepImport } from './routes/accounts.$accountId/onboarding/$step'
import { Route as AccountsAccountIdMessagingMessagesImport } from './routes/accounts.$accountId/messaging/messages'
import { Route as AccountsAccountIdLocationsSplatImport } from './routes/accounts.$accountId/locations/$'
import { Route as AccountsAccountIdCheckoutSplatImport } from './routes/accounts.$accountId/checkout/$'
import { Route as AccountsAccountIdChangePlanPlanImport } from './routes/accounts.$accountId/change-plan/$plan'
import { Route as pairingGuideConnectVendorPairImport } from './routes/(pairing-guide)/connect.$vendor/pair'
import { Route as pairingGuideConnectVendorPairingCodeImport } from './routes/(pairing-guide)/connect.$vendor/$pairingCode_'
import { Route as miscInvoiceAccountIdRefImport } from './routes/(misc)/invoice.$accountId.$ref'
import { Route as miscExchangeratesDateCurrencyImport } from './routes/(misc)/exchangerates.$date.$currency'
import { Route as loggedOutLoginSamlOrgSlugImport } from './routes/(logged-out)/login.saml.$orgSlug'
import { Route as AccountsAccountIdSettingsSubscriptionsRouteImport } from './routes/accounts.$accountId/settings/subscriptions/route'
import { Route as AccountsAccountIdSettingsBillingRouteImport } from './routes/accounts.$accountId/settings/billing/route'
import { Route as AccountsAccountIdZonesCreateIndexImport } from './routes/accounts.$accountId/zones/create.index'
import { Route as AccountsAccountIdZonesZoneIdIndexImport } from './routes/accounts.$accountId/zones/$zoneId.index'
import { Route as AccountsAccountIdYourMusicSchedulesIndexImport } from './routes/accounts.$accountId/your-music/schedules.index'
import { Route as AccountsAccountIdYourMusicPlaylistsIndexImport } from './routes/accounts.$accountId/your-music/playlists.index'
import { Route as AccountsAccountIdSettingsSubscriptionsIndexImport } from './routes/accounts.$accountId/settings/subscriptions/index'
import { Route as AccountsAccountIdMessagingCampaignsIndexImport } from './routes/accounts.$accountId/messaging/campaigns.index'
import { Route as AccountsAccountIdZonesCreateLocationIdImport } from './routes/accounts.$accountId/zones/create.$locationId'
import { Route as AccountsAccountIdZonesZoneIdSubscriptionImport } from './routes/accounts.$accountId/zones/$zoneId.subscription'
import { Route as AccountsAccountIdZonesZoneIdSettingsImport } from './routes/accounts.$accountId/zones/$zoneId.settings'
import { Route as AccountsAccountIdZonesZoneIdRemoteImport } from './routes/accounts.$accountId/zones/$zoneId.remote'
import { Route as AccountsAccountIdZonesZoneIdPlayerImport } from './routes/accounts.$accountId/zones/$zoneId.player'
import { Route as AccountsAccountIdZonesZoneIdControlImport } from './routes/accounts.$accountId/zones/$zoneId.control'
import { Route as AccountsAccountIdYourMusicSchedulesScheduleIdImport } from './routes/accounts.$accountId/your-music_.schedules.$scheduleId'
import { Route as AccountsAccountIdYourMusicSchedulesEditImport } from './routes/accounts.$accountId/your-music/schedules.edit'
import { Route as AccountsAccountIdYourMusicSchedulesCreateImport } from './routes/accounts.$accountId/your-music/schedules.create'
import { Route as AccountsAccountIdYourMusicPlaylistsMusicIdImport } from './routes/accounts.$accountId/your-music/playlists.$musicId'
import { Route as AccountsAccountIdSettingsUsersInviteImport } from './routes/accounts.$accountId/settings/users.invite'
import { Route as AccountsAccountIdSettingsSubscriptionsStreamingImport } from './routes/accounts.$accountId/settings/subscriptions/streaming'
import { Route as AccountsAccountIdSettingsOverviewCompanyDetailsImport } from './routes/accounts.$accountId/settings/overview.company-details'
import { Route as AccountsAccountIdSettingsBillingUpdateBillingEmailImport } from './routes/accounts.$accountId/settings/billing/update-billing-email'
import { Route as AccountsAccountIdMessagingMessagesCreateImport } from './routes/accounts.$accountId/messaging/messages.create'
import { Route as AccountsAccountIdMessagingMessagesMessageIdImport } from './routes/accounts.$accountId/messaging/messages.$messageId'
import { Route as AccountsAccountIdMessagingCampaignsCreateImport } from './routes/accounts.$accountId/messaging/campaigns.create'
import { Route as AccountsAccountIdMessagingCampaignsCampaignIdImport } from './routes/accounts.$accountId/messaging/campaigns.$campaignId'
import { Route as AccountsAccountIdChangePlanConfirmedPlanImport } from './routes/accounts.$accountId/change-plan/confirmed.$plan'
import { Route as AccountsAccountIdAddonsAddonIdActivateImport } from './routes/accounts.$accountId/addons.$addonId.activate'
import { Route as pairingGuideSonosPairV2HouseholdIdImport } from './routes/(pairing-guide)/sonos.pair.v2.$householdId'
import { Route as pairingGuideSonosConnectV2HouseholdIdImport } from './routes/(pairing-guide)/sonos.connect.v2.$householdId'
import { Route as pairingGuideConnectVendorPairingCodePairImport } from './routes/(pairing-guide)/connect.$vendor/$pairingCode.pair'
import { Route as AccountsAccountIdZonesZoneIdPlayerHistoryImport } from './routes/accounts.$accountId/zones/$zoneId_.player.history'
import { Route as AccountsAccountIdZonesZoneIdPlayerBlockedTracksImport } from './routes/accounts.$accountId/zones/$zoneId_.player.blocked-tracks'
import { Route as AccountsAccountIdZonesZoneIdSubscriptionActionImport } from './routes/accounts.$accountId/zones/$zoneId.subscription.$action'
import { Route as AccountsAccountIdZonesZoneIdPlayerActionImport } from './routes/accounts.$accountId/zones/$zoneId.player.$action'
import { Route as AccountsAccountIdYourMusicSchedulesEditScheduleIdImport } from './routes/accounts.$accountId/your-music/schedules.edit.$scheduleId'
import { Route as AccountsAccountIdYourMusicSchedulesScheduleIdEditImport } from './routes/accounts.$accountId/your-music/schedules.$scheduleId.edit'
import { Route as AccountsAccountIdYourMusicSchedulesScheduleIdCopyImport } from './routes/accounts.$accountId/your-music/schedules.$scheduleId.copy'
import { Route as AccountsAccountIdSettingsSubscriptionsStreamingZoneIdImport } from './routes/accounts.$accountId/settings/subscriptions/streaming.$zoneId'
import { Route as AccountsAccountIdSettingsSubscriptionsAddonsSubscriptionIdImport } from './routes/accounts.$accountId/settings/subscriptions/addons.$subscriptionId'
import { Route as AccountsAccountIdSettingsBillingUpdatePaymentTypeImport } from './routes/accounts.$accountId/settings/billing/update-payment.$type'
import { Route as pairingGuideConnectVendorPairingCodePairZoneIdImport } from './routes/(pairing-guide)/connect.$vendor/$pairingCode.pair.$zoneId'
import { Route as AccountsAccountIdZonesZoneIdPlayerPairPlayerImport } from './routes/accounts.$accountId/zones/$zoneId.player.pair.$player'
import { Route as AccountsAccountIdSettingsSubscriptionsStreamingZoneIdActionImport } from './routes/accounts.$accountId/settings/subscriptions/streaming.$zoneId.$action'
import { Route as AccountsAccountIdSettingsBillingInvoiceGroupEditGroupIdImport } from './routes/accounts.$accountId/settings/billing/invoice-group.edit.$groupId'

// Create/Update Routes

const SpotifyCallbackRoute = SpotifyCallbackImport.update({
  id: '/spotify-callback',
  path: '/spotify-callback',
  getParentRoute: () => rootRoute,
} as any)

const SignupRoute = SignupImport.update({
  id: '/signup',
  path: '/signup',
  getParentRoute: () => rootRoute,
} as any)

const DownloadRoute = DownloadImport.update({
  id: '/download',
  path: '/download',
  getParentRoute: () => rootRoute,
} as any)

const AccountRoute = AccountImport.update({
  id: '/account',
  path: '/account',
  getParentRoute: () => rootRoute,
} as any)

const UserRouteRoute = UserRouteImport.update({
  id: '/user',
  path: '/user',
  getParentRoute: () => rootRoute,
} as any)

const SetupGuideRouteRoute = SetupGuideRouteImport.update({
  id: '/setup-guide',
  path: '/setup-guide',
  getParentRoute: () => rootRoute,
} as any)

const SearchRouteRoute = SearchRouteImport.update({
  id: '/search',
  path: '/search',
  getParentRoute: () => rootRoute,
} as any)

const PartnerPortalRouteRoute = PartnerPortalRouteImport.update({
  id: '/partner-portal',
  path: '/partner-portal',
  getParentRoute: () => rootRoute,
} as any)

const HomeRouteRoute = HomeRouteImport.update({
  id: '/home',
  path: '/home',
  getParentRoute: () => rootRoute,
} as any)

const ElectronRouteRoute = ElectronRouteImport.update({
  id: '/electron',
  path: '/electron',
  getParentRoute: () => rootRoute,
} as any)

const DiscoverRouteRoute = DiscoverRouteImport.update({
  id: '/discover',
  path: '/discover',
  getParentRoute: () => rootRoute,
} as any)

const DevRouteRoute = DevRouteImport.update({
  id: '/dev',
  path: '/dev',
  getParentRoute: () => rootRoute,
} as any)

const CreateRouteRoute = CreateRouteImport.update({
  id: '/create',
  path: '/create',
  getParentRoute: () => rootRoute,
} as any)

const loggedOutRouteRoute = loggedOutRouteImport.update({
  id: '/(logged-out)',
  getParentRoute: () => IndexRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const PartnerPortalIndexRoute = PartnerPortalIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PartnerPortalRouteRoute,
} as any)

const HomeIndexRoute = HomeIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => HomeRouteRoute,
} as any)

const DiscoverIndexRoute = DiscoverIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DiscoverRouteRoute,
} as any)

const DevIndexRoute = DevIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DevRouteRoute,
} as any)

const CreateIndexRoute = CreateIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => CreateRouteRoute,
} as any)

const AddonsIndexRoute = AddonsIndexImport.update({
  id: '/addons/',
  path: '/addons/',
  getParentRoute: () => rootRoute,
} as any)

const UserEditRoute = UserEditImport.update({
  id: '/edit',
  path: '/edit',
  getParentRoute: () => UserRouteRoute,
} as any)

const UserChangePasswordRoute = UserChangePasswordImport.update({
  id: '/change-password',
  path: '/change-password',
  getParentRoute: () => UserRouteRoute,
} as any)

const UserChangeEmailRoute = UserChangeEmailImport.update({
  id: '/change-email',
  path: '/change-email',
  getParentRoute: () => UserRouteRoute,
} as any)

const SignupSplatRoute = SignupSplatImport.update({
  id: '/$',
  path: '/$',
  getParentRoute: () => SignupRoute,
} as any)

const SetupGuideUserInviteRoute = SetupGuideUserInviteImport.update({
  id: '/user-invite',
  path: '/user-invite',
  getParentRoute: () => SetupGuideRouteRoute,
} as any)

const SetupGuideSpotifyImportRoute = SetupGuideSpotifyImportImport.update({
  id: '/spotify-import',
  path: '/spotify-import',
  getParentRoute: () => SetupGuideRouteRoute,
} as any)

const SetupGuideSeeOptionsRoute = SetupGuideSeeOptionsImport.update({
  id: '/see-options',
  path: '/see-options',
  getParentRoute: () => SetupGuideRouteRoute,
} as any)

const SetupGuideQrAppLoginRoute = SetupGuideQrAppLoginImport.update({
  id: '/qr-app-login',
  path: '/qr-app-login',
  getParentRoute: () => SetupGuideRouteRoute,
} as any)

const SetupGuidePairRoute = SetupGuidePairImport.update({
  id: '/pair',
  path: '/pair',
  getParentRoute: () => SetupGuideRouteRoute,
} as any)

const SetupGuideExplicitSettingRoute = SetupGuideExplicitSettingImport.update({
  id: '/explicit-setting',
  path: '/explicit-setting',
  getParentRoute: () => SetupGuideRouteRoute,
} as any)

const SetupGuideCreatePlaylistRoute = SetupGuideCreatePlaylistImport.update({
  id: '/create-playlist',
  path: '/create-playlist',
  getParentRoute: () => SetupGuideRouteRoute,
} as any)

const SetupGuideChoosePlanRoute = SetupGuideChoosePlanImport.update({
  id: '/choose-plan',
  path: '/choose-plan',
  getParentRoute: () => SetupGuideRouteRoute,
} as any)

const SetupGuideBusinessDescriptionRoute =
  SetupGuideBusinessDescriptionImport.update({
    id: '/business-description',
    path: '/business-description',
    getParentRoute: () => SetupGuideRouteRoute,
  } as any)

const SearchSplatRoute = SearchSplatImport.update({
  id: '/$',
  path: '/$',
  getParentRoute: () => SearchRouteRoute,
} as any)

const PartnerPortalAccountIdRoute = PartnerPortalAccountIdImport.update({
  id: '/$accountId',
  path: '/$accountId',
  getParentRoute: () => PartnerPortalRouteRoute,
} as any)

const HomePageRoute = HomePageImport.update({
  id: '/$page',
  path: '/$page',
  getParentRoute: () => HomeRouteRoute,
} as any)

const ElectronSelectRoute = ElectronSelectImport.update({
  id: '/select',
  path: '/select',
  getParentRoute: () => ElectronRouteRoute,
} as any)

const ElectronBrowserOpenedRoute = ElectronBrowserOpenedImport.update({
  id: '/browser-opened',
  path: '/browser-opened',
  getParentRoute: () => ElectronRouteRoute,
} as any)

const DiscoverSplatRoute = DiscoverSplatImport.update({
  id: '/$',
  path: '/$',
  getParentRoute: () => DiscoverRouteRoute,
} as any)

const DevTooltipsRoute = DevTooltipsImport.update({
  id: '/tooltips',
  path: '/tooltips',
  getParentRoute: () => DevRouteRoute,
} as any)

const DevToastsRoute = DevToastsImport.update({
  id: '/toasts',
  path: '/toasts',
  getParentRoute: () => DevRouteRoute,
} as any)

const DevSurveysRoute = DevSurveysImport.update({
  id: '/surveys',
  path: '/surveys',
  getParentRoute: () => DevRouteRoute,
} as any)

const DevSentryRoute = DevSentryImport.update({
  id: '/sentry',
  path: '/sentry',
  getParentRoute: () => DevRouteRoute,
} as any)

const DevSandboxRoute = DevSandboxImport.update({
  id: '/sandbox',
  path: '/sandbox',
  getParentRoute: () => DevRouteRoute,
} as any)

const DevPriceBreakdownRoute = DevPriceBreakdownImport.update({
  id: '/price-breakdown',
  path: '/price-breakdown',
  getParentRoute: () => DevRouteRoute,
} as any)

const DevPlaybackRoute = DevPlaybackImport.update({
  id: '/playback',
  path: '/playback',
  getParentRoute: () => DevRouteRoute,
} as any)

const DevFlagsRoute = DevFlagsImport.update({
  id: '/flags',
  path: '/flags',
  getParentRoute: () => DevRouteRoute,
} as any)

const DevDragDropRoute = DevDragDropImport.update({
  id: '/drag-drop',
  path: '/drag-drop',
  getParentRoute: () => DevRouteRoute,
} as any)

const CreateSyncedPlaylistRoute = CreateSyncedPlaylistImport.update({
  id: '/synced-playlist',
  path: '/synced-playlist',
  getParentRoute: () => CreateRouteRoute,
} as any)

const CreateScheduleRoute = CreateScheduleImport.update({
  id: '/schedule',
  path: '/schedule',
  getParentRoute: () => CreateRouteRoute,
} as any)

const CreatePlaylistRoute = CreatePlaylistImport.update({
  id: '/playlist',
  path: '/playlist',
  getParentRoute: () => CreateRouteRoute,
} as any)

const CreateImportSpotifyPlaylistRoute =
  CreateImportSpotifyPlaylistImport.update({
    id: '/import-spotify-playlist',
    path: '/import-spotify-playlist',
    getParentRoute: () => CreateRouteRoute,
  } as any)

const CreateAiStationRoute = CreateAiStationImport.update({
  id: '/ai-station',
  path: '/ai-station',
  getParentRoute: () => CreateRouteRoute,
} as any)

const CreateAiPlaylistRoute = CreateAiPlaylistImport.update({
  id: '/ai-playlist',
  path: '/ai-playlist',
  getParentRoute: () => CreateRouteRoute,
} as any)

const miscSsoRoute = miscSsoImport.update({
  id: '/(misc)/sso',
  path: '/sso',
  getParentRoute: () => rootRoute,
} as any)

const miscNpsSurveyRoute = miscNpsSurveyImport.update({
  id: '/(misc)/nps-survey',
  path: '/nps-survey',
  getParentRoute: () => rootRoute,
} as any)

const miscLogoutRoute = miscLogoutImport.update({
  id: '/(misc)/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any)

const loggedOutForgotPasswordRoute = loggedOutForgotPasswordImport.update({
  id: '/forgot-password',
  path: '/forgot-password',
  getParentRoute: () => loggedOutRouteRoute,
} as any)

const AccountsAccountIdRouteRoute = AccountsAccountIdRouteImport.update({
  id: '/accounts/$accountId',
  path: '/accounts/$accountId',
  getParentRoute: () => rootRoute,
} as any)

const SetupGuidePairIndexRoute = SetupGuidePairIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => SetupGuidePairRoute,
} as any)

const ElectronLoginIndexRoute = ElectronLoginIndexImport.update({
  id: '/login/',
  path: '/login/',
  getParentRoute: () => ElectronRouteRoute,
} as any)

const AccountsAccountIdIndexRoute = AccountsAccountIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AccountsAccountIdRouteRoute,
} as any)

const miscNpsSurveyIndexRoute = miscNpsSurveyIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => miscNpsSurveyRoute,
} as any)

const miscLegalCertificateIndexRoute = miscLegalCertificateIndexImport.update({
  id: '/(misc)/legal-certificate/',
  path: '/legal-certificate/',
  getParentRoute: () => rootRoute,
} as any)

const loggedOutLoginIndexRoute = loggedOutLoginIndexImport.update({
  id: '/login/',
  path: '/login/',
  getParentRoute: () => loggedOutRouteRoute,
} as any)

const loggedOutInvitationIndexRoute = loggedOutInvitationIndexImport.update({
  id: '/invitation/',
  path: '/invitation/',
  getParentRoute: () => loggedOutRouteRoute,
} as any)

const SetupGuidePairActionRoute = SetupGuidePairActionImport.update({
  id: '/$action',
  path: '/$action',
  getParentRoute: () => SetupGuidePairRoute,
} as any)

const PartnerPortalAccountIdCreateRoute =
  PartnerPortalAccountIdCreateImport.update({
    id: '/create',
    path: '/create',
    getParentRoute: () => PartnerPortalAccountIdRoute,
  } as any)

const ElectronSelectAccountIdRoute = ElectronSelectAccountIdImport.update({
  id: '/$accountId',
  path: '/$accountId',
  getParentRoute: () => ElectronSelectRoute,
} as any)

const ElectronLoginPairingCodeRoute = ElectronLoginPairingCodeImport.update({
  id: '/login/pairing-code',
  path: '/login/pairing-code',
  getParentRoute: () => ElectronRouteRoute,
} as any)

const ElectronLoginEmailRoute = ElectronLoginEmailImport.update({
  id: '/login/email',
  path: '/login/email',
  getParentRoute: () => ElectronRouteRoute,
} as any)

const DiscoverScheduleIdRoute = DiscoverScheduleIdImport.update({
  id: '/schedule/$id',
  path: '/schedule/$id',
  getParentRoute: () => DiscoverRouteRoute,
} as any)

const DiscoverMusicMusicIdRoute = DiscoverMusicMusicIdImport.update({
  id: '/music/$musicId',
  path: '/music/$musicId',
  getParentRoute: () => DiscoverRouteRoute,
} as any)

const DiscoverAlbumReleaseIdRoute = DiscoverAlbumReleaseIdImport.update({
  id: '/album/$releaseId',
  path: '/album/$releaseId',
  getParentRoute: () => DiscoverRouteRoute,
} as any)

const CreateStationFromTagsRoute = CreateStationFromTagsImport.update({
  id: '/station/from-tags',
  path: '/station/from-tags',
  getParentRoute: () => CreateRouteRoute,
} as any)

const CreateStationFromPlaylistRoute = CreateStationFromPlaylistImport.update({
  id: '/station/from-playlist',
  path: '/station/from-playlist',
  getParentRoute: () => CreateRouteRoute,
} as any)

const AddonsPromoSsoRoute = AddonsPromoSsoImport.update({
  id: '/addons/promo/sso',
  path: '/addons/promo/sso',
  getParentRoute: () => rootRoute,
} as any)

const AddonsPromoMessagingRoute = AddonsPromoMessagingImport.update({
  id: '/addons/promo/messaging',
  path: '/addons/promo/messaging',
  getParentRoute: () => rootRoute,
} as any)

const AddonsPromoHifiRoute = AddonsPromoHifiImport.update({
  id: '/addons/promo/hifi',
  path: '/addons/promo/hifi',
  getParentRoute: () => rootRoute,
} as any)

const AddonsPromoCurationRoute = AddonsPromoCurationImport.update({
  id: '/addons/promo/curation',
  path: '/addons/promo/curation',
  getParentRoute: () => rootRoute,
} as any)

const AddonsActivatedAddonRoute = AddonsActivatedAddonImport.update({
  id: '/addons/activated/$addon',
  path: '/addons/activated/$addon',
  getParentRoute: () => rootRoute,
} as any)

const miscSsoSplatRoute = miscSsoSplatImport.update({
  id: '/$',
  path: '/$',
  getParentRoute: () => miscSsoRoute,
} as any)

const miscReferralCodeRoute = miscReferralCodeImport.update({
  id: '/(misc)/referral/$code',
  path: '/referral/$code',
  getParentRoute: () => rootRoute,
} as any)

const miscNpsSurveyVariantRoute = miscNpsSurveyVariantImport.update({
  id: '/$variant',
  path: '/$variant',
  getParentRoute: () => miscNpsSurveyRoute,
} as any)

const miscLegalCertificatePrintRoute = miscLegalCertificatePrintImport.update({
  id: '/(misc)/legal-certificate/print',
  path: '/legal-certificate/print',
  getParentRoute: () => rootRoute,
} as any)

const miscInvoiceUuidRoute = miscInvoiceUuidImport.update({
  id: '/(misc)/invoice/$uuid',
  path: '/invoice/$uuid',
  getParentRoute: () => rootRoute,
} as any)

const loggedOutVerifyEmailTokenRoute = loggedOutVerifyEmailTokenImport.update({
  id: '/verify-email/$token',
  path: '/verify-email/$token',
  getParentRoute: () => loggedOutRouteRoute,
} as any)

const loggedOutResetPasswordTokenRoute =
  loggedOutResetPasswordTokenImport.update({
    id: '/reset-password/$token',
    path: '/reset-password/$token',
    getParentRoute: () => loggedOutRouteRoute,
  } as any)

const loggedOutInvitationTokenRoute = loggedOutInvitationTokenImport.update({
  id: '/invitation/$token',
  path: '/invitation/$token',
  getParentRoute: () => loggedOutRouteRoute,
} as any)

const AccountsAccountIdZonesRouteRoute =
  AccountsAccountIdZonesRouteImport.update({
    id: '/zones',
    path: '/zones',
    getParentRoute: () => AccountsAccountIdRouteRoute,
  } as any)

const AccountsAccountIdYourMusicRouteRoute =
  AccountsAccountIdYourMusicRouteImport.update({
    id: '/your-music',
    path: '/your-music',
    getParentRoute: () => AccountsAccountIdRouteRoute,
  } as any)

const AccountsAccountIdSettingsRouteRoute =
  AccountsAccountIdSettingsRouteImport.update({
    id: '/settings',
    path: '/settings',
    getParentRoute: () => AccountsAccountIdRouteRoute,
  } as any)

const AccountsAccountIdOnboardingRouteRoute =
  AccountsAccountIdOnboardingRouteImport.update({
    id: '/onboarding',
    path: '/onboarding',
    getParentRoute: () => AccountsAccountIdRouteRoute,
  } as any)

const AccountsAccountIdMessagingRouteRoute =
  AccountsAccountIdMessagingRouteImport.update({
    id: '/messaging',
    path: '/messaging',
    getParentRoute: () => AccountsAccountIdRouteRoute,
  } as any)

const AccountsAccountIdCheckoutRouteRoute =
  AccountsAccountIdCheckoutRouteImport.update({
    id: '/checkout',
    path: '/checkout',
    getParentRoute: () => AccountsAccountIdRouteRoute,
  } as any)

const DiscoverMusicMusicIdIndexRoute = DiscoverMusicMusicIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => DiscoverMusicMusicIdRoute,
} as any)

const AccountsAccountIdYourMusicIndexRoute =
  AccountsAccountIdYourMusicIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AccountsAccountIdYourMusicRouteRoute,
  } as any)

const AccountsAccountIdUpgradePlanIndexRoute =
  AccountsAccountIdUpgradePlanIndexImport.update({
    id: '/upgrade-plan/',
    path: '/upgrade-plan/',
    getParentRoute: () => AccountsAccountIdRouteRoute,
  } as any)

const AccountsAccountIdSettingsIndexRoute =
  AccountsAccountIdSettingsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AccountsAccountIdSettingsRouteRoute,
  } as any)

const AccountsAccountIdMessagingIndexRoute =
  AccountsAccountIdMessagingIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AccountsAccountIdMessagingRouteRoute,
  } as any)

const AccountsAccountIdLocationsIndexRoute =
  AccountsAccountIdLocationsIndexImport.update({
    id: '/locations/',
    path: '/locations/',
    getParentRoute: () => AccountsAccountIdRouteRoute,
  } as any)

const AccountsAccountIdChoosePlanIndexRoute =
  AccountsAccountIdChoosePlanIndexImport.update({
    id: '/choose-plan/',
    path: '/choose-plan/',
    getParentRoute: () => AccountsAccountIdRouteRoute,
  } as any)

const AccountsAccountIdChangePlanIndexRoute =
  AccountsAccountIdChangePlanIndexImport.update({
    id: '/change-plan/',
    path: '/change-plan/',
    getParentRoute: () => AccountsAccountIdRouteRoute,
  } as any)

const pairingGuideConnectVendorIndexRoute =
  pairingGuideConnectVendorIndexImport.update({
    id: '/(pairing-guide)/connect/$vendor/',
    path: '/connect/$vendor/',
    getParentRoute: () => rootRoute,
  } as any)

const loggedOutLoginSamlIndexRoute = loggedOutLoginSamlIndexImport.update({
  id: '/login/saml/',
  path: '/login/saml/',
  getParentRoute: () => loggedOutRouteRoute,
} as any)

const ElectronSelectAccountIdLocationIdRoute =
  ElectronSelectAccountIdLocationIdImport.update({
    id: '/$locationId',
    path: '/$locationId',
    getParentRoute: () => ElectronSelectAccountIdRoute,
  } as any)

const DiscoverScheduleIdEditRoute = DiscoverScheduleIdEditImport.update({
  id: '/edit',
  path: '/edit',
  getParentRoute: () => DiscoverScheduleIdRoute,
} as any)

const DiscoverMusicMusicIdEditRoute = DiscoverMusicMusicIdEditImport.update({
  id: '/edit',
  path: '/edit',
  getParentRoute: () => DiscoverMusicMusicIdRoute,
} as any)

const DiscoverCategoriesTypeCategoryIdRoute =
  DiscoverCategoriesTypeCategoryIdImport.update({
    id: '/categories/$type/$categoryId',
    path: '/categories/$type/$categoryId',
    getParentRoute: () => DiscoverRouteRoute,
  } as any)

const AccountsAccountIdZonesZoneIdRoute =
  AccountsAccountIdZonesZoneIdImport.update({
    id: '/$zoneId',
    path: '/$zoneId',
    getParentRoute: () => AccountsAccountIdZonesRouteRoute,
  } as any)

const AccountsAccountIdYourMusicMusicIdRoute =
  AccountsAccountIdYourMusicMusicIdImport.update({
    id: '/your-music_/$musicId',
    path: '/your-music/$musicId',
    getParentRoute: () => AccountsAccountIdRouteRoute,
  } as any)

const AccountsAccountIdVoucherCodeRoute =
  AccountsAccountIdVoucherCodeImport.update({
    id: '/voucher/$code',
    path: '/voucher/$code',
    getParentRoute: () => AccountsAccountIdRouteRoute,
  } as any)

const AccountsAccountIdSettingsZonePresetsRoute =
  AccountsAccountIdSettingsZonePresetsImport.update({
    id: '/zone-presets',
    path: '/zone-presets',
    getParentRoute: () => AccountsAccountIdSettingsRouteRoute,
  } as any)

const AccountsAccountIdSettingsUsersRoute =
  AccountsAccountIdSettingsUsersImport.update({
    id: '/users',
    path: '/users',
    getParentRoute: () => AccountsAccountIdSettingsRouteRoute,
  } as any)

const AccountsAccountIdSettingsSamlRoute =
  AccountsAccountIdSettingsSamlImport.update({
    id: '/saml',
    path: '/saml',
    getParentRoute: () => AccountsAccountIdSettingsRouteRoute,
  } as any)

const AccountsAccountIdSettingsReferralsRoute =
  AccountsAccountIdSettingsReferralsImport.update({
    id: '/referrals',
    path: '/referrals',
    getParentRoute: () => AccountsAccountIdSettingsRouteRoute,
  } as any)

const AccountsAccountIdSettingsOverviewRoute =
  AccountsAccountIdSettingsOverviewImport.update({
    id: '/overview',
    path: '/overview',
    getParentRoute: () => AccountsAccountIdSettingsRouteRoute,
  } as any)

const AccountsAccountIdOnboardingStepRoute =
  AccountsAccountIdOnboardingStepImport.update({
    id: '/$step',
    path: '/$step',
    getParentRoute: () => AccountsAccountIdOnboardingRouteRoute,
  } as any)

const AccountsAccountIdMessagingMessagesRoute =
  AccountsAccountIdMessagingMessagesImport.update({
    id: '/messages',
    path: '/messages',
    getParentRoute: () => AccountsAccountIdMessagingRouteRoute,
  } as any)

const AccountsAccountIdLocationsSplatRoute =
  AccountsAccountIdLocationsSplatImport.update({
    id: '/locations/$',
    path: '/locations/$',
    getParentRoute: () => AccountsAccountIdRouteRoute,
  } as any)

const AccountsAccountIdCheckoutSplatRoute =
  AccountsAccountIdCheckoutSplatImport.update({
    id: '/$',
    path: '/$',
    getParentRoute: () => AccountsAccountIdCheckoutRouteRoute,
  } as any)

const AccountsAccountIdChangePlanPlanRoute =
  AccountsAccountIdChangePlanPlanImport.update({
    id: '/change-plan/$plan',
    path: '/change-plan/$plan',
    getParentRoute: () => AccountsAccountIdRouteRoute,
  } as any)

const pairingGuideConnectVendorPairRoute =
  pairingGuideConnectVendorPairImport.update({
    id: '/(pairing-guide)/connect/$vendor/pair',
    path: '/connect/$vendor/pair',
    getParentRoute: () => rootRoute,
  } as any)

const pairingGuideConnectVendorPairingCodeRoute =
  pairingGuideConnectVendorPairingCodeImport.update({
    id: '/(pairing-guide)/connect/$vendor/$pairingCode_',
    path: '/connect/$vendor/$pairingCode',
    getParentRoute: () => rootRoute,
  } as any)

const miscInvoiceAccountIdRefRoute = miscInvoiceAccountIdRefImport.update({
  id: '/(misc)/invoice/$accountId/$ref',
  path: '/invoice/$accountId/$ref',
  getParentRoute: () => rootRoute,
} as any)

const miscExchangeratesDateCurrencyRoute =
  miscExchangeratesDateCurrencyImport.update({
    id: '/(misc)/exchangerates/$date/$currency',
    path: '/exchangerates/$date/$currency',
    getParentRoute: () => rootRoute,
  } as any)

const loggedOutLoginSamlOrgSlugRoute = loggedOutLoginSamlOrgSlugImport.update({
  id: '/login/saml/$orgSlug',
  path: '/login/saml/$orgSlug',
  getParentRoute: () => loggedOutRouteRoute,
} as any)

const AccountsAccountIdSettingsSubscriptionsRouteRoute =
  AccountsAccountIdSettingsSubscriptionsRouteImport.update({
    id: '/subscriptions',
    path: '/subscriptions',
    getParentRoute: () => AccountsAccountIdSettingsRouteRoute,
  } as any)

const AccountsAccountIdSettingsBillingRouteRoute =
  AccountsAccountIdSettingsBillingRouteImport.update({
    id: '/billing',
    path: '/billing',
    getParentRoute: () => AccountsAccountIdSettingsRouteRoute,
  } as any)

const AccountsAccountIdZonesCreateIndexRoute =
  AccountsAccountIdZonesCreateIndexImport.update({
    id: '/create/',
    path: '/create/',
    getParentRoute: () => AccountsAccountIdZonesRouteRoute,
  } as any)

const AccountsAccountIdZonesZoneIdIndexRoute =
  AccountsAccountIdZonesZoneIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AccountsAccountIdZonesZoneIdRoute,
  } as any)

const AccountsAccountIdYourMusicSchedulesIndexRoute =
  AccountsAccountIdYourMusicSchedulesIndexImport.update({
    id: '/schedules/',
    path: '/schedules/',
    getParentRoute: () => AccountsAccountIdYourMusicRouteRoute,
  } as any)

const AccountsAccountIdYourMusicPlaylistsIndexRoute =
  AccountsAccountIdYourMusicPlaylistsIndexImport.update({
    id: '/playlists/',
    path: '/playlists/',
    getParentRoute: () => AccountsAccountIdYourMusicRouteRoute,
  } as any)

const AccountsAccountIdSettingsSubscriptionsIndexRoute =
  AccountsAccountIdSettingsSubscriptionsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AccountsAccountIdSettingsSubscriptionsRouteRoute,
  } as any)

const AccountsAccountIdMessagingCampaignsIndexRoute =
  AccountsAccountIdMessagingCampaignsIndexImport.update({
    id: '/campaigns/',
    path: '/campaigns/',
    getParentRoute: () => AccountsAccountIdMessagingRouteRoute,
  } as any)

const AccountsAccountIdZonesCreateLocationIdRoute =
  AccountsAccountIdZonesCreateLocationIdImport.update({
    id: '/create/$locationId',
    path: '/create/$locationId',
    getParentRoute: () => AccountsAccountIdZonesRouteRoute,
  } as any)

const AccountsAccountIdZonesZoneIdSubscriptionRoute =
  AccountsAccountIdZonesZoneIdSubscriptionImport.update({
    id: '/subscription',
    path: '/subscription',
    getParentRoute: () => AccountsAccountIdZonesZoneIdRoute,
  } as any)

const AccountsAccountIdZonesZoneIdSettingsRoute =
  AccountsAccountIdZonesZoneIdSettingsImport.update({
    id: '/settings',
    path: '/settings',
    getParentRoute: () => AccountsAccountIdZonesZoneIdRoute,
  } as any)

const AccountsAccountIdZonesZoneIdRemoteRoute =
  AccountsAccountIdZonesZoneIdRemoteImport.update({
    id: '/remote',
    path: '/remote',
    getParentRoute: () => AccountsAccountIdZonesZoneIdRoute,
  } as any)

const AccountsAccountIdZonesZoneIdPlayerRoute =
  AccountsAccountIdZonesZoneIdPlayerImport.update({
    id: '/player',
    path: '/player',
    getParentRoute: () => AccountsAccountIdZonesZoneIdRoute,
  } as any)

const AccountsAccountIdZonesZoneIdControlRoute =
  AccountsAccountIdZonesZoneIdControlImport.update({
    id: '/control',
    path: '/control',
    getParentRoute: () => AccountsAccountIdZonesZoneIdRoute,
  } as any)

const AccountsAccountIdYourMusicSchedulesScheduleIdRoute =
  AccountsAccountIdYourMusicSchedulesScheduleIdImport.update({
    id: '/your-music_/schedules/$scheduleId',
    path: '/your-music/schedules/$scheduleId',
    getParentRoute: () => AccountsAccountIdRouteRoute,
  } as any)

const AccountsAccountIdYourMusicSchedulesEditRoute =
  AccountsAccountIdYourMusicSchedulesEditImport.update({
    id: '/schedules/edit',
    path: '/schedules/edit',
    getParentRoute: () => AccountsAccountIdYourMusicRouteRoute,
  } as any)

const AccountsAccountIdYourMusicSchedulesCreateRoute =
  AccountsAccountIdYourMusicSchedulesCreateImport.update({
    id: '/schedules/create',
    path: '/schedules/create',
    getParentRoute: () => AccountsAccountIdYourMusicRouteRoute,
  } as any)

const AccountsAccountIdYourMusicPlaylistsMusicIdRoute =
  AccountsAccountIdYourMusicPlaylistsMusicIdImport.update({
    id: '/playlists/$musicId',
    path: '/playlists/$musicId',
    getParentRoute: () => AccountsAccountIdYourMusicRouteRoute,
  } as any)

const AccountsAccountIdSettingsUsersInviteRoute =
  AccountsAccountIdSettingsUsersInviteImport.update({
    id: '/invite',
    path: '/invite',
    getParentRoute: () => AccountsAccountIdSettingsUsersRoute,
  } as any)

const AccountsAccountIdSettingsSubscriptionsStreamingRoute =
  AccountsAccountIdSettingsSubscriptionsStreamingImport.update({
    id: '/streaming',
    path: '/streaming',
    getParentRoute: () => AccountsAccountIdSettingsSubscriptionsRouteRoute,
  } as any)

const AccountsAccountIdSettingsOverviewCompanyDetailsRoute =
  AccountsAccountIdSettingsOverviewCompanyDetailsImport.update({
    id: '/company-details',
    path: '/company-details',
    getParentRoute: () => AccountsAccountIdSettingsOverviewRoute,
  } as any)

const AccountsAccountIdSettingsBillingUpdateBillingEmailRoute =
  AccountsAccountIdSettingsBillingUpdateBillingEmailImport.update({
    id: '/update-billing-email',
    path: '/update-billing-email',
    getParentRoute: () => AccountsAccountIdSettingsBillingRouteRoute,
  } as any)

const AccountsAccountIdMessagingMessagesCreateRoute =
  AccountsAccountIdMessagingMessagesCreateImport.update({
    id: '/create',
    path: '/create',
    getParentRoute: () => AccountsAccountIdMessagingMessagesRoute,
  } as any)

const AccountsAccountIdMessagingMessagesMessageIdRoute =
  AccountsAccountIdMessagingMessagesMessageIdImport.update({
    id: '/$messageId',
    path: '/$messageId',
    getParentRoute: () => AccountsAccountIdMessagingMessagesRoute,
  } as any)

const AccountsAccountIdMessagingCampaignsCreateRoute =
  AccountsAccountIdMessagingCampaignsCreateImport.update({
    id: '/campaigns/create',
    path: '/campaigns/create',
    getParentRoute: () => AccountsAccountIdMessagingRouteRoute,
  } as any)

const AccountsAccountIdMessagingCampaignsCampaignIdRoute =
  AccountsAccountIdMessagingCampaignsCampaignIdImport.update({
    id: '/campaigns/$campaignId',
    path: '/campaigns/$campaignId',
    getParentRoute: () => AccountsAccountIdMessagingRouteRoute,
  } as any)

const AccountsAccountIdChangePlanConfirmedPlanRoute =
  AccountsAccountIdChangePlanConfirmedPlanImport.update({
    id: '/change-plan/confirmed/$plan',
    path: '/change-plan/confirmed/$plan',
    getParentRoute: () => AccountsAccountIdRouteRoute,
  } as any)

const AccountsAccountIdAddonsAddonIdActivateRoute =
  AccountsAccountIdAddonsAddonIdActivateImport.update({
    id: '/addons/$addonId/activate',
    path: '/addons/$addonId/activate',
    getParentRoute: () => AccountsAccountIdRouteRoute,
  } as any)

const pairingGuideSonosPairV2HouseholdIdRoute =
  pairingGuideSonosPairV2HouseholdIdImport.update({
    id: '/(pairing-guide)/sonos/pair/v2/$householdId',
    path: '/sonos/pair/v2/$householdId',
    getParentRoute: () => rootRoute,
  } as any)

const pairingGuideSonosConnectV2HouseholdIdRoute =
  pairingGuideSonosConnectV2HouseholdIdImport.update({
    id: '/(pairing-guide)/sonos/connect/v2/$householdId',
    path: '/sonos/connect/v2/$householdId',
    getParentRoute: () => rootRoute,
  } as any)

const pairingGuideConnectVendorPairingCodePairRoute =
  pairingGuideConnectVendorPairingCodePairImport.update({
    id: '/(pairing-guide)/connect/$vendor/$pairingCode/pair',
    path: '/connect/$vendor/$pairingCode/pair',
    getParentRoute: () => rootRoute,
  } as any)

const AccountsAccountIdZonesZoneIdPlayerHistoryRoute =
  AccountsAccountIdZonesZoneIdPlayerHistoryImport.update({
    id: '/$zoneId_/player/history',
    path: '/$zoneId/player/history',
    getParentRoute: () => AccountsAccountIdZonesRouteRoute,
  } as any)

const AccountsAccountIdZonesZoneIdPlayerBlockedTracksRoute =
  AccountsAccountIdZonesZoneIdPlayerBlockedTracksImport.update({
    id: '/$zoneId_/player/blocked-tracks',
    path: '/$zoneId/player/blocked-tracks',
    getParentRoute: () => AccountsAccountIdZonesRouteRoute,
  } as any)

const AccountsAccountIdZonesZoneIdSubscriptionActionRoute =
  AccountsAccountIdZonesZoneIdSubscriptionActionImport.update({
    id: '/$action',
    path: '/$action',
    getParentRoute: () => AccountsAccountIdZonesZoneIdSubscriptionRoute,
  } as any)

const AccountsAccountIdZonesZoneIdPlayerActionRoute =
  AccountsAccountIdZonesZoneIdPlayerActionImport.update({
    id: '/$action',
    path: '/$action',
    getParentRoute: () => AccountsAccountIdZonesZoneIdPlayerRoute,
  } as any)

const AccountsAccountIdYourMusicSchedulesEditScheduleIdRoute =
  AccountsAccountIdYourMusicSchedulesEditScheduleIdImport.update({
    id: '/$scheduleId',
    path: '/$scheduleId',
    getParentRoute: () => AccountsAccountIdYourMusicSchedulesEditRoute,
  } as any)

const AccountsAccountIdYourMusicSchedulesScheduleIdEditRoute =
  AccountsAccountIdYourMusicSchedulesScheduleIdEditImport.update({
    id: '/schedules/$scheduleId/edit',
    path: '/schedules/$scheduleId/edit',
    getParentRoute: () => AccountsAccountIdYourMusicRouteRoute,
  } as any)

const AccountsAccountIdYourMusicSchedulesScheduleIdCopyRoute =
  AccountsAccountIdYourMusicSchedulesScheduleIdCopyImport.update({
    id: '/schedules/$scheduleId/copy',
    path: '/schedules/$scheduleId/copy',
    getParentRoute: () => AccountsAccountIdYourMusicRouteRoute,
  } as any)

const AccountsAccountIdSettingsSubscriptionsStreamingZoneIdRoute =
  AccountsAccountIdSettingsSubscriptionsStreamingZoneIdImport.update({
    id: '/$zoneId',
    path: '/$zoneId',
    getParentRoute: () => AccountsAccountIdSettingsSubscriptionsStreamingRoute,
  } as any)

const AccountsAccountIdSettingsSubscriptionsAddonsSubscriptionIdRoute =
  AccountsAccountIdSettingsSubscriptionsAddonsSubscriptionIdImport.update({
    id: '/addons/$subscriptionId',
    path: '/addons/$subscriptionId',
    getParentRoute: () => AccountsAccountIdSettingsSubscriptionsRouteRoute,
  } as any)

const AccountsAccountIdSettingsBillingUpdatePaymentTypeRoute =
  AccountsAccountIdSettingsBillingUpdatePaymentTypeImport.update({
    id: '/update-payment/$type',
    path: '/update-payment/$type',
    getParentRoute: () => AccountsAccountIdSettingsBillingRouteRoute,
  } as any)

const pairingGuideConnectVendorPairingCodePairZoneIdRoute =
  pairingGuideConnectVendorPairingCodePairZoneIdImport.update({
    id: '/$zoneId',
    path: '/$zoneId',
    getParentRoute: () => pairingGuideConnectVendorPairingCodePairRoute,
  } as any)

const AccountsAccountIdZonesZoneIdPlayerPairPlayerRoute =
  AccountsAccountIdZonesZoneIdPlayerPairPlayerImport.update({
    id: '/pair/$player',
    path: '/pair/$player',
    getParentRoute: () => AccountsAccountIdZonesZoneIdPlayerRoute,
  } as any)

const AccountsAccountIdSettingsSubscriptionsStreamingZoneIdActionRoute =
  AccountsAccountIdSettingsSubscriptionsStreamingZoneIdActionImport.update({
    id: '/$action',
    path: '/$action',
    getParentRoute: () =>
      AccountsAccountIdSettingsSubscriptionsStreamingZoneIdRoute,
  } as any)

const AccountsAccountIdSettingsBillingInvoiceGroupEditGroupIdRoute =
  AccountsAccountIdSettingsBillingInvoiceGroupEditGroupIdImport.update({
    id: '/invoice-group/edit/$groupId',
    path: '/invoice-group/edit/$groupId',
    getParentRoute: () => AccountsAccountIdSettingsBillingRouteRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/(logged-out)': {
      id: '/(logged-out)'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof loggedOutRouteImport
      parentRoute: typeof IndexRoute
    }
    '/create': {
      id: '/create'
      path: '/create'
      fullPath: '/create'
      preLoaderRoute: typeof CreateRouteImport
      parentRoute: typeof rootRoute
    }
    '/dev': {
      id: '/dev'
      path: '/dev'
      fullPath: '/dev'
      preLoaderRoute: typeof DevRouteImport
      parentRoute: typeof rootRoute
    }
    '/discover': {
      id: '/discover'
      path: '/discover'
      fullPath: '/discover'
      preLoaderRoute: typeof DiscoverRouteImport
      parentRoute: typeof rootRoute
    }
    '/electron': {
      id: '/electron'
      path: '/electron'
      fullPath: '/electron'
      preLoaderRoute: typeof ElectronRouteImport
      parentRoute: typeof rootRoute
    }
    '/home': {
      id: '/home'
      path: '/home'
      fullPath: '/home'
      preLoaderRoute: typeof HomeRouteImport
      parentRoute: typeof rootRoute
    }
    '/partner-portal': {
      id: '/partner-portal'
      path: '/partner-portal'
      fullPath: '/partner-portal'
      preLoaderRoute: typeof PartnerPortalRouteImport
      parentRoute: typeof rootRoute
    }
    '/search': {
      id: '/search'
      path: '/search'
      fullPath: '/search'
      preLoaderRoute: typeof SearchRouteImport
      parentRoute: typeof rootRoute
    }
    '/setup-guide': {
      id: '/setup-guide'
      path: '/setup-guide'
      fullPath: '/setup-guide'
      preLoaderRoute: typeof SetupGuideRouteImport
      parentRoute: typeof rootRoute
    }
    '/user': {
      id: '/user'
      path: '/user'
      fullPath: '/user'
      preLoaderRoute: typeof UserRouteImport
      parentRoute: typeof rootRoute
    }
    '/account': {
      id: '/account'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof AccountImport
      parentRoute: typeof rootRoute
    }
    '/download': {
      id: '/download'
      path: '/download'
      fullPath: '/download'
      preLoaderRoute: typeof DownloadImport
      parentRoute: typeof rootRoute
    }
    '/signup': {
      id: '/signup'
      path: '/signup'
      fullPath: '/signup'
      preLoaderRoute: typeof SignupImport
      parentRoute: typeof rootRoute
    }
    '/spotify-callback': {
      id: '/spotify-callback'
      path: '/spotify-callback'
      fullPath: '/spotify-callback'
      preLoaderRoute: typeof SpotifyCallbackImport
      parentRoute: typeof rootRoute
    }
    '/accounts/$accountId': {
      id: '/accounts/$accountId'
      path: '/accounts/$accountId'
      fullPath: '/accounts/$accountId'
      preLoaderRoute: typeof AccountsAccountIdRouteImport
      parentRoute: typeof rootRoute
    }
    '/(logged-out)/forgot-password': {
      id: '/(logged-out)/forgot-password'
      path: '/forgot-password'
      fullPath: '/forgot-password'
      preLoaderRoute: typeof loggedOutForgotPasswordImport
      parentRoute: typeof loggedOutRouteImport
    }
    '/(misc)/logout': {
      id: '/(misc)/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof miscLogoutImport
      parentRoute: typeof rootRoute
    }
    '/(misc)/nps-survey': {
      id: '/(misc)/nps-survey'
      path: '/nps-survey'
      fullPath: '/nps-survey'
      preLoaderRoute: typeof miscNpsSurveyImport
      parentRoute: typeof rootRoute
    }
    '/(misc)/sso': {
      id: '/(misc)/sso'
      path: '/sso'
      fullPath: '/sso'
      preLoaderRoute: typeof miscSsoImport
      parentRoute: typeof rootRoute
    }
    '/create/ai-playlist': {
      id: '/create/ai-playlist'
      path: '/ai-playlist'
      fullPath: '/create/ai-playlist'
      preLoaderRoute: typeof CreateAiPlaylistImport
      parentRoute: typeof CreateRouteImport
    }
    '/create/ai-station': {
      id: '/create/ai-station'
      path: '/ai-station'
      fullPath: '/create/ai-station'
      preLoaderRoute: typeof CreateAiStationImport
      parentRoute: typeof CreateRouteImport
    }
    '/create/import-spotify-playlist': {
      id: '/create/import-spotify-playlist'
      path: '/import-spotify-playlist'
      fullPath: '/create/import-spotify-playlist'
      preLoaderRoute: typeof CreateImportSpotifyPlaylistImport
      parentRoute: typeof CreateRouteImport
    }
    '/create/playlist': {
      id: '/create/playlist'
      path: '/playlist'
      fullPath: '/create/playlist'
      preLoaderRoute: typeof CreatePlaylistImport
      parentRoute: typeof CreateRouteImport
    }
    '/create/schedule': {
      id: '/create/schedule'
      path: '/schedule'
      fullPath: '/create/schedule'
      preLoaderRoute: typeof CreateScheduleImport
      parentRoute: typeof CreateRouteImport
    }
    '/create/synced-playlist': {
      id: '/create/synced-playlist'
      path: '/synced-playlist'
      fullPath: '/create/synced-playlist'
      preLoaderRoute: typeof CreateSyncedPlaylistImport
      parentRoute: typeof CreateRouteImport
    }
    '/dev/drag-drop': {
      id: '/dev/drag-drop'
      path: '/drag-drop'
      fullPath: '/dev/drag-drop'
      preLoaderRoute: typeof DevDragDropImport
      parentRoute: typeof DevRouteImport
    }
    '/dev/flags': {
      id: '/dev/flags'
      path: '/flags'
      fullPath: '/dev/flags'
      preLoaderRoute: typeof DevFlagsImport
      parentRoute: typeof DevRouteImport
    }
    '/dev/playback': {
      id: '/dev/playback'
      path: '/playback'
      fullPath: '/dev/playback'
      preLoaderRoute: typeof DevPlaybackImport
      parentRoute: typeof DevRouteImport
    }
    '/dev/price-breakdown': {
      id: '/dev/price-breakdown'
      path: '/price-breakdown'
      fullPath: '/dev/price-breakdown'
      preLoaderRoute: typeof DevPriceBreakdownImport
      parentRoute: typeof DevRouteImport
    }
    '/dev/sandbox': {
      id: '/dev/sandbox'
      path: '/sandbox'
      fullPath: '/dev/sandbox'
      preLoaderRoute: typeof DevSandboxImport
      parentRoute: typeof DevRouteImport
    }
    '/dev/sentry': {
      id: '/dev/sentry'
      path: '/sentry'
      fullPath: '/dev/sentry'
      preLoaderRoute: typeof DevSentryImport
      parentRoute: typeof DevRouteImport
    }
    '/dev/surveys': {
      id: '/dev/surveys'
      path: '/surveys'
      fullPath: '/dev/surveys'
      preLoaderRoute: typeof DevSurveysImport
      parentRoute: typeof DevRouteImport
    }
    '/dev/toasts': {
      id: '/dev/toasts'
      path: '/toasts'
      fullPath: '/dev/toasts'
      preLoaderRoute: typeof DevToastsImport
      parentRoute: typeof DevRouteImport
    }
    '/dev/tooltips': {
      id: '/dev/tooltips'
      path: '/tooltips'
      fullPath: '/dev/tooltips'
      preLoaderRoute: typeof DevTooltipsImport
      parentRoute: typeof DevRouteImport
    }
    '/discover/$': {
      id: '/discover/$'
      path: '/$'
      fullPath: '/discover/$'
      preLoaderRoute: typeof DiscoverSplatImport
      parentRoute: typeof DiscoverRouteImport
    }
    '/electron/browser-opened': {
      id: '/electron/browser-opened'
      path: '/browser-opened'
      fullPath: '/electron/browser-opened'
      preLoaderRoute: typeof ElectronBrowserOpenedImport
      parentRoute: typeof ElectronRouteImport
    }
    '/electron/select': {
      id: '/electron/select'
      path: '/select'
      fullPath: '/electron/select'
      preLoaderRoute: typeof ElectronSelectImport
      parentRoute: typeof ElectronRouteImport
    }
    '/home/$page': {
      id: '/home/$page'
      path: '/$page'
      fullPath: '/home/$page'
      preLoaderRoute: typeof HomePageImport
      parentRoute: typeof HomeRouteImport
    }
    '/partner-portal/$accountId': {
      id: '/partner-portal/$accountId'
      path: '/$accountId'
      fullPath: '/partner-portal/$accountId'
      preLoaderRoute: typeof PartnerPortalAccountIdImport
      parentRoute: typeof PartnerPortalRouteImport
    }
    '/search/$': {
      id: '/search/$'
      path: '/$'
      fullPath: '/search/$'
      preLoaderRoute: typeof SearchSplatImport
      parentRoute: typeof SearchRouteImport
    }
    '/setup-guide/business-description': {
      id: '/setup-guide/business-description'
      path: '/business-description'
      fullPath: '/setup-guide/business-description'
      preLoaderRoute: typeof SetupGuideBusinessDescriptionImport
      parentRoute: typeof SetupGuideRouteImport
    }
    '/setup-guide/choose-plan': {
      id: '/setup-guide/choose-plan'
      path: '/choose-plan'
      fullPath: '/setup-guide/choose-plan'
      preLoaderRoute: typeof SetupGuideChoosePlanImport
      parentRoute: typeof SetupGuideRouteImport
    }
    '/setup-guide/create-playlist': {
      id: '/setup-guide/create-playlist'
      path: '/create-playlist'
      fullPath: '/setup-guide/create-playlist'
      preLoaderRoute: typeof SetupGuideCreatePlaylistImport
      parentRoute: typeof SetupGuideRouteImport
    }
    '/setup-guide/explicit-setting': {
      id: '/setup-guide/explicit-setting'
      path: '/explicit-setting'
      fullPath: '/setup-guide/explicit-setting'
      preLoaderRoute: typeof SetupGuideExplicitSettingImport
      parentRoute: typeof SetupGuideRouteImport
    }
    '/setup-guide/pair': {
      id: '/setup-guide/pair'
      path: '/pair'
      fullPath: '/setup-guide/pair'
      preLoaderRoute: typeof SetupGuidePairImport
      parentRoute: typeof SetupGuideRouteImport
    }
    '/setup-guide/qr-app-login': {
      id: '/setup-guide/qr-app-login'
      path: '/qr-app-login'
      fullPath: '/setup-guide/qr-app-login'
      preLoaderRoute: typeof SetupGuideQrAppLoginImport
      parentRoute: typeof SetupGuideRouteImport
    }
    '/setup-guide/see-options': {
      id: '/setup-guide/see-options'
      path: '/see-options'
      fullPath: '/setup-guide/see-options'
      preLoaderRoute: typeof SetupGuideSeeOptionsImport
      parentRoute: typeof SetupGuideRouteImport
    }
    '/setup-guide/spotify-import': {
      id: '/setup-guide/spotify-import'
      path: '/spotify-import'
      fullPath: '/setup-guide/spotify-import'
      preLoaderRoute: typeof SetupGuideSpotifyImportImport
      parentRoute: typeof SetupGuideRouteImport
    }
    '/setup-guide/user-invite': {
      id: '/setup-guide/user-invite'
      path: '/user-invite'
      fullPath: '/setup-guide/user-invite'
      preLoaderRoute: typeof SetupGuideUserInviteImport
      parentRoute: typeof SetupGuideRouteImport
    }
    '/signup/$': {
      id: '/signup/$'
      path: '/$'
      fullPath: '/signup/$'
      preLoaderRoute: typeof SignupSplatImport
      parentRoute: typeof SignupImport
    }
    '/user/change-email': {
      id: '/user/change-email'
      path: '/change-email'
      fullPath: '/user/change-email'
      preLoaderRoute: typeof UserChangeEmailImport
      parentRoute: typeof UserRouteImport
    }
    '/user/change-password': {
      id: '/user/change-password'
      path: '/change-password'
      fullPath: '/user/change-password'
      preLoaderRoute: typeof UserChangePasswordImport
      parentRoute: typeof UserRouteImport
    }
    '/user/edit': {
      id: '/user/edit'
      path: '/edit'
      fullPath: '/user/edit'
      preLoaderRoute: typeof UserEditImport
      parentRoute: typeof UserRouteImport
    }
    '/addons/': {
      id: '/addons/'
      path: '/addons'
      fullPath: '/addons'
      preLoaderRoute: typeof AddonsIndexImport
      parentRoute: typeof rootRoute
    }
    '/create/': {
      id: '/create/'
      path: '/'
      fullPath: '/create/'
      preLoaderRoute: typeof CreateIndexImport
      parentRoute: typeof CreateRouteImport
    }
    '/dev/': {
      id: '/dev/'
      path: '/'
      fullPath: '/dev/'
      preLoaderRoute: typeof DevIndexImport
      parentRoute: typeof DevRouteImport
    }
    '/discover/': {
      id: '/discover/'
      path: '/'
      fullPath: '/discover/'
      preLoaderRoute: typeof DiscoverIndexImport
      parentRoute: typeof DiscoverRouteImport
    }
    '/home/': {
      id: '/home/'
      path: '/'
      fullPath: '/home/'
      preLoaderRoute: typeof HomeIndexImport
      parentRoute: typeof HomeRouteImport
    }
    '/partner-portal/': {
      id: '/partner-portal/'
      path: '/'
      fullPath: '/partner-portal/'
      preLoaderRoute: typeof PartnerPortalIndexImport
      parentRoute: typeof PartnerPortalRouteImport
    }
    '/accounts/$accountId/checkout': {
      id: '/accounts/$accountId/checkout'
      path: '/checkout'
      fullPath: '/accounts/$accountId/checkout'
      preLoaderRoute: typeof AccountsAccountIdCheckoutRouteImport
      parentRoute: typeof AccountsAccountIdRouteImport
    }
    '/accounts/$accountId/messaging': {
      id: '/accounts/$accountId/messaging'
      path: '/messaging'
      fullPath: '/accounts/$accountId/messaging'
      preLoaderRoute: typeof AccountsAccountIdMessagingRouteImport
      parentRoute: typeof AccountsAccountIdRouteImport
    }
    '/accounts/$accountId/onboarding': {
      id: '/accounts/$accountId/onboarding'
      path: '/onboarding'
      fullPath: '/accounts/$accountId/onboarding'
      preLoaderRoute: typeof AccountsAccountIdOnboardingRouteImport
      parentRoute: typeof AccountsAccountIdRouteImport
    }
    '/accounts/$accountId/settings': {
      id: '/accounts/$accountId/settings'
      path: '/settings'
      fullPath: '/accounts/$accountId/settings'
      preLoaderRoute: typeof AccountsAccountIdSettingsRouteImport
      parentRoute: typeof AccountsAccountIdRouteImport
    }
    '/accounts/$accountId/your-music': {
      id: '/accounts/$accountId/your-music'
      path: '/your-music'
      fullPath: '/accounts/$accountId/your-music'
      preLoaderRoute: typeof AccountsAccountIdYourMusicRouteImport
      parentRoute: typeof AccountsAccountIdRouteImport
    }
    '/accounts/$accountId/zones': {
      id: '/accounts/$accountId/zones'
      path: '/zones'
      fullPath: '/accounts/$accountId/zones'
      preLoaderRoute: typeof AccountsAccountIdZonesRouteImport
      parentRoute: typeof AccountsAccountIdRouteImport
    }
    '/(logged-out)/invitation/$token': {
      id: '/(logged-out)/invitation/$token'
      path: '/invitation/$token'
      fullPath: '/invitation/$token'
      preLoaderRoute: typeof loggedOutInvitationTokenImport
      parentRoute: typeof loggedOutRouteImport
    }
    '/(logged-out)/reset-password/$token': {
      id: '/(logged-out)/reset-password/$token'
      path: '/reset-password/$token'
      fullPath: '/reset-password/$token'
      preLoaderRoute: typeof loggedOutResetPasswordTokenImport
      parentRoute: typeof loggedOutRouteImport
    }
    '/(logged-out)/verify-email/$token': {
      id: '/(logged-out)/verify-email/$token'
      path: '/verify-email/$token'
      fullPath: '/verify-email/$token'
      preLoaderRoute: typeof loggedOutVerifyEmailTokenImport
      parentRoute: typeof loggedOutRouteImport
    }
    '/(misc)/invoice/$uuid': {
      id: '/(misc)/invoice/$uuid'
      path: '/invoice/$uuid'
      fullPath: '/invoice/$uuid'
      preLoaderRoute: typeof miscInvoiceUuidImport
      parentRoute: typeof rootRoute
    }
    '/(misc)/legal-certificate/print': {
      id: '/(misc)/legal-certificate/print'
      path: '/legal-certificate/print'
      fullPath: '/legal-certificate/print'
      preLoaderRoute: typeof miscLegalCertificatePrintImport
      parentRoute: typeof rootRoute
    }
    '/(misc)/nps-survey/$variant': {
      id: '/(misc)/nps-survey/$variant'
      path: '/$variant'
      fullPath: '/nps-survey/$variant'
      preLoaderRoute: typeof miscNpsSurveyVariantImport
      parentRoute: typeof miscNpsSurveyImport
    }
    '/(misc)/referral/$code': {
      id: '/(misc)/referral/$code'
      path: '/referral/$code'
      fullPath: '/referral/$code'
      preLoaderRoute: typeof miscReferralCodeImport
      parentRoute: typeof rootRoute
    }
    '/(misc)/sso/$': {
      id: '/(misc)/sso/$'
      path: '/$'
      fullPath: '/sso/$'
      preLoaderRoute: typeof miscSsoSplatImport
      parentRoute: typeof miscSsoImport
    }
    '/addons/activated/$addon': {
      id: '/addons/activated/$addon'
      path: '/addons/activated/$addon'
      fullPath: '/addons/activated/$addon'
      preLoaderRoute: typeof AddonsActivatedAddonImport
      parentRoute: typeof rootRoute
    }
    '/addons/promo/curation': {
      id: '/addons/promo/curation'
      path: '/addons/promo/curation'
      fullPath: '/addons/promo/curation'
      preLoaderRoute: typeof AddonsPromoCurationImport
      parentRoute: typeof rootRoute
    }
    '/addons/promo/hifi': {
      id: '/addons/promo/hifi'
      path: '/addons/promo/hifi'
      fullPath: '/addons/promo/hifi'
      preLoaderRoute: typeof AddonsPromoHifiImport
      parentRoute: typeof rootRoute
    }
    '/addons/promo/messaging': {
      id: '/addons/promo/messaging'
      path: '/addons/promo/messaging'
      fullPath: '/addons/promo/messaging'
      preLoaderRoute: typeof AddonsPromoMessagingImport
      parentRoute: typeof rootRoute
    }
    '/addons/promo/sso': {
      id: '/addons/promo/sso'
      path: '/addons/promo/sso'
      fullPath: '/addons/promo/sso'
      preLoaderRoute: typeof AddonsPromoSsoImport
      parentRoute: typeof rootRoute
    }
    '/create/station/from-playlist': {
      id: '/create/station/from-playlist'
      path: '/station/from-playlist'
      fullPath: '/create/station/from-playlist'
      preLoaderRoute: typeof CreateStationFromPlaylistImport
      parentRoute: typeof CreateRouteImport
    }
    '/create/station/from-tags': {
      id: '/create/station/from-tags'
      path: '/station/from-tags'
      fullPath: '/create/station/from-tags'
      preLoaderRoute: typeof CreateStationFromTagsImport
      parentRoute: typeof CreateRouteImport
    }
    '/discover/album/$releaseId': {
      id: '/discover/album/$releaseId'
      path: '/album/$releaseId'
      fullPath: '/discover/album/$releaseId'
      preLoaderRoute: typeof DiscoverAlbumReleaseIdImport
      parentRoute: typeof DiscoverRouteImport
    }
    '/discover/music/$musicId': {
      id: '/discover/music/$musicId'
      path: '/music/$musicId'
      fullPath: '/discover/music/$musicId'
      preLoaderRoute: typeof DiscoverMusicMusicIdImport
      parentRoute: typeof DiscoverRouteImport
    }
    '/discover/schedule/$id': {
      id: '/discover/schedule/$id'
      path: '/schedule/$id'
      fullPath: '/discover/schedule/$id'
      preLoaderRoute: typeof DiscoverScheduleIdImport
      parentRoute: typeof DiscoverRouteImport
    }
    '/electron/login/email': {
      id: '/electron/login/email'
      path: '/login/email'
      fullPath: '/electron/login/email'
      preLoaderRoute: typeof ElectronLoginEmailImport
      parentRoute: typeof ElectronRouteImport
    }
    '/electron/login/pairing-code': {
      id: '/electron/login/pairing-code'
      path: '/login/pairing-code'
      fullPath: '/electron/login/pairing-code'
      preLoaderRoute: typeof ElectronLoginPairingCodeImport
      parentRoute: typeof ElectronRouteImport
    }
    '/electron/select/$accountId': {
      id: '/electron/select/$accountId'
      path: '/$accountId'
      fullPath: '/electron/select/$accountId'
      preLoaderRoute: typeof ElectronSelectAccountIdImport
      parentRoute: typeof ElectronSelectImport
    }
    '/partner-portal/$accountId/create': {
      id: '/partner-portal/$accountId/create'
      path: '/create'
      fullPath: '/partner-portal/$accountId/create'
      preLoaderRoute: typeof PartnerPortalAccountIdCreateImport
      parentRoute: typeof PartnerPortalAccountIdImport
    }
    '/setup-guide/pair/$action': {
      id: '/setup-guide/pair/$action'
      path: '/$action'
      fullPath: '/setup-guide/pair/$action'
      preLoaderRoute: typeof SetupGuidePairActionImport
      parentRoute: typeof SetupGuidePairImport
    }
    '/(logged-out)/invitation/': {
      id: '/(logged-out)/invitation/'
      path: '/invitation'
      fullPath: '/invitation'
      preLoaderRoute: typeof loggedOutInvitationIndexImport
      parentRoute: typeof loggedOutRouteImport
    }
    '/(logged-out)/login/': {
      id: '/(logged-out)/login/'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof loggedOutLoginIndexImport
      parentRoute: typeof loggedOutRouteImport
    }
    '/(misc)/legal-certificate/': {
      id: '/(misc)/legal-certificate/'
      path: '/legal-certificate'
      fullPath: '/legal-certificate'
      preLoaderRoute: typeof miscLegalCertificateIndexImport
      parentRoute: typeof rootRoute
    }
    '/(misc)/nps-survey/': {
      id: '/(misc)/nps-survey/'
      path: '/'
      fullPath: '/nps-survey/'
      preLoaderRoute: typeof miscNpsSurveyIndexImport
      parentRoute: typeof miscNpsSurveyImport
    }
    '/accounts/$accountId/': {
      id: '/accounts/$accountId/'
      path: '/'
      fullPath: '/accounts/$accountId/'
      preLoaderRoute: typeof AccountsAccountIdIndexImport
      parentRoute: typeof AccountsAccountIdRouteImport
    }
    '/electron/login/': {
      id: '/electron/login/'
      path: '/login'
      fullPath: '/electron/login'
      preLoaderRoute: typeof ElectronLoginIndexImport
      parentRoute: typeof ElectronRouteImport
    }
    '/setup-guide/pair/': {
      id: '/setup-guide/pair/'
      path: '/'
      fullPath: '/setup-guide/pair/'
      preLoaderRoute: typeof SetupGuidePairIndexImport
      parentRoute: typeof SetupGuidePairImport
    }
    '/accounts/$accountId/settings/billing': {
      id: '/accounts/$accountId/settings/billing'
      path: '/billing'
      fullPath: '/accounts/$accountId/settings/billing'
      preLoaderRoute: typeof AccountsAccountIdSettingsBillingRouteImport
      parentRoute: typeof AccountsAccountIdSettingsRouteImport
    }
    '/accounts/$accountId/settings/subscriptions': {
      id: '/accounts/$accountId/settings/subscriptions'
      path: '/subscriptions'
      fullPath: '/accounts/$accountId/settings/subscriptions'
      preLoaderRoute: typeof AccountsAccountIdSettingsSubscriptionsRouteImport
      parentRoute: typeof AccountsAccountIdSettingsRouteImport
    }
    '/(logged-out)/login/saml/$orgSlug': {
      id: '/(logged-out)/login/saml/$orgSlug'
      path: '/login/saml/$orgSlug'
      fullPath: '/login/saml/$orgSlug'
      preLoaderRoute: typeof loggedOutLoginSamlOrgSlugImport
      parentRoute: typeof loggedOutRouteImport
    }
    '/(misc)/exchangerates/$date/$currency': {
      id: '/(misc)/exchangerates/$date/$currency'
      path: '/exchangerates/$date/$currency'
      fullPath: '/exchangerates/$date/$currency'
      preLoaderRoute: typeof miscExchangeratesDateCurrencyImport
      parentRoute: typeof rootRoute
    }
    '/(misc)/invoice/$accountId/$ref': {
      id: '/(misc)/invoice/$accountId/$ref'
      path: '/invoice/$accountId/$ref'
      fullPath: '/invoice/$accountId/$ref'
      preLoaderRoute: typeof miscInvoiceAccountIdRefImport
      parentRoute: typeof rootRoute
    }
    '/(pairing-guide)/connect/$vendor/$pairingCode_': {
      id: '/(pairing-guide)/connect/$vendor/$pairingCode_'
      path: '/connect/$vendor/$pairingCode'
      fullPath: '/connect/$vendor/$pairingCode'
      preLoaderRoute: typeof pairingGuideConnectVendorPairingCodeImport
      parentRoute: typeof rootRoute
    }
    '/(pairing-guide)/connect/$vendor/pair': {
      id: '/(pairing-guide)/connect/$vendor/pair'
      path: '/connect/$vendor/pair'
      fullPath: '/connect/$vendor/pair'
      preLoaderRoute: typeof pairingGuideConnectVendorPairImport
      parentRoute: typeof rootRoute
    }
    '/accounts/$accountId/change-plan/$plan': {
      id: '/accounts/$accountId/change-plan/$plan'
      path: '/change-plan/$plan'
      fullPath: '/accounts/$accountId/change-plan/$plan'
      preLoaderRoute: typeof AccountsAccountIdChangePlanPlanImport
      parentRoute: typeof AccountsAccountIdRouteImport
    }
    '/accounts/$accountId/checkout/$': {
      id: '/accounts/$accountId/checkout/$'
      path: '/$'
      fullPath: '/accounts/$accountId/checkout/$'
      preLoaderRoute: typeof AccountsAccountIdCheckoutSplatImport
      parentRoute: typeof AccountsAccountIdCheckoutRouteImport
    }
    '/accounts/$accountId/locations/$': {
      id: '/accounts/$accountId/locations/$'
      path: '/locations/$'
      fullPath: '/accounts/$accountId/locations/$'
      preLoaderRoute: typeof AccountsAccountIdLocationsSplatImport
      parentRoute: typeof AccountsAccountIdRouteImport
    }
    '/accounts/$accountId/messaging/messages': {
      id: '/accounts/$accountId/messaging/messages'
      path: '/messages'
      fullPath: '/accounts/$accountId/messaging/messages'
      preLoaderRoute: typeof AccountsAccountIdMessagingMessagesImport
      parentRoute: typeof AccountsAccountIdMessagingRouteImport
    }
    '/accounts/$accountId/onboarding/$step': {
      id: '/accounts/$accountId/onboarding/$step'
      path: '/$step'
      fullPath: '/accounts/$accountId/onboarding/$step'
      preLoaderRoute: typeof AccountsAccountIdOnboardingStepImport
      parentRoute: typeof AccountsAccountIdOnboardingRouteImport
    }
    '/accounts/$accountId/settings/overview': {
      id: '/accounts/$accountId/settings/overview'
      path: '/overview'
      fullPath: '/accounts/$accountId/settings/overview'
      preLoaderRoute: typeof AccountsAccountIdSettingsOverviewImport
      parentRoute: typeof AccountsAccountIdSettingsRouteImport
    }
    '/accounts/$accountId/settings/referrals': {
      id: '/accounts/$accountId/settings/referrals'
      path: '/referrals'
      fullPath: '/accounts/$accountId/settings/referrals'
      preLoaderRoute: typeof AccountsAccountIdSettingsReferralsImport
      parentRoute: typeof AccountsAccountIdSettingsRouteImport
    }
    '/accounts/$accountId/settings/saml': {
      id: '/accounts/$accountId/settings/saml'
      path: '/saml'
      fullPath: '/accounts/$accountId/settings/saml'
      preLoaderRoute: typeof AccountsAccountIdSettingsSamlImport
      parentRoute: typeof AccountsAccountIdSettingsRouteImport
    }
    '/accounts/$accountId/settings/users': {
      id: '/accounts/$accountId/settings/users'
      path: '/users'
      fullPath: '/accounts/$accountId/settings/users'
      preLoaderRoute: typeof AccountsAccountIdSettingsUsersImport
      parentRoute: typeof AccountsAccountIdSettingsRouteImport
    }
    '/accounts/$accountId/settings/zone-presets': {
      id: '/accounts/$accountId/settings/zone-presets'
      path: '/zone-presets'
      fullPath: '/accounts/$accountId/settings/zone-presets'
      preLoaderRoute: typeof AccountsAccountIdSettingsZonePresetsImport
      parentRoute: typeof AccountsAccountIdSettingsRouteImport
    }
    '/accounts/$accountId/voucher/$code': {
      id: '/accounts/$accountId/voucher/$code'
      path: '/voucher/$code'
      fullPath: '/accounts/$accountId/voucher/$code'
      preLoaderRoute: typeof AccountsAccountIdVoucherCodeImport
      parentRoute: typeof AccountsAccountIdRouteImport
    }
    '/accounts/$accountId/your-music_/$musicId': {
      id: '/accounts/$accountId/your-music_/$musicId'
      path: '/your-music/$musicId'
      fullPath: '/accounts/$accountId/your-music/$musicId'
      preLoaderRoute: typeof AccountsAccountIdYourMusicMusicIdImport
      parentRoute: typeof AccountsAccountIdRouteImport
    }
    '/accounts/$accountId/zones/$zoneId': {
      id: '/accounts/$accountId/zones/$zoneId'
      path: '/$zoneId'
      fullPath: '/accounts/$accountId/zones/$zoneId'
      preLoaderRoute: typeof AccountsAccountIdZonesZoneIdImport
      parentRoute: typeof AccountsAccountIdZonesRouteImport
    }
    '/discover/categories/$type/$categoryId': {
      id: '/discover/categories/$type/$categoryId'
      path: '/categories/$type/$categoryId'
      fullPath: '/discover/categories/$type/$categoryId'
      preLoaderRoute: typeof DiscoverCategoriesTypeCategoryIdImport
      parentRoute: typeof DiscoverRouteImport
    }
    '/discover/music/$musicId/edit': {
      id: '/discover/music/$musicId/edit'
      path: '/edit'
      fullPath: '/discover/music/$musicId/edit'
      preLoaderRoute: typeof DiscoverMusicMusicIdEditImport
      parentRoute: typeof DiscoverMusicMusicIdImport
    }
    '/discover/schedule/$id/edit': {
      id: '/discover/schedule/$id/edit'
      path: '/edit'
      fullPath: '/discover/schedule/$id/edit'
      preLoaderRoute: typeof DiscoverScheduleIdEditImport
      parentRoute: typeof DiscoverScheduleIdImport
    }
    '/electron/select/$accountId/$locationId': {
      id: '/electron/select/$accountId/$locationId'
      path: '/$locationId'
      fullPath: '/electron/select/$accountId/$locationId'
      preLoaderRoute: typeof ElectronSelectAccountIdLocationIdImport
      parentRoute: typeof ElectronSelectAccountIdImport
    }
    '/(logged-out)/login/saml/': {
      id: '/(logged-out)/login/saml/'
      path: '/login/saml'
      fullPath: '/login/saml'
      preLoaderRoute: typeof loggedOutLoginSamlIndexImport
      parentRoute: typeof loggedOutRouteImport
    }
    '/(pairing-guide)/connect/$vendor/': {
      id: '/(pairing-guide)/connect/$vendor/'
      path: '/connect/$vendor'
      fullPath: '/connect/$vendor'
      preLoaderRoute: typeof pairingGuideConnectVendorIndexImport
      parentRoute: typeof rootRoute
    }
    '/accounts/$accountId/change-plan/': {
      id: '/accounts/$accountId/change-plan/'
      path: '/change-plan'
      fullPath: '/accounts/$accountId/change-plan'
      preLoaderRoute: typeof AccountsAccountIdChangePlanIndexImport
      parentRoute: typeof AccountsAccountIdRouteImport
    }
    '/accounts/$accountId/choose-plan/': {
      id: '/accounts/$accountId/choose-plan/'
      path: '/choose-plan'
      fullPath: '/accounts/$accountId/choose-plan'
      preLoaderRoute: typeof AccountsAccountIdChoosePlanIndexImport
      parentRoute: typeof AccountsAccountIdRouteImport
    }
    '/accounts/$accountId/locations/': {
      id: '/accounts/$accountId/locations/'
      path: '/locations'
      fullPath: '/accounts/$accountId/locations'
      preLoaderRoute: typeof AccountsAccountIdLocationsIndexImport
      parentRoute: typeof AccountsAccountIdRouteImport
    }
    '/accounts/$accountId/messaging/': {
      id: '/accounts/$accountId/messaging/'
      path: '/'
      fullPath: '/accounts/$accountId/messaging/'
      preLoaderRoute: typeof AccountsAccountIdMessagingIndexImport
      parentRoute: typeof AccountsAccountIdMessagingRouteImport
    }
    '/accounts/$accountId/settings/': {
      id: '/accounts/$accountId/settings/'
      path: '/'
      fullPath: '/accounts/$accountId/settings/'
      preLoaderRoute: typeof AccountsAccountIdSettingsIndexImport
      parentRoute: typeof AccountsAccountIdSettingsRouteImport
    }
    '/accounts/$accountId/upgrade-plan/': {
      id: '/accounts/$accountId/upgrade-plan/'
      path: '/upgrade-plan'
      fullPath: '/accounts/$accountId/upgrade-plan'
      preLoaderRoute: typeof AccountsAccountIdUpgradePlanIndexImport
      parentRoute: typeof AccountsAccountIdRouteImport
    }
    '/accounts/$accountId/your-music/': {
      id: '/accounts/$accountId/your-music/'
      path: '/'
      fullPath: '/accounts/$accountId/your-music/'
      preLoaderRoute: typeof AccountsAccountIdYourMusicIndexImport
      parentRoute: typeof AccountsAccountIdYourMusicRouteImport
    }
    '/discover/music/$musicId/': {
      id: '/discover/music/$musicId/'
      path: '/'
      fullPath: '/discover/music/$musicId/'
      preLoaderRoute: typeof DiscoverMusicMusicIdIndexImport
      parentRoute: typeof DiscoverMusicMusicIdImport
    }
    '/(pairing-guide)/connect/$vendor/$pairingCode/pair': {
      id: '/(pairing-guide)/connect/$vendor/$pairingCode/pair'
      path: '/connect/$vendor/$pairingCode/pair'
      fullPath: '/connect/$vendor/$pairingCode/pair'
      preLoaderRoute: typeof pairingGuideConnectVendorPairingCodePairImport
      parentRoute: typeof rootRoute
    }
    '/(pairing-guide)/sonos/connect/v2/$householdId': {
      id: '/(pairing-guide)/sonos/connect/v2/$householdId'
      path: '/sonos/connect/v2/$householdId'
      fullPath: '/sonos/connect/v2/$householdId'
      preLoaderRoute: typeof pairingGuideSonosConnectV2HouseholdIdImport
      parentRoute: typeof rootRoute
    }
    '/(pairing-guide)/sonos/pair/v2/$householdId': {
      id: '/(pairing-guide)/sonos/pair/v2/$householdId'
      path: '/sonos/pair/v2/$householdId'
      fullPath: '/sonos/pair/v2/$householdId'
      preLoaderRoute: typeof pairingGuideSonosPairV2HouseholdIdImport
      parentRoute: typeof rootRoute
    }
    '/accounts/$accountId/addons/$addonId/activate': {
      id: '/accounts/$accountId/addons/$addonId/activate'
      path: '/addons/$addonId/activate'
      fullPath: '/accounts/$accountId/addons/$addonId/activate'
      preLoaderRoute: typeof AccountsAccountIdAddonsAddonIdActivateImport
      parentRoute: typeof AccountsAccountIdRouteImport
    }
    '/accounts/$accountId/change-plan/confirmed/$plan': {
      id: '/accounts/$accountId/change-plan/confirmed/$plan'
      path: '/change-plan/confirmed/$plan'
      fullPath: '/accounts/$accountId/change-plan/confirmed/$plan'
      preLoaderRoute: typeof AccountsAccountIdChangePlanConfirmedPlanImport
      parentRoute: typeof AccountsAccountIdRouteImport
    }
    '/accounts/$accountId/messaging/campaigns/$campaignId': {
      id: '/accounts/$accountId/messaging/campaigns/$campaignId'
      path: '/campaigns/$campaignId'
      fullPath: '/accounts/$accountId/messaging/campaigns/$campaignId'
      preLoaderRoute: typeof AccountsAccountIdMessagingCampaignsCampaignIdImport
      parentRoute: typeof AccountsAccountIdMessagingRouteImport
    }
    '/accounts/$accountId/messaging/campaigns/create': {
      id: '/accounts/$accountId/messaging/campaigns/create'
      path: '/campaigns/create'
      fullPath: '/accounts/$accountId/messaging/campaigns/create'
      preLoaderRoute: typeof AccountsAccountIdMessagingCampaignsCreateImport
      parentRoute: typeof AccountsAccountIdMessagingRouteImport
    }
    '/accounts/$accountId/messaging/messages/$messageId': {
      id: '/accounts/$accountId/messaging/messages/$messageId'
      path: '/$messageId'
      fullPath: '/accounts/$accountId/messaging/messages/$messageId'
      preLoaderRoute: typeof AccountsAccountIdMessagingMessagesMessageIdImport
      parentRoute: typeof AccountsAccountIdMessagingMessagesImport
    }
    '/accounts/$accountId/messaging/messages/create': {
      id: '/accounts/$accountId/messaging/messages/create'
      path: '/create'
      fullPath: '/accounts/$accountId/messaging/messages/create'
      preLoaderRoute: typeof AccountsAccountIdMessagingMessagesCreateImport
      parentRoute: typeof AccountsAccountIdMessagingMessagesImport
    }
    '/accounts/$accountId/settings/billing/update-billing-email': {
      id: '/accounts/$accountId/settings/billing/update-billing-email'
      path: '/update-billing-email'
      fullPath: '/accounts/$accountId/settings/billing/update-billing-email'
      preLoaderRoute: typeof AccountsAccountIdSettingsBillingUpdateBillingEmailImport
      parentRoute: typeof AccountsAccountIdSettingsBillingRouteImport
    }
    '/accounts/$accountId/settings/overview/company-details': {
      id: '/accounts/$accountId/settings/overview/company-details'
      path: '/company-details'
      fullPath: '/accounts/$accountId/settings/overview/company-details'
      preLoaderRoute: typeof AccountsAccountIdSettingsOverviewCompanyDetailsImport
      parentRoute: typeof AccountsAccountIdSettingsOverviewImport
    }
    '/accounts/$accountId/settings/subscriptions/streaming': {
      id: '/accounts/$accountId/settings/subscriptions/streaming'
      path: '/streaming'
      fullPath: '/accounts/$accountId/settings/subscriptions/streaming'
      preLoaderRoute: typeof AccountsAccountIdSettingsSubscriptionsStreamingImport
      parentRoute: typeof AccountsAccountIdSettingsSubscriptionsRouteImport
    }
    '/accounts/$accountId/settings/users/invite': {
      id: '/accounts/$accountId/settings/users/invite'
      path: '/invite'
      fullPath: '/accounts/$accountId/settings/users/invite'
      preLoaderRoute: typeof AccountsAccountIdSettingsUsersInviteImport
      parentRoute: typeof AccountsAccountIdSettingsUsersImport
    }
    '/accounts/$accountId/your-music/playlists/$musicId': {
      id: '/accounts/$accountId/your-music/playlists/$musicId'
      path: '/playlists/$musicId'
      fullPath: '/accounts/$accountId/your-music/playlists/$musicId'
      preLoaderRoute: typeof AccountsAccountIdYourMusicPlaylistsMusicIdImport
      parentRoute: typeof AccountsAccountIdYourMusicRouteImport
    }
    '/accounts/$accountId/your-music/schedules/create': {
      id: '/accounts/$accountId/your-music/schedules/create'
      path: '/schedules/create'
      fullPath: '/accounts/$accountId/your-music/schedules/create'
      preLoaderRoute: typeof AccountsAccountIdYourMusicSchedulesCreateImport
      parentRoute: typeof AccountsAccountIdYourMusicRouteImport
    }
    '/accounts/$accountId/your-music/schedules/edit': {
      id: '/accounts/$accountId/your-music/schedules/edit'
      path: '/schedules/edit'
      fullPath: '/accounts/$accountId/your-music/schedules/edit'
      preLoaderRoute: typeof AccountsAccountIdYourMusicSchedulesEditImport
      parentRoute: typeof AccountsAccountIdYourMusicRouteImport
    }
    '/accounts/$accountId/your-music_/schedules/$scheduleId': {
      id: '/accounts/$accountId/your-music_/schedules/$scheduleId'
      path: '/your-music/schedules/$scheduleId'
      fullPath: '/accounts/$accountId/your-music/schedules/$scheduleId'
      preLoaderRoute: typeof AccountsAccountIdYourMusicSchedulesScheduleIdImport
      parentRoute: typeof AccountsAccountIdRouteImport
    }
    '/accounts/$accountId/zones/$zoneId/control': {
      id: '/accounts/$accountId/zones/$zoneId/control'
      path: '/control'
      fullPath: '/accounts/$accountId/zones/$zoneId/control'
      preLoaderRoute: typeof AccountsAccountIdZonesZoneIdControlImport
      parentRoute: typeof AccountsAccountIdZonesZoneIdImport
    }
    '/accounts/$accountId/zones/$zoneId/player': {
      id: '/accounts/$accountId/zones/$zoneId/player'
      path: '/player'
      fullPath: '/accounts/$accountId/zones/$zoneId/player'
      preLoaderRoute: typeof AccountsAccountIdZonesZoneIdPlayerImport
      parentRoute: typeof AccountsAccountIdZonesZoneIdImport
    }
    '/accounts/$accountId/zones/$zoneId/remote': {
      id: '/accounts/$accountId/zones/$zoneId/remote'
      path: '/remote'
      fullPath: '/accounts/$accountId/zones/$zoneId/remote'
      preLoaderRoute: typeof AccountsAccountIdZonesZoneIdRemoteImport
      parentRoute: typeof AccountsAccountIdZonesZoneIdImport
    }
    '/accounts/$accountId/zones/$zoneId/settings': {
      id: '/accounts/$accountId/zones/$zoneId/settings'
      path: '/settings'
      fullPath: '/accounts/$accountId/zones/$zoneId/settings'
      preLoaderRoute: typeof AccountsAccountIdZonesZoneIdSettingsImport
      parentRoute: typeof AccountsAccountIdZonesZoneIdImport
    }
    '/accounts/$accountId/zones/$zoneId/subscription': {
      id: '/accounts/$accountId/zones/$zoneId/subscription'
      path: '/subscription'
      fullPath: '/accounts/$accountId/zones/$zoneId/subscription'
      preLoaderRoute: typeof AccountsAccountIdZonesZoneIdSubscriptionImport
      parentRoute: typeof AccountsAccountIdZonesZoneIdImport
    }
    '/accounts/$accountId/zones/create/$locationId': {
      id: '/accounts/$accountId/zones/create/$locationId'
      path: '/create/$locationId'
      fullPath: '/accounts/$accountId/zones/create/$locationId'
      preLoaderRoute: typeof AccountsAccountIdZonesCreateLocationIdImport
      parentRoute: typeof AccountsAccountIdZonesRouteImport
    }
    '/accounts/$accountId/messaging/campaigns/': {
      id: '/accounts/$accountId/messaging/campaigns/'
      path: '/campaigns'
      fullPath: '/accounts/$accountId/messaging/campaigns'
      preLoaderRoute: typeof AccountsAccountIdMessagingCampaignsIndexImport
      parentRoute: typeof AccountsAccountIdMessagingRouteImport
    }
    '/accounts/$accountId/settings/subscriptions/': {
      id: '/accounts/$accountId/settings/subscriptions/'
      path: '/'
      fullPath: '/accounts/$accountId/settings/subscriptions/'
      preLoaderRoute: typeof AccountsAccountIdSettingsSubscriptionsIndexImport
      parentRoute: typeof AccountsAccountIdSettingsSubscriptionsRouteImport
    }
    '/accounts/$accountId/your-music/playlists/': {
      id: '/accounts/$accountId/your-music/playlists/'
      path: '/playlists'
      fullPath: '/accounts/$accountId/your-music/playlists'
      preLoaderRoute: typeof AccountsAccountIdYourMusicPlaylistsIndexImport
      parentRoute: typeof AccountsAccountIdYourMusicRouteImport
    }
    '/accounts/$accountId/your-music/schedules/': {
      id: '/accounts/$accountId/your-music/schedules/'
      path: '/schedules'
      fullPath: '/accounts/$accountId/your-music/schedules'
      preLoaderRoute: typeof AccountsAccountIdYourMusicSchedulesIndexImport
      parentRoute: typeof AccountsAccountIdYourMusicRouteImport
    }
    '/accounts/$accountId/zones/$zoneId/': {
      id: '/accounts/$accountId/zones/$zoneId/'
      path: '/'
      fullPath: '/accounts/$accountId/zones/$zoneId/'
      preLoaderRoute: typeof AccountsAccountIdZonesZoneIdIndexImport
      parentRoute: typeof AccountsAccountIdZonesZoneIdImport
    }
    '/accounts/$accountId/zones/create/': {
      id: '/accounts/$accountId/zones/create/'
      path: '/create'
      fullPath: '/accounts/$accountId/zones/create'
      preLoaderRoute: typeof AccountsAccountIdZonesCreateIndexImport
      parentRoute: typeof AccountsAccountIdZonesRouteImport
    }
    '/(pairing-guide)/connect/$vendor/$pairingCode/pair/$zoneId': {
      id: '/(pairing-guide)/connect/$vendor/$pairingCode/pair/$zoneId'
      path: '/$zoneId'
      fullPath: '/connect/$vendor/$pairingCode/pair/$zoneId'
      preLoaderRoute: typeof pairingGuideConnectVendorPairingCodePairZoneIdImport
      parentRoute: typeof pairingGuideConnectVendorPairingCodePairImport
    }
    '/accounts/$accountId/settings/billing/update-payment/$type': {
      id: '/accounts/$accountId/settings/billing/update-payment/$type'
      path: '/update-payment/$type'
      fullPath: '/accounts/$accountId/settings/billing/update-payment/$type'
      preLoaderRoute: typeof AccountsAccountIdSettingsBillingUpdatePaymentTypeImport
      parentRoute: typeof AccountsAccountIdSettingsBillingRouteImport
    }
    '/accounts/$accountId/settings/subscriptions/addons/$subscriptionId': {
      id: '/accounts/$accountId/settings/subscriptions/addons/$subscriptionId'
      path: '/addons/$subscriptionId'
      fullPath: '/accounts/$accountId/settings/subscriptions/addons/$subscriptionId'
      preLoaderRoute: typeof AccountsAccountIdSettingsSubscriptionsAddonsSubscriptionIdImport
      parentRoute: typeof AccountsAccountIdSettingsSubscriptionsRouteImport
    }
    '/accounts/$accountId/settings/subscriptions/streaming/$zoneId': {
      id: '/accounts/$accountId/settings/subscriptions/streaming/$zoneId'
      path: '/$zoneId'
      fullPath: '/accounts/$accountId/settings/subscriptions/streaming/$zoneId'
      preLoaderRoute: typeof AccountsAccountIdSettingsSubscriptionsStreamingZoneIdImport
      parentRoute: typeof AccountsAccountIdSettingsSubscriptionsStreamingImport
    }
    '/accounts/$accountId/your-music/schedules/$scheduleId/copy': {
      id: '/accounts/$accountId/your-music/schedules/$scheduleId/copy'
      path: '/schedules/$scheduleId/copy'
      fullPath: '/accounts/$accountId/your-music/schedules/$scheduleId/copy'
      preLoaderRoute: typeof AccountsAccountIdYourMusicSchedulesScheduleIdCopyImport
      parentRoute: typeof AccountsAccountIdYourMusicRouteImport
    }
    '/accounts/$accountId/your-music/schedules/$scheduleId/edit': {
      id: '/accounts/$accountId/your-music/schedules/$scheduleId/edit'
      path: '/schedules/$scheduleId/edit'
      fullPath: '/accounts/$accountId/your-music/schedules/$scheduleId/edit'
      preLoaderRoute: typeof AccountsAccountIdYourMusicSchedulesScheduleIdEditImport
      parentRoute: typeof AccountsAccountIdYourMusicRouteImport
    }
    '/accounts/$accountId/your-music/schedules/edit/$scheduleId': {
      id: '/accounts/$accountId/your-music/schedules/edit/$scheduleId'
      path: '/$scheduleId'
      fullPath: '/accounts/$accountId/your-music/schedules/edit/$scheduleId'
      preLoaderRoute: typeof AccountsAccountIdYourMusicSchedulesEditScheduleIdImport
      parentRoute: typeof AccountsAccountIdYourMusicSchedulesEditImport
    }
    '/accounts/$accountId/zones/$zoneId/player/$action': {
      id: '/accounts/$accountId/zones/$zoneId/player/$action'
      path: '/$action'
      fullPath: '/accounts/$accountId/zones/$zoneId/player/$action'
      preLoaderRoute: typeof AccountsAccountIdZonesZoneIdPlayerActionImport
      parentRoute: typeof AccountsAccountIdZonesZoneIdPlayerImport
    }
    '/accounts/$accountId/zones/$zoneId/subscription/$action': {
      id: '/accounts/$accountId/zones/$zoneId/subscription/$action'
      path: '/$action'
      fullPath: '/accounts/$accountId/zones/$zoneId/subscription/$action'
      preLoaderRoute: typeof AccountsAccountIdZonesZoneIdSubscriptionActionImport
      parentRoute: typeof AccountsAccountIdZonesZoneIdSubscriptionImport
    }
    '/accounts/$accountId/zones/$zoneId_/player/blocked-tracks': {
      id: '/accounts/$accountId/zones/$zoneId_/player/blocked-tracks'
      path: '/$zoneId/player/blocked-tracks'
      fullPath: '/accounts/$accountId/zones/$zoneId/player/blocked-tracks'
      preLoaderRoute: typeof AccountsAccountIdZonesZoneIdPlayerBlockedTracksImport
      parentRoute: typeof AccountsAccountIdZonesRouteImport
    }
    '/accounts/$accountId/zones/$zoneId_/player/history': {
      id: '/accounts/$accountId/zones/$zoneId_/player/history'
      path: '/$zoneId/player/history'
      fullPath: '/accounts/$accountId/zones/$zoneId/player/history'
      preLoaderRoute: typeof AccountsAccountIdZonesZoneIdPlayerHistoryImport
      parentRoute: typeof AccountsAccountIdZonesRouteImport
    }
    '/accounts/$accountId/settings/billing/invoice-group/edit/$groupId': {
      id: '/accounts/$accountId/settings/billing/invoice-group/edit/$groupId'
      path: '/invoice-group/edit/$groupId'
      fullPath: '/accounts/$accountId/settings/billing/invoice-group/edit/$groupId'
      preLoaderRoute: typeof AccountsAccountIdSettingsBillingInvoiceGroupEditGroupIdImport
      parentRoute: typeof AccountsAccountIdSettingsBillingRouteImport
    }
    '/accounts/$accountId/settings/subscriptions/streaming/$zoneId/$action': {
      id: '/accounts/$accountId/settings/subscriptions/streaming/$zoneId/$action'
      path: '/$action'
      fullPath: '/accounts/$accountId/settings/subscriptions/streaming/$zoneId/$action'
      preLoaderRoute: typeof AccountsAccountIdSettingsSubscriptionsStreamingZoneIdActionImport
      parentRoute: typeof AccountsAccountIdSettingsSubscriptionsStreamingZoneIdImport
    }
    '/accounts/$accountId/zones/$zoneId/player/pair/$player': {
      id: '/accounts/$accountId/zones/$zoneId/player/pair/$player'
      path: '/pair/$player'
      fullPath: '/accounts/$accountId/zones/$zoneId/player/pair/$player'
      preLoaderRoute: typeof AccountsAccountIdZonesZoneIdPlayerPairPlayerImport
      parentRoute: typeof AccountsAccountIdZonesZoneIdPlayerImport
    }
  }
}

// Create and export the route tree

interface loggedOutRouteRouteChildren {
  loggedOutForgotPasswordRoute: typeof loggedOutForgotPasswordRoute
  loggedOutInvitationTokenRoute: typeof loggedOutInvitationTokenRoute
  loggedOutResetPasswordTokenRoute: typeof loggedOutResetPasswordTokenRoute
  loggedOutVerifyEmailTokenRoute: typeof loggedOutVerifyEmailTokenRoute
  loggedOutInvitationIndexRoute: typeof loggedOutInvitationIndexRoute
  loggedOutLoginIndexRoute: typeof loggedOutLoginIndexRoute
  loggedOutLoginSamlOrgSlugRoute: typeof loggedOutLoginSamlOrgSlugRoute
  loggedOutLoginSamlIndexRoute: typeof loggedOutLoginSamlIndexRoute
}

const loggedOutRouteRouteChildren: loggedOutRouteRouteChildren = {
  loggedOutForgotPasswordRoute: loggedOutForgotPasswordRoute,
  loggedOutInvitationTokenRoute: loggedOutInvitationTokenRoute,
  loggedOutResetPasswordTokenRoute: loggedOutResetPasswordTokenRoute,
  loggedOutVerifyEmailTokenRoute: loggedOutVerifyEmailTokenRoute,
  loggedOutInvitationIndexRoute: loggedOutInvitationIndexRoute,
  loggedOutLoginIndexRoute: loggedOutLoginIndexRoute,
  loggedOutLoginSamlOrgSlugRoute: loggedOutLoginSamlOrgSlugRoute,
  loggedOutLoginSamlIndexRoute: loggedOutLoginSamlIndexRoute,
}

const loggedOutRouteRouteWithChildren = loggedOutRouteRoute._addFileChildren(
  loggedOutRouteRouteChildren,
)

interface IndexRouteChildren {
  loggedOutRouteRoute: typeof loggedOutRouteRouteWithChildren
}

const IndexRouteChildren: IndexRouteChildren = {
  loggedOutRouteRoute: loggedOutRouteRouteWithChildren,
}

const IndexRouteWithChildren = IndexRoute._addFileChildren(IndexRouteChildren)

interface CreateRouteRouteChildren {
  CreateAiPlaylistRoute: typeof CreateAiPlaylistRoute
  CreateAiStationRoute: typeof CreateAiStationRoute
  CreateImportSpotifyPlaylistRoute: typeof CreateImportSpotifyPlaylistRoute
  CreatePlaylistRoute: typeof CreatePlaylistRoute
  CreateScheduleRoute: typeof CreateScheduleRoute
  CreateSyncedPlaylistRoute: typeof CreateSyncedPlaylistRoute
  CreateIndexRoute: typeof CreateIndexRoute
  CreateStationFromPlaylistRoute: typeof CreateStationFromPlaylistRoute
  CreateStationFromTagsRoute: typeof CreateStationFromTagsRoute
}

const CreateRouteRouteChildren: CreateRouteRouteChildren = {
  CreateAiPlaylistRoute: CreateAiPlaylistRoute,
  CreateAiStationRoute: CreateAiStationRoute,
  CreateImportSpotifyPlaylistRoute: CreateImportSpotifyPlaylistRoute,
  CreatePlaylistRoute: CreatePlaylistRoute,
  CreateScheduleRoute: CreateScheduleRoute,
  CreateSyncedPlaylistRoute: CreateSyncedPlaylistRoute,
  CreateIndexRoute: CreateIndexRoute,
  CreateStationFromPlaylistRoute: CreateStationFromPlaylistRoute,
  CreateStationFromTagsRoute: CreateStationFromTagsRoute,
}

const CreateRouteRouteWithChildren = CreateRouteRoute._addFileChildren(
  CreateRouteRouteChildren,
)

interface DevRouteRouteChildren {
  DevDragDropRoute: typeof DevDragDropRoute
  DevFlagsRoute: typeof DevFlagsRoute
  DevPlaybackRoute: typeof DevPlaybackRoute
  DevPriceBreakdownRoute: typeof DevPriceBreakdownRoute
  DevSandboxRoute: typeof DevSandboxRoute
  DevSentryRoute: typeof DevSentryRoute
  DevSurveysRoute: typeof DevSurveysRoute
  DevToastsRoute: typeof DevToastsRoute
  DevTooltipsRoute: typeof DevTooltipsRoute
  DevIndexRoute: typeof DevIndexRoute
}

const DevRouteRouteChildren: DevRouteRouteChildren = {
  DevDragDropRoute: DevDragDropRoute,
  DevFlagsRoute: DevFlagsRoute,
  DevPlaybackRoute: DevPlaybackRoute,
  DevPriceBreakdownRoute: DevPriceBreakdownRoute,
  DevSandboxRoute: DevSandboxRoute,
  DevSentryRoute: DevSentryRoute,
  DevSurveysRoute: DevSurveysRoute,
  DevToastsRoute: DevToastsRoute,
  DevTooltipsRoute: DevTooltipsRoute,
  DevIndexRoute: DevIndexRoute,
}

const DevRouteRouteWithChildren = DevRouteRoute._addFileChildren(
  DevRouteRouteChildren,
)

interface DiscoverMusicMusicIdRouteChildren {
  DiscoverMusicMusicIdEditRoute: typeof DiscoverMusicMusicIdEditRoute
  DiscoverMusicMusicIdIndexRoute: typeof DiscoverMusicMusicIdIndexRoute
}

const DiscoverMusicMusicIdRouteChildren: DiscoverMusicMusicIdRouteChildren = {
  DiscoverMusicMusicIdEditRoute: DiscoverMusicMusicIdEditRoute,
  DiscoverMusicMusicIdIndexRoute: DiscoverMusicMusicIdIndexRoute,
}

const DiscoverMusicMusicIdRouteWithChildren =
  DiscoverMusicMusicIdRoute._addFileChildren(DiscoverMusicMusicIdRouteChildren)

interface DiscoverScheduleIdRouteChildren {
  DiscoverScheduleIdEditRoute: typeof DiscoverScheduleIdEditRoute
}

const DiscoverScheduleIdRouteChildren: DiscoverScheduleIdRouteChildren = {
  DiscoverScheduleIdEditRoute: DiscoverScheduleIdEditRoute,
}

const DiscoverScheduleIdRouteWithChildren =
  DiscoverScheduleIdRoute._addFileChildren(DiscoverScheduleIdRouteChildren)

interface DiscoverRouteRouteChildren {
  DiscoverSplatRoute: typeof DiscoverSplatRoute
  DiscoverIndexRoute: typeof DiscoverIndexRoute
  DiscoverAlbumReleaseIdRoute: typeof DiscoverAlbumReleaseIdRoute
  DiscoverMusicMusicIdRoute: typeof DiscoverMusicMusicIdRouteWithChildren
  DiscoverScheduleIdRoute: typeof DiscoverScheduleIdRouteWithChildren
  DiscoverCategoriesTypeCategoryIdRoute: typeof DiscoverCategoriesTypeCategoryIdRoute
}

const DiscoverRouteRouteChildren: DiscoverRouteRouteChildren = {
  DiscoverSplatRoute: DiscoverSplatRoute,
  DiscoverIndexRoute: DiscoverIndexRoute,
  DiscoverAlbumReleaseIdRoute: DiscoverAlbumReleaseIdRoute,
  DiscoverMusicMusicIdRoute: DiscoverMusicMusicIdRouteWithChildren,
  DiscoverScheduleIdRoute: DiscoverScheduleIdRouteWithChildren,
  DiscoverCategoriesTypeCategoryIdRoute: DiscoverCategoriesTypeCategoryIdRoute,
}

const DiscoverRouteRouteWithChildren = DiscoverRouteRoute._addFileChildren(
  DiscoverRouteRouteChildren,
)

interface ElectronSelectAccountIdRouteChildren {
  ElectronSelectAccountIdLocationIdRoute: typeof ElectronSelectAccountIdLocationIdRoute
}

const ElectronSelectAccountIdRouteChildren: ElectronSelectAccountIdRouteChildren =
  {
    ElectronSelectAccountIdLocationIdRoute:
      ElectronSelectAccountIdLocationIdRoute,
  }

const ElectronSelectAccountIdRouteWithChildren =
  ElectronSelectAccountIdRoute._addFileChildren(
    ElectronSelectAccountIdRouteChildren,
  )

interface ElectronSelectRouteChildren {
  ElectronSelectAccountIdRoute: typeof ElectronSelectAccountIdRouteWithChildren
}

const ElectronSelectRouteChildren: ElectronSelectRouteChildren = {
  ElectronSelectAccountIdRoute: ElectronSelectAccountIdRouteWithChildren,
}

const ElectronSelectRouteWithChildren = ElectronSelectRoute._addFileChildren(
  ElectronSelectRouteChildren,
)

interface ElectronRouteRouteChildren {
  ElectronBrowserOpenedRoute: typeof ElectronBrowserOpenedRoute
  ElectronSelectRoute: typeof ElectronSelectRouteWithChildren
  ElectronLoginEmailRoute: typeof ElectronLoginEmailRoute
  ElectronLoginPairingCodeRoute: typeof ElectronLoginPairingCodeRoute
  ElectronLoginIndexRoute: typeof ElectronLoginIndexRoute
}

const ElectronRouteRouteChildren: ElectronRouteRouteChildren = {
  ElectronBrowserOpenedRoute: ElectronBrowserOpenedRoute,
  ElectronSelectRoute: ElectronSelectRouteWithChildren,
  ElectronLoginEmailRoute: ElectronLoginEmailRoute,
  ElectronLoginPairingCodeRoute: ElectronLoginPairingCodeRoute,
  ElectronLoginIndexRoute: ElectronLoginIndexRoute,
}

const ElectronRouteRouteWithChildren = ElectronRouteRoute._addFileChildren(
  ElectronRouteRouteChildren,
)

interface HomeRouteRouteChildren {
  HomePageRoute: typeof HomePageRoute
  HomeIndexRoute: typeof HomeIndexRoute
}

const HomeRouteRouteChildren: HomeRouteRouteChildren = {
  HomePageRoute: HomePageRoute,
  HomeIndexRoute: HomeIndexRoute,
}

const HomeRouteRouteWithChildren = HomeRouteRoute._addFileChildren(
  HomeRouteRouteChildren,
)

interface PartnerPortalAccountIdRouteChildren {
  PartnerPortalAccountIdCreateRoute: typeof PartnerPortalAccountIdCreateRoute
}

const PartnerPortalAccountIdRouteChildren: PartnerPortalAccountIdRouteChildren =
  {
    PartnerPortalAccountIdCreateRoute: PartnerPortalAccountIdCreateRoute,
  }

const PartnerPortalAccountIdRouteWithChildren =
  PartnerPortalAccountIdRoute._addFileChildren(
    PartnerPortalAccountIdRouteChildren,
  )

interface PartnerPortalRouteRouteChildren {
  PartnerPortalAccountIdRoute: typeof PartnerPortalAccountIdRouteWithChildren
  PartnerPortalIndexRoute: typeof PartnerPortalIndexRoute
}

const PartnerPortalRouteRouteChildren: PartnerPortalRouteRouteChildren = {
  PartnerPortalAccountIdRoute: PartnerPortalAccountIdRouteWithChildren,
  PartnerPortalIndexRoute: PartnerPortalIndexRoute,
}

const PartnerPortalRouteRouteWithChildren =
  PartnerPortalRouteRoute._addFileChildren(PartnerPortalRouteRouteChildren)

interface SearchRouteRouteChildren {
  SearchSplatRoute: typeof SearchSplatRoute
}

const SearchRouteRouteChildren: SearchRouteRouteChildren = {
  SearchSplatRoute: SearchSplatRoute,
}

const SearchRouteRouteWithChildren = SearchRouteRoute._addFileChildren(
  SearchRouteRouteChildren,
)

interface SetupGuidePairRouteChildren {
  SetupGuidePairActionRoute: typeof SetupGuidePairActionRoute
  SetupGuidePairIndexRoute: typeof SetupGuidePairIndexRoute
}

const SetupGuidePairRouteChildren: SetupGuidePairRouteChildren = {
  SetupGuidePairActionRoute: SetupGuidePairActionRoute,
  SetupGuidePairIndexRoute: SetupGuidePairIndexRoute,
}

const SetupGuidePairRouteWithChildren = SetupGuidePairRoute._addFileChildren(
  SetupGuidePairRouteChildren,
)

interface SetupGuideRouteRouteChildren {
  SetupGuideBusinessDescriptionRoute: typeof SetupGuideBusinessDescriptionRoute
  SetupGuideChoosePlanRoute: typeof SetupGuideChoosePlanRoute
  SetupGuideCreatePlaylistRoute: typeof SetupGuideCreatePlaylistRoute
  SetupGuideExplicitSettingRoute: typeof SetupGuideExplicitSettingRoute
  SetupGuidePairRoute: typeof SetupGuidePairRouteWithChildren
  SetupGuideQrAppLoginRoute: typeof SetupGuideQrAppLoginRoute
  SetupGuideSeeOptionsRoute: typeof SetupGuideSeeOptionsRoute
  SetupGuideSpotifyImportRoute: typeof SetupGuideSpotifyImportRoute
  SetupGuideUserInviteRoute: typeof SetupGuideUserInviteRoute
}

const SetupGuideRouteRouteChildren: SetupGuideRouteRouteChildren = {
  SetupGuideBusinessDescriptionRoute: SetupGuideBusinessDescriptionRoute,
  SetupGuideChoosePlanRoute: SetupGuideChoosePlanRoute,
  SetupGuideCreatePlaylistRoute: SetupGuideCreatePlaylistRoute,
  SetupGuideExplicitSettingRoute: SetupGuideExplicitSettingRoute,
  SetupGuidePairRoute: SetupGuidePairRouteWithChildren,
  SetupGuideQrAppLoginRoute: SetupGuideQrAppLoginRoute,
  SetupGuideSeeOptionsRoute: SetupGuideSeeOptionsRoute,
  SetupGuideSpotifyImportRoute: SetupGuideSpotifyImportRoute,
  SetupGuideUserInviteRoute: SetupGuideUserInviteRoute,
}

const SetupGuideRouteRouteWithChildren = SetupGuideRouteRoute._addFileChildren(
  SetupGuideRouteRouteChildren,
)

interface UserRouteRouteChildren {
  UserChangeEmailRoute: typeof UserChangeEmailRoute
  UserChangePasswordRoute: typeof UserChangePasswordRoute
  UserEditRoute: typeof UserEditRoute
}

const UserRouteRouteChildren: UserRouteRouteChildren = {
  UserChangeEmailRoute: UserChangeEmailRoute,
  UserChangePasswordRoute: UserChangePasswordRoute,
  UserEditRoute: UserEditRoute,
}

const UserRouteRouteWithChildren = UserRouteRoute._addFileChildren(
  UserRouteRouteChildren,
)

interface SignupRouteChildren {
  SignupSplatRoute: typeof SignupSplatRoute
}

const SignupRouteChildren: SignupRouteChildren = {
  SignupSplatRoute: SignupSplatRoute,
}

const SignupRouteWithChildren =
  SignupRoute._addFileChildren(SignupRouteChildren)

interface AccountsAccountIdCheckoutRouteRouteChildren {
  AccountsAccountIdCheckoutSplatRoute: typeof AccountsAccountIdCheckoutSplatRoute
}

const AccountsAccountIdCheckoutRouteRouteChildren: AccountsAccountIdCheckoutRouteRouteChildren =
  {
    AccountsAccountIdCheckoutSplatRoute: AccountsAccountIdCheckoutSplatRoute,
  }

const AccountsAccountIdCheckoutRouteRouteWithChildren =
  AccountsAccountIdCheckoutRouteRoute._addFileChildren(
    AccountsAccountIdCheckoutRouteRouteChildren,
  )

interface AccountsAccountIdMessagingMessagesRouteChildren {
  AccountsAccountIdMessagingMessagesMessageIdRoute: typeof AccountsAccountIdMessagingMessagesMessageIdRoute
  AccountsAccountIdMessagingMessagesCreateRoute: typeof AccountsAccountIdMessagingMessagesCreateRoute
}

const AccountsAccountIdMessagingMessagesRouteChildren: AccountsAccountIdMessagingMessagesRouteChildren =
  {
    AccountsAccountIdMessagingMessagesMessageIdRoute:
      AccountsAccountIdMessagingMessagesMessageIdRoute,
    AccountsAccountIdMessagingMessagesCreateRoute:
      AccountsAccountIdMessagingMessagesCreateRoute,
  }

const AccountsAccountIdMessagingMessagesRouteWithChildren =
  AccountsAccountIdMessagingMessagesRoute._addFileChildren(
    AccountsAccountIdMessagingMessagesRouteChildren,
  )

interface AccountsAccountIdMessagingRouteRouteChildren {
  AccountsAccountIdMessagingMessagesRoute: typeof AccountsAccountIdMessagingMessagesRouteWithChildren
  AccountsAccountIdMessagingIndexRoute: typeof AccountsAccountIdMessagingIndexRoute
  AccountsAccountIdMessagingCampaignsCampaignIdRoute: typeof AccountsAccountIdMessagingCampaignsCampaignIdRoute
  AccountsAccountIdMessagingCampaignsCreateRoute: typeof AccountsAccountIdMessagingCampaignsCreateRoute
  AccountsAccountIdMessagingCampaignsIndexRoute: typeof AccountsAccountIdMessagingCampaignsIndexRoute
}

const AccountsAccountIdMessagingRouteRouteChildren: AccountsAccountIdMessagingRouteRouteChildren =
  {
    AccountsAccountIdMessagingMessagesRoute:
      AccountsAccountIdMessagingMessagesRouteWithChildren,
    AccountsAccountIdMessagingIndexRoute: AccountsAccountIdMessagingIndexRoute,
    AccountsAccountIdMessagingCampaignsCampaignIdRoute:
      AccountsAccountIdMessagingCampaignsCampaignIdRoute,
    AccountsAccountIdMessagingCampaignsCreateRoute:
      AccountsAccountIdMessagingCampaignsCreateRoute,
    AccountsAccountIdMessagingCampaignsIndexRoute:
      AccountsAccountIdMessagingCampaignsIndexRoute,
  }

const AccountsAccountIdMessagingRouteRouteWithChildren =
  AccountsAccountIdMessagingRouteRoute._addFileChildren(
    AccountsAccountIdMessagingRouteRouteChildren,
  )

interface AccountsAccountIdOnboardingRouteRouteChildren {
  AccountsAccountIdOnboardingStepRoute: typeof AccountsAccountIdOnboardingStepRoute
}

const AccountsAccountIdOnboardingRouteRouteChildren: AccountsAccountIdOnboardingRouteRouteChildren =
  {
    AccountsAccountIdOnboardingStepRoute: AccountsAccountIdOnboardingStepRoute,
  }

const AccountsAccountIdOnboardingRouteRouteWithChildren =
  AccountsAccountIdOnboardingRouteRoute._addFileChildren(
    AccountsAccountIdOnboardingRouteRouteChildren,
  )

interface AccountsAccountIdSettingsBillingRouteRouteChildren {
  AccountsAccountIdSettingsBillingUpdateBillingEmailRoute: typeof AccountsAccountIdSettingsBillingUpdateBillingEmailRoute
  AccountsAccountIdSettingsBillingUpdatePaymentTypeRoute: typeof AccountsAccountIdSettingsBillingUpdatePaymentTypeRoute
  AccountsAccountIdSettingsBillingInvoiceGroupEditGroupIdRoute: typeof AccountsAccountIdSettingsBillingInvoiceGroupEditGroupIdRoute
}

const AccountsAccountIdSettingsBillingRouteRouteChildren: AccountsAccountIdSettingsBillingRouteRouteChildren =
  {
    AccountsAccountIdSettingsBillingUpdateBillingEmailRoute:
      AccountsAccountIdSettingsBillingUpdateBillingEmailRoute,
    AccountsAccountIdSettingsBillingUpdatePaymentTypeRoute:
      AccountsAccountIdSettingsBillingUpdatePaymentTypeRoute,
    AccountsAccountIdSettingsBillingInvoiceGroupEditGroupIdRoute:
      AccountsAccountIdSettingsBillingInvoiceGroupEditGroupIdRoute,
  }

const AccountsAccountIdSettingsBillingRouteRouteWithChildren =
  AccountsAccountIdSettingsBillingRouteRoute._addFileChildren(
    AccountsAccountIdSettingsBillingRouteRouteChildren,
  )

interface AccountsAccountIdSettingsSubscriptionsStreamingZoneIdRouteChildren {
  AccountsAccountIdSettingsSubscriptionsStreamingZoneIdActionRoute: typeof AccountsAccountIdSettingsSubscriptionsStreamingZoneIdActionRoute
}

const AccountsAccountIdSettingsSubscriptionsStreamingZoneIdRouteChildren: AccountsAccountIdSettingsSubscriptionsStreamingZoneIdRouteChildren =
  {
    AccountsAccountIdSettingsSubscriptionsStreamingZoneIdActionRoute:
      AccountsAccountIdSettingsSubscriptionsStreamingZoneIdActionRoute,
  }

const AccountsAccountIdSettingsSubscriptionsStreamingZoneIdRouteWithChildren =
  AccountsAccountIdSettingsSubscriptionsStreamingZoneIdRoute._addFileChildren(
    AccountsAccountIdSettingsSubscriptionsStreamingZoneIdRouteChildren,
  )

interface AccountsAccountIdSettingsSubscriptionsStreamingRouteChildren {
  AccountsAccountIdSettingsSubscriptionsStreamingZoneIdRoute: typeof AccountsAccountIdSettingsSubscriptionsStreamingZoneIdRouteWithChildren
}

const AccountsAccountIdSettingsSubscriptionsStreamingRouteChildren: AccountsAccountIdSettingsSubscriptionsStreamingRouteChildren =
  {
    AccountsAccountIdSettingsSubscriptionsStreamingZoneIdRoute:
      AccountsAccountIdSettingsSubscriptionsStreamingZoneIdRouteWithChildren,
  }

const AccountsAccountIdSettingsSubscriptionsStreamingRouteWithChildren =
  AccountsAccountIdSettingsSubscriptionsStreamingRoute._addFileChildren(
    AccountsAccountIdSettingsSubscriptionsStreamingRouteChildren,
  )

interface AccountsAccountIdSettingsSubscriptionsRouteRouteChildren {
  AccountsAccountIdSettingsSubscriptionsStreamingRoute: typeof AccountsAccountIdSettingsSubscriptionsStreamingRouteWithChildren
  AccountsAccountIdSettingsSubscriptionsIndexRoute: typeof AccountsAccountIdSettingsSubscriptionsIndexRoute
  AccountsAccountIdSettingsSubscriptionsAddonsSubscriptionIdRoute: typeof AccountsAccountIdSettingsSubscriptionsAddonsSubscriptionIdRoute
}

const AccountsAccountIdSettingsSubscriptionsRouteRouteChildren: AccountsAccountIdSettingsSubscriptionsRouteRouteChildren =
  {
    AccountsAccountIdSettingsSubscriptionsStreamingRoute:
      AccountsAccountIdSettingsSubscriptionsStreamingRouteWithChildren,
    AccountsAccountIdSettingsSubscriptionsIndexRoute:
      AccountsAccountIdSettingsSubscriptionsIndexRoute,
    AccountsAccountIdSettingsSubscriptionsAddonsSubscriptionIdRoute:
      AccountsAccountIdSettingsSubscriptionsAddonsSubscriptionIdRoute,
  }

const AccountsAccountIdSettingsSubscriptionsRouteRouteWithChildren =
  AccountsAccountIdSettingsSubscriptionsRouteRoute._addFileChildren(
    AccountsAccountIdSettingsSubscriptionsRouteRouteChildren,
  )

interface AccountsAccountIdSettingsOverviewRouteChildren {
  AccountsAccountIdSettingsOverviewCompanyDetailsRoute: typeof AccountsAccountIdSettingsOverviewCompanyDetailsRoute
}

const AccountsAccountIdSettingsOverviewRouteChildren: AccountsAccountIdSettingsOverviewRouteChildren =
  {
    AccountsAccountIdSettingsOverviewCompanyDetailsRoute:
      AccountsAccountIdSettingsOverviewCompanyDetailsRoute,
  }

const AccountsAccountIdSettingsOverviewRouteWithChildren =
  AccountsAccountIdSettingsOverviewRoute._addFileChildren(
    AccountsAccountIdSettingsOverviewRouteChildren,
  )

interface AccountsAccountIdSettingsUsersRouteChildren {
  AccountsAccountIdSettingsUsersInviteRoute: typeof AccountsAccountIdSettingsUsersInviteRoute
}

const AccountsAccountIdSettingsUsersRouteChildren: AccountsAccountIdSettingsUsersRouteChildren =
  {
    AccountsAccountIdSettingsUsersInviteRoute:
      AccountsAccountIdSettingsUsersInviteRoute,
  }

const AccountsAccountIdSettingsUsersRouteWithChildren =
  AccountsAccountIdSettingsUsersRoute._addFileChildren(
    AccountsAccountIdSettingsUsersRouteChildren,
  )

interface AccountsAccountIdSettingsRouteRouteChildren {
  AccountsAccountIdSettingsBillingRouteRoute: typeof AccountsAccountIdSettingsBillingRouteRouteWithChildren
  AccountsAccountIdSettingsSubscriptionsRouteRoute: typeof AccountsAccountIdSettingsSubscriptionsRouteRouteWithChildren
  AccountsAccountIdSettingsOverviewRoute: typeof AccountsAccountIdSettingsOverviewRouteWithChildren
  AccountsAccountIdSettingsReferralsRoute: typeof AccountsAccountIdSettingsReferralsRoute
  AccountsAccountIdSettingsSamlRoute: typeof AccountsAccountIdSettingsSamlRoute
  AccountsAccountIdSettingsUsersRoute: typeof AccountsAccountIdSettingsUsersRouteWithChildren
  AccountsAccountIdSettingsZonePresetsRoute: typeof AccountsAccountIdSettingsZonePresetsRoute
  AccountsAccountIdSettingsIndexRoute: typeof AccountsAccountIdSettingsIndexRoute
}

const AccountsAccountIdSettingsRouteRouteChildren: AccountsAccountIdSettingsRouteRouteChildren =
  {
    AccountsAccountIdSettingsBillingRouteRoute:
      AccountsAccountIdSettingsBillingRouteRouteWithChildren,
    AccountsAccountIdSettingsSubscriptionsRouteRoute:
      AccountsAccountIdSettingsSubscriptionsRouteRouteWithChildren,
    AccountsAccountIdSettingsOverviewRoute:
      AccountsAccountIdSettingsOverviewRouteWithChildren,
    AccountsAccountIdSettingsReferralsRoute:
      AccountsAccountIdSettingsReferralsRoute,
    AccountsAccountIdSettingsSamlRoute: AccountsAccountIdSettingsSamlRoute,
    AccountsAccountIdSettingsUsersRoute:
      AccountsAccountIdSettingsUsersRouteWithChildren,
    AccountsAccountIdSettingsZonePresetsRoute:
      AccountsAccountIdSettingsZonePresetsRoute,
    AccountsAccountIdSettingsIndexRoute: AccountsAccountIdSettingsIndexRoute,
  }

const AccountsAccountIdSettingsRouteRouteWithChildren =
  AccountsAccountIdSettingsRouteRoute._addFileChildren(
    AccountsAccountIdSettingsRouteRouteChildren,
  )

interface AccountsAccountIdYourMusicSchedulesEditRouteChildren {
  AccountsAccountIdYourMusicSchedulesEditScheduleIdRoute: typeof AccountsAccountIdYourMusicSchedulesEditScheduleIdRoute
}

const AccountsAccountIdYourMusicSchedulesEditRouteChildren: AccountsAccountIdYourMusicSchedulesEditRouteChildren =
  {
    AccountsAccountIdYourMusicSchedulesEditScheduleIdRoute:
      AccountsAccountIdYourMusicSchedulesEditScheduleIdRoute,
  }

const AccountsAccountIdYourMusicSchedulesEditRouteWithChildren =
  AccountsAccountIdYourMusicSchedulesEditRoute._addFileChildren(
    AccountsAccountIdYourMusicSchedulesEditRouteChildren,
  )

interface AccountsAccountIdYourMusicRouteRouteChildren {
  AccountsAccountIdYourMusicIndexRoute: typeof AccountsAccountIdYourMusicIndexRoute
  AccountsAccountIdYourMusicPlaylistsMusicIdRoute: typeof AccountsAccountIdYourMusicPlaylistsMusicIdRoute
  AccountsAccountIdYourMusicSchedulesCreateRoute: typeof AccountsAccountIdYourMusicSchedulesCreateRoute
  AccountsAccountIdYourMusicSchedulesEditRoute: typeof AccountsAccountIdYourMusicSchedulesEditRouteWithChildren
  AccountsAccountIdYourMusicPlaylistsIndexRoute: typeof AccountsAccountIdYourMusicPlaylistsIndexRoute
  AccountsAccountIdYourMusicSchedulesIndexRoute: typeof AccountsAccountIdYourMusicSchedulesIndexRoute
  AccountsAccountIdYourMusicSchedulesScheduleIdCopyRoute: typeof AccountsAccountIdYourMusicSchedulesScheduleIdCopyRoute
  AccountsAccountIdYourMusicSchedulesScheduleIdEditRoute: typeof AccountsAccountIdYourMusicSchedulesScheduleIdEditRoute
}

const AccountsAccountIdYourMusicRouteRouteChildren: AccountsAccountIdYourMusicRouteRouteChildren =
  {
    AccountsAccountIdYourMusicIndexRoute: AccountsAccountIdYourMusicIndexRoute,
    AccountsAccountIdYourMusicPlaylistsMusicIdRoute:
      AccountsAccountIdYourMusicPlaylistsMusicIdRoute,
    AccountsAccountIdYourMusicSchedulesCreateRoute:
      AccountsAccountIdYourMusicSchedulesCreateRoute,
    AccountsAccountIdYourMusicSchedulesEditRoute:
      AccountsAccountIdYourMusicSchedulesEditRouteWithChildren,
    AccountsAccountIdYourMusicPlaylistsIndexRoute:
      AccountsAccountIdYourMusicPlaylistsIndexRoute,
    AccountsAccountIdYourMusicSchedulesIndexRoute:
      AccountsAccountIdYourMusicSchedulesIndexRoute,
    AccountsAccountIdYourMusicSchedulesScheduleIdCopyRoute:
      AccountsAccountIdYourMusicSchedulesScheduleIdCopyRoute,
    AccountsAccountIdYourMusicSchedulesScheduleIdEditRoute:
      AccountsAccountIdYourMusicSchedulesScheduleIdEditRoute,
  }

const AccountsAccountIdYourMusicRouteRouteWithChildren =
  AccountsAccountIdYourMusicRouteRoute._addFileChildren(
    AccountsAccountIdYourMusicRouteRouteChildren,
  )

interface AccountsAccountIdZonesZoneIdPlayerRouteChildren {
  AccountsAccountIdZonesZoneIdPlayerActionRoute: typeof AccountsAccountIdZonesZoneIdPlayerActionRoute
  AccountsAccountIdZonesZoneIdPlayerPairPlayerRoute: typeof AccountsAccountIdZonesZoneIdPlayerPairPlayerRoute
}

const AccountsAccountIdZonesZoneIdPlayerRouteChildren: AccountsAccountIdZonesZoneIdPlayerRouteChildren =
  {
    AccountsAccountIdZonesZoneIdPlayerActionRoute:
      AccountsAccountIdZonesZoneIdPlayerActionRoute,
    AccountsAccountIdZonesZoneIdPlayerPairPlayerRoute:
      AccountsAccountIdZonesZoneIdPlayerPairPlayerRoute,
  }

const AccountsAccountIdZonesZoneIdPlayerRouteWithChildren =
  AccountsAccountIdZonesZoneIdPlayerRoute._addFileChildren(
    AccountsAccountIdZonesZoneIdPlayerRouteChildren,
  )

interface AccountsAccountIdZonesZoneIdSubscriptionRouteChildren {
  AccountsAccountIdZonesZoneIdSubscriptionActionRoute: typeof AccountsAccountIdZonesZoneIdSubscriptionActionRoute
}

const AccountsAccountIdZonesZoneIdSubscriptionRouteChildren: AccountsAccountIdZonesZoneIdSubscriptionRouteChildren =
  {
    AccountsAccountIdZonesZoneIdSubscriptionActionRoute:
      AccountsAccountIdZonesZoneIdSubscriptionActionRoute,
  }

const AccountsAccountIdZonesZoneIdSubscriptionRouteWithChildren =
  AccountsAccountIdZonesZoneIdSubscriptionRoute._addFileChildren(
    AccountsAccountIdZonesZoneIdSubscriptionRouteChildren,
  )

interface AccountsAccountIdZonesZoneIdRouteChildren {
  AccountsAccountIdZonesZoneIdControlRoute: typeof AccountsAccountIdZonesZoneIdControlRoute
  AccountsAccountIdZonesZoneIdPlayerRoute: typeof AccountsAccountIdZonesZoneIdPlayerRouteWithChildren
  AccountsAccountIdZonesZoneIdRemoteRoute: typeof AccountsAccountIdZonesZoneIdRemoteRoute
  AccountsAccountIdZonesZoneIdSettingsRoute: typeof AccountsAccountIdZonesZoneIdSettingsRoute
  AccountsAccountIdZonesZoneIdSubscriptionRoute: typeof AccountsAccountIdZonesZoneIdSubscriptionRouteWithChildren
  AccountsAccountIdZonesZoneIdIndexRoute: typeof AccountsAccountIdZonesZoneIdIndexRoute
}

const AccountsAccountIdZonesZoneIdRouteChildren: AccountsAccountIdZonesZoneIdRouteChildren =
  {
    AccountsAccountIdZonesZoneIdControlRoute:
      AccountsAccountIdZonesZoneIdControlRoute,
    AccountsAccountIdZonesZoneIdPlayerRoute:
      AccountsAccountIdZonesZoneIdPlayerRouteWithChildren,
    AccountsAccountIdZonesZoneIdRemoteRoute:
      AccountsAccountIdZonesZoneIdRemoteRoute,
    AccountsAccountIdZonesZoneIdSettingsRoute:
      AccountsAccountIdZonesZoneIdSettingsRoute,
    AccountsAccountIdZonesZoneIdSubscriptionRoute:
      AccountsAccountIdZonesZoneIdSubscriptionRouteWithChildren,
    AccountsAccountIdZonesZoneIdIndexRoute:
      AccountsAccountIdZonesZoneIdIndexRoute,
  }

const AccountsAccountIdZonesZoneIdRouteWithChildren =
  AccountsAccountIdZonesZoneIdRoute._addFileChildren(
    AccountsAccountIdZonesZoneIdRouteChildren,
  )

interface AccountsAccountIdZonesRouteRouteChildren {
  AccountsAccountIdZonesZoneIdRoute: typeof AccountsAccountIdZonesZoneIdRouteWithChildren
  AccountsAccountIdZonesCreateLocationIdRoute: typeof AccountsAccountIdZonesCreateLocationIdRoute
  AccountsAccountIdZonesCreateIndexRoute: typeof AccountsAccountIdZonesCreateIndexRoute
  AccountsAccountIdZonesZoneIdPlayerBlockedTracksRoute: typeof AccountsAccountIdZonesZoneIdPlayerBlockedTracksRoute
  AccountsAccountIdZonesZoneIdPlayerHistoryRoute: typeof AccountsAccountIdZonesZoneIdPlayerHistoryRoute
}

const AccountsAccountIdZonesRouteRouteChildren: AccountsAccountIdZonesRouteRouteChildren =
  {
    AccountsAccountIdZonesZoneIdRoute:
      AccountsAccountIdZonesZoneIdRouteWithChildren,
    AccountsAccountIdZonesCreateLocationIdRoute:
      AccountsAccountIdZonesCreateLocationIdRoute,
    AccountsAccountIdZonesCreateIndexRoute:
      AccountsAccountIdZonesCreateIndexRoute,
    AccountsAccountIdZonesZoneIdPlayerBlockedTracksRoute:
      AccountsAccountIdZonesZoneIdPlayerBlockedTracksRoute,
    AccountsAccountIdZonesZoneIdPlayerHistoryRoute:
      AccountsAccountIdZonesZoneIdPlayerHistoryRoute,
  }

const AccountsAccountIdZonesRouteRouteWithChildren =
  AccountsAccountIdZonesRouteRoute._addFileChildren(
    AccountsAccountIdZonesRouteRouteChildren,
  )

interface AccountsAccountIdRouteRouteChildren {
  AccountsAccountIdCheckoutRouteRoute: typeof AccountsAccountIdCheckoutRouteRouteWithChildren
  AccountsAccountIdMessagingRouteRoute: typeof AccountsAccountIdMessagingRouteRouteWithChildren
  AccountsAccountIdOnboardingRouteRoute: typeof AccountsAccountIdOnboardingRouteRouteWithChildren
  AccountsAccountIdSettingsRouteRoute: typeof AccountsAccountIdSettingsRouteRouteWithChildren
  AccountsAccountIdYourMusicRouteRoute: typeof AccountsAccountIdYourMusicRouteRouteWithChildren
  AccountsAccountIdZonesRouteRoute: typeof AccountsAccountIdZonesRouteRouteWithChildren
  AccountsAccountIdIndexRoute: typeof AccountsAccountIdIndexRoute
  AccountsAccountIdChangePlanPlanRoute: typeof AccountsAccountIdChangePlanPlanRoute
  AccountsAccountIdLocationsSplatRoute: typeof AccountsAccountIdLocationsSplatRoute
  AccountsAccountIdVoucherCodeRoute: typeof AccountsAccountIdVoucherCodeRoute
  AccountsAccountIdYourMusicMusicIdRoute: typeof AccountsAccountIdYourMusicMusicIdRoute
  AccountsAccountIdChangePlanIndexRoute: typeof AccountsAccountIdChangePlanIndexRoute
  AccountsAccountIdChoosePlanIndexRoute: typeof AccountsAccountIdChoosePlanIndexRoute
  AccountsAccountIdLocationsIndexRoute: typeof AccountsAccountIdLocationsIndexRoute
  AccountsAccountIdUpgradePlanIndexRoute: typeof AccountsAccountIdUpgradePlanIndexRoute
  AccountsAccountIdAddonsAddonIdActivateRoute: typeof AccountsAccountIdAddonsAddonIdActivateRoute
  AccountsAccountIdChangePlanConfirmedPlanRoute: typeof AccountsAccountIdChangePlanConfirmedPlanRoute
  AccountsAccountIdYourMusicSchedulesScheduleIdRoute: typeof AccountsAccountIdYourMusicSchedulesScheduleIdRoute
}

const AccountsAccountIdRouteRouteChildren: AccountsAccountIdRouteRouteChildren =
  {
    AccountsAccountIdCheckoutRouteRoute:
      AccountsAccountIdCheckoutRouteRouteWithChildren,
    AccountsAccountIdMessagingRouteRoute:
      AccountsAccountIdMessagingRouteRouteWithChildren,
    AccountsAccountIdOnboardingRouteRoute:
      AccountsAccountIdOnboardingRouteRouteWithChildren,
    AccountsAccountIdSettingsRouteRoute:
      AccountsAccountIdSettingsRouteRouteWithChildren,
    AccountsAccountIdYourMusicRouteRoute:
      AccountsAccountIdYourMusicRouteRouteWithChildren,
    AccountsAccountIdZonesRouteRoute:
      AccountsAccountIdZonesRouteRouteWithChildren,
    AccountsAccountIdIndexRoute: AccountsAccountIdIndexRoute,
    AccountsAccountIdChangePlanPlanRoute: AccountsAccountIdChangePlanPlanRoute,
    AccountsAccountIdLocationsSplatRoute: AccountsAccountIdLocationsSplatRoute,
    AccountsAccountIdVoucherCodeRoute: AccountsAccountIdVoucherCodeRoute,
    AccountsAccountIdYourMusicMusicIdRoute:
      AccountsAccountIdYourMusicMusicIdRoute,
    AccountsAccountIdChangePlanIndexRoute:
      AccountsAccountIdChangePlanIndexRoute,
    AccountsAccountIdChoosePlanIndexRoute:
      AccountsAccountIdChoosePlanIndexRoute,
    AccountsAccountIdLocationsIndexRoute: AccountsAccountIdLocationsIndexRoute,
    AccountsAccountIdUpgradePlanIndexRoute:
      AccountsAccountIdUpgradePlanIndexRoute,
    AccountsAccountIdAddonsAddonIdActivateRoute:
      AccountsAccountIdAddonsAddonIdActivateRoute,
    AccountsAccountIdChangePlanConfirmedPlanRoute:
      AccountsAccountIdChangePlanConfirmedPlanRoute,
    AccountsAccountIdYourMusicSchedulesScheduleIdRoute:
      AccountsAccountIdYourMusicSchedulesScheduleIdRoute,
  }

const AccountsAccountIdRouteRouteWithChildren =
  AccountsAccountIdRouteRoute._addFileChildren(
    AccountsAccountIdRouteRouteChildren,
  )

interface miscNpsSurveyRouteChildren {
  miscNpsSurveyVariantRoute: typeof miscNpsSurveyVariantRoute
  miscNpsSurveyIndexRoute: typeof miscNpsSurveyIndexRoute
}

const miscNpsSurveyRouteChildren: miscNpsSurveyRouteChildren = {
  miscNpsSurveyVariantRoute: miscNpsSurveyVariantRoute,
  miscNpsSurveyIndexRoute: miscNpsSurveyIndexRoute,
}

const miscNpsSurveyRouteWithChildren = miscNpsSurveyRoute._addFileChildren(
  miscNpsSurveyRouteChildren,
)

interface miscSsoRouteChildren {
  miscSsoSplatRoute: typeof miscSsoSplatRoute
}

const miscSsoRouteChildren: miscSsoRouteChildren = {
  miscSsoSplatRoute: miscSsoSplatRoute,
}

const miscSsoRouteWithChildren =
  miscSsoRoute._addFileChildren(miscSsoRouteChildren)

interface pairingGuideConnectVendorPairingCodePairRouteChildren {
  pairingGuideConnectVendorPairingCodePairZoneIdRoute: typeof pairingGuideConnectVendorPairingCodePairZoneIdRoute
}

const pairingGuideConnectVendorPairingCodePairRouteChildren: pairingGuideConnectVendorPairingCodePairRouteChildren =
  {
    pairingGuideConnectVendorPairingCodePairZoneIdRoute:
      pairingGuideConnectVendorPairingCodePairZoneIdRoute,
  }

const pairingGuideConnectVendorPairingCodePairRouteWithChildren =
  pairingGuideConnectVendorPairingCodePairRoute._addFileChildren(
    pairingGuideConnectVendorPairingCodePairRouteChildren,
  )

export interface FileRoutesByFullPath {
  '/': typeof loggedOutRouteRouteWithChildren
  '/create': typeof CreateRouteRouteWithChildren
  '/dev': typeof DevRouteRouteWithChildren
  '/discover': typeof DiscoverRouteRouteWithChildren
  '/electron': typeof ElectronRouteRouteWithChildren
  '/home': typeof HomeRouteRouteWithChildren
  '/partner-portal': typeof PartnerPortalRouteRouteWithChildren
  '/search': typeof SearchRouteRouteWithChildren
  '/setup-guide': typeof SetupGuideRouteRouteWithChildren
  '/user': typeof UserRouteRouteWithChildren
  '/account': typeof AccountRoute
  '/download': typeof DownloadRoute
  '/signup': typeof SignupRouteWithChildren
  '/spotify-callback': typeof SpotifyCallbackRoute
  '/accounts/$accountId': typeof AccountsAccountIdRouteRouteWithChildren
  '/forgot-password': typeof loggedOutForgotPasswordRoute
  '/logout': typeof miscLogoutRoute
  '/nps-survey': typeof miscNpsSurveyRouteWithChildren
  '/sso': typeof miscSsoRouteWithChildren
  '/create/ai-playlist': typeof CreateAiPlaylistRoute
  '/create/ai-station': typeof CreateAiStationRoute
  '/create/import-spotify-playlist': typeof CreateImportSpotifyPlaylistRoute
  '/create/playlist': typeof CreatePlaylistRoute
  '/create/schedule': typeof CreateScheduleRoute
  '/create/synced-playlist': typeof CreateSyncedPlaylistRoute
  '/dev/drag-drop': typeof DevDragDropRoute
  '/dev/flags': typeof DevFlagsRoute
  '/dev/playback': typeof DevPlaybackRoute
  '/dev/price-breakdown': typeof DevPriceBreakdownRoute
  '/dev/sandbox': typeof DevSandboxRoute
  '/dev/sentry': typeof DevSentryRoute
  '/dev/surveys': typeof DevSurveysRoute
  '/dev/toasts': typeof DevToastsRoute
  '/dev/tooltips': typeof DevTooltipsRoute
  '/discover/$': typeof DiscoverSplatRoute
  '/electron/browser-opened': typeof ElectronBrowserOpenedRoute
  '/electron/select': typeof ElectronSelectRouteWithChildren
  '/home/$page': typeof HomePageRoute
  '/partner-portal/$accountId': typeof PartnerPortalAccountIdRouteWithChildren
  '/search/$': typeof SearchSplatRoute
  '/setup-guide/business-description': typeof SetupGuideBusinessDescriptionRoute
  '/setup-guide/choose-plan': typeof SetupGuideChoosePlanRoute
  '/setup-guide/create-playlist': typeof SetupGuideCreatePlaylistRoute
  '/setup-guide/explicit-setting': typeof SetupGuideExplicitSettingRoute
  '/setup-guide/pair': typeof SetupGuidePairRouteWithChildren
  '/setup-guide/qr-app-login': typeof SetupGuideQrAppLoginRoute
  '/setup-guide/see-options': typeof SetupGuideSeeOptionsRoute
  '/setup-guide/spotify-import': typeof SetupGuideSpotifyImportRoute
  '/setup-guide/user-invite': typeof SetupGuideUserInviteRoute
  '/signup/$': typeof SignupSplatRoute
  '/user/change-email': typeof UserChangeEmailRoute
  '/user/change-password': typeof UserChangePasswordRoute
  '/user/edit': typeof UserEditRoute
  '/addons': typeof AddonsIndexRoute
  '/create/': typeof CreateIndexRoute
  '/dev/': typeof DevIndexRoute
  '/discover/': typeof DiscoverIndexRoute
  '/home/': typeof HomeIndexRoute
  '/partner-portal/': typeof PartnerPortalIndexRoute
  '/accounts/$accountId/checkout': typeof AccountsAccountIdCheckoutRouteRouteWithChildren
  '/accounts/$accountId/messaging': typeof AccountsAccountIdMessagingRouteRouteWithChildren
  '/accounts/$accountId/onboarding': typeof AccountsAccountIdOnboardingRouteRouteWithChildren
  '/accounts/$accountId/settings': typeof AccountsAccountIdSettingsRouteRouteWithChildren
  '/accounts/$accountId/your-music': typeof AccountsAccountIdYourMusicRouteRouteWithChildren
  '/accounts/$accountId/zones': typeof AccountsAccountIdZonesRouteRouteWithChildren
  '/invitation/$token': typeof loggedOutInvitationTokenRoute
  '/reset-password/$token': typeof loggedOutResetPasswordTokenRoute
  '/verify-email/$token': typeof loggedOutVerifyEmailTokenRoute
  '/invoice/$uuid': typeof miscInvoiceUuidRoute
  '/legal-certificate/print': typeof miscLegalCertificatePrintRoute
  '/nps-survey/$variant': typeof miscNpsSurveyVariantRoute
  '/referral/$code': typeof miscReferralCodeRoute
  '/sso/$': typeof miscSsoSplatRoute
  '/addons/activated/$addon': typeof AddonsActivatedAddonRoute
  '/addons/promo/curation': typeof AddonsPromoCurationRoute
  '/addons/promo/hifi': typeof AddonsPromoHifiRoute
  '/addons/promo/messaging': typeof AddonsPromoMessagingRoute
  '/addons/promo/sso': typeof AddonsPromoSsoRoute
  '/create/station/from-playlist': typeof CreateStationFromPlaylistRoute
  '/create/station/from-tags': typeof CreateStationFromTagsRoute
  '/discover/album/$releaseId': typeof DiscoverAlbumReleaseIdRoute
  '/discover/music/$musicId': typeof DiscoverMusicMusicIdRouteWithChildren
  '/discover/schedule/$id': typeof DiscoverScheduleIdRouteWithChildren
  '/electron/login/email': typeof ElectronLoginEmailRoute
  '/electron/login/pairing-code': typeof ElectronLoginPairingCodeRoute
  '/electron/select/$accountId': typeof ElectronSelectAccountIdRouteWithChildren
  '/partner-portal/$accountId/create': typeof PartnerPortalAccountIdCreateRoute
  '/setup-guide/pair/$action': typeof SetupGuidePairActionRoute
  '/invitation': typeof loggedOutInvitationIndexRoute
  '/login': typeof loggedOutLoginIndexRoute
  '/legal-certificate': typeof miscLegalCertificateIndexRoute
  '/nps-survey/': typeof miscNpsSurveyIndexRoute
  '/accounts/$accountId/': typeof AccountsAccountIdIndexRoute
  '/electron/login': typeof ElectronLoginIndexRoute
  '/setup-guide/pair/': typeof SetupGuidePairIndexRoute
  '/accounts/$accountId/settings/billing': typeof AccountsAccountIdSettingsBillingRouteRouteWithChildren
  '/accounts/$accountId/settings/subscriptions': typeof AccountsAccountIdSettingsSubscriptionsRouteRouteWithChildren
  '/login/saml/$orgSlug': typeof loggedOutLoginSamlOrgSlugRoute
  '/exchangerates/$date/$currency': typeof miscExchangeratesDateCurrencyRoute
  '/invoice/$accountId/$ref': typeof miscInvoiceAccountIdRefRoute
  '/connect/$vendor/$pairingCode': typeof pairingGuideConnectVendorPairingCodeRoute
  '/connect/$vendor/pair': typeof pairingGuideConnectVendorPairRoute
  '/accounts/$accountId/change-plan/$plan': typeof AccountsAccountIdChangePlanPlanRoute
  '/accounts/$accountId/checkout/$': typeof AccountsAccountIdCheckoutSplatRoute
  '/accounts/$accountId/locations/$': typeof AccountsAccountIdLocationsSplatRoute
  '/accounts/$accountId/messaging/messages': typeof AccountsAccountIdMessagingMessagesRouteWithChildren
  '/accounts/$accountId/onboarding/$step': typeof AccountsAccountIdOnboardingStepRoute
  '/accounts/$accountId/settings/overview': typeof AccountsAccountIdSettingsOverviewRouteWithChildren
  '/accounts/$accountId/settings/referrals': typeof AccountsAccountIdSettingsReferralsRoute
  '/accounts/$accountId/settings/saml': typeof AccountsAccountIdSettingsSamlRoute
  '/accounts/$accountId/settings/users': typeof AccountsAccountIdSettingsUsersRouteWithChildren
  '/accounts/$accountId/settings/zone-presets': typeof AccountsAccountIdSettingsZonePresetsRoute
  '/accounts/$accountId/voucher/$code': typeof AccountsAccountIdVoucherCodeRoute
  '/accounts/$accountId/your-music/$musicId': typeof AccountsAccountIdYourMusicMusicIdRoute
  '/accounts/$accountId/zones/$zoneId': typeof AccountsAccountIdZonesZoneIdRouteWithChildren
  '/discover/categories/$type/$categoryId': typeof DiscoverCategoriesTypeCategoryIdRoute
  '/discover/music/$musicId/edit': typeof DiscoverMusicMusicIdEditRoute
  '/discover/schedule/$id/edit': typeof DiscoverScheduleIdEditRoute
  '/electron/select/$accountId/$locationId': typeof ElectronSelectAccountIdLocationIdRoute
  '/login/saml': typeof loggedOutLoginSamlIndexRoute
  '/connect/$vendor': typeof pairingGuideConnectVendorIndexRoute
  '/accounts/$accountId/change-plan': typeof AccountsAccountIdChangePlanIndexRoute
  '/accounts/$accountId/choose-plan': typeof AccountsAccountIdChoosePlanIndexRoute
  '/accounts/$accountId/locations': typeof AccountsAccountIdLocationsIndexRoute
  '/accounts/$accountId/messaging/': typeof AccountsAccountIdMessagingIndexRoute
  '/accounts/$accountId/settings/': typeof AccountsAccountIdSettingsIndexRoute
  '/accounts/$accountId/upgrade-plan': typeof AccountsAccountIdUpgradePlanIndexRoute
  '/accounts/$accountId/your-music/': typeof AccountsAccountIdYourMusicIndexRoute
  '/discover/music/$musicId/': typeof DiscoverMusicMusicIdIndexRoute
  '/connect/$vendor/$pairingCode/pair': typeof pairingGuideConnectVendorPairingCodePairRouteWithChildren
  '/sonos/connect/v2/$householdId': typeof pairingGuideSonosConnectV2HouseholdIdRoute
  '/sonos/pair/v2/$householdId': typeof pairingGuideSonosPairV2HouseholdIdRoute
  '/accounts/$accountId/addons/$addonId/activate': typeof AccountsAccountIdAddonsAddonIdActivateRoute
  '/accounts/$accountId/change-plan/confirmed/$plan': typeof AccountsAccountIdChangePlanConfirmedPlanRoute
  '/accounts/$accountId/messaging/campaigns/$campaignId': typeof AccountsAccountIdMessagingCampaignsCampaignIdRoute
  '/accounts/$accountId/messaging/campaigns/create': typeof AccountsAccountIdMessagingCampaignsCreateRoute
  '/accounts/$accountId/messaging/messages/$messageId': typeof AccountsAccountIdMessagingMessagesMessageIdRoute
  '/accounts/$accountId/messaging/messages/create': typeof AccountsAccountIdMessagingMessagesCreateRoute
  '/accounts/$accountId/settings/billing/update-billing-email': typeof AccountsAccountIdSettingsBillingUpdateBillingEmailRoute
  '/accounts/$accountId/settings/overview/company-details': typeof AccountsAccountIdSettingsOverviewCompanyDetailsRoute
  '/accounts/$accountId/settings/subscriptions/streaming': typeof AccountsAccountIdSettingsSubscriptionsStreamingRouteWithChildren
  '/accounts/$accountId/settings/users/invite': typeof AccountsAccountIdSettingsUsersInviteRoute
  '/accounts/$accountId/your-music/playlists/$musicId': typeof AccountsAccountIdYourMusicPlaylistsMusicIdRoute
  '/accounts/$accountId/your-music/schedules/create': typeof AccountsAccountIdYourMusicSchedulesCreateRoute
  '/accounts/$accountId/your-music/schedules/edit': typeof AccountsAccountIdYourMusicSchedulesEditRouteWithChildren
  '/accounts/$accountId/your-music/schedules/$scheduleId': typeof AccountsAccountIdYourMusicSchedulesScheduleIdRoute
  '/accounts/$accountId/zones/$zoneId/control': typeof AccountsAccountIdZonesZoneIdControlRoute
  '/accounts/$accountId/zones/$zoneId/player': typeof AccountsAccountIdZonesZoneIdPlayerRouteWithChildren
  '/accounts/$accountId/zones/$zoneId/remote': typeof AccountsAccountIdZonesZoneIdRemoteRoute
  '/accounts/$accountId/zones/$zoneId/settings': typeof AccountsAccountIdZonesZoneIdSettingsRoute
  '/accounts/$accountId/zones/$zoneId/subscription': typeof AccountsAccountIdZonesZoneIdSubscriptionRouteWithChildren
  '/accounts/$accountId/zones/create/$locationId': typeof AccountsAccountIdZonesCreateLocationIdRoute
  '/accounts/$accountId/messaging/campaigns': typeof AccountsAccountIdMessagingCampaignsIndexRoute
  '/accounts/$accountId/settings/subscriptions/': typeof AccountsAccountIdSettingsSubscriptionsIndexRoute
  '/accounts/$accountId/your-music/playlists': typeof AccountsAccountIdYourMusicPlaylistsIndexRoute
  '/accounts/$accountId/your-music/schedules': typeof AccountsAccountIdYourMusicSchedulesIndexRoute
  '/accounts/$accountId/zones/$zoneId/': typeof AccountsAccountIdZonesZoneIdIndexRoute
  '/accounts/$accountId/zones/create': typeof AccountsAccountIdZonesCreateIndexRoute
  '/connect/$vendor/$pairingCode/pair/$zoneId': typeof pairingGuideConnectVendorPairingCodePairZoneIdRoute
  '/accounts/$accountId/settings/billing/update-payment/$type': typeof AccountsAccountIdSettingsBillingUpdatePaymentTypeRoute
  '/accounts/$accountId/settings/subscriptions/addons/$subscriptionId': typeof AccountsAccountIdSettingsSubscriptionsAddonsSubscriptionIdRoute
  '/accounts/$accountId/settings/subscriptions/streaming/$zoneId': typeof AccountsAccountIdSettingsSubscriptionsStreamingZoneIdRouteWithChildren
  '/accounts/$accountId/your-music/schedules/$scheduleId/copy': typeof AccountsAccountIdYourMusicSchedulesScheduleIdCopyRoute
  '/accounts/$accountId/your-music/schedules/$scheduleId/edit': typeof AccountsAccountIdYourMusicSchedulesScheduleIdEditRoute
  '/accounts/$accountId/your-music/schedules/edit/$scheduleId': typeof AccountsAccountIdYourMusicSchedulesEditScheduleIdRoute
  '/accounts/$accountId/zones/$zoneId/player/$action': typeof AccountsAccountIdZonesZoneIdPlayerActionRoute
  '/accounts/$accountId/zones/$zoneId/subscription/$action': typeof AccountsAccountIdZonesZoneIdSubscriptionActionRoute
  '/accounts/$accountId/zones/$zoneId/player/blocked-tracks': typeof AccountsAccountIdZonesZoneIdPlayerBlockedTracksRoute
  '/accounts/$accountId/zones/$zoneId/player/history': typeof AccountsAccountIdZonesZoneIdPlayerHistoryRoute
  '/accounts/$accountId/settings/billing/invoice-group/edit/$groupId': typeof AccountsAccountIdSettingsBillingInvoiceGroupEditGroupIdRoute
  '/accounts/$accountId/settings/subscriptions/streaming/$zoneId/$action': typeof AccountsAccountIdSettingsSubscriptionsStreamingZoneIdActionRoute
  '/accounts/$accountId/zones/$zoneId/player/pair/$player': typeof AccountsAccountIdZonesZoneIdPlayerPairPlayerRoute
}

export interface FileRoutesByTo {
  '/': typeof loggedOutRouteRouteWithChildren
  '/electron': typeof ElectronRouteRouteWithChildren
  '/search': typeof SearchRouteRouteWithChildren
  '/setup-guide': typeof SetupGuideRouteRouteWithChildren
  '/user': typeof UserRouteRouteWithChildren
  '/account': typeof AccountRoute
  '/download': typeof DownloadRoute
  '/signup': typeof SignupRouteWithChildren
  '/spotify-callback': typeof SpotifyCallbackRoute
  '/forgot-password': typeof loggedOutForgotPasswordRoute
  '/logout': typeof miscLogoutRoute
  '/sso': typeof miscSsoRouteWithChildren
  '/create/ai-playlist': typeof CreateAiPlaylistRoute
  '/create/ai-station': typeof CreateAiStationRoute
  '/create/import-spotify-playlist': typeof CreateImportSpotifyPlaylistRoute
  '/create/playlist': typeof CreatePlaylistRoute
  '/create/schedule': typeof CreateScheduleRoute
  '/create/synced-playlist': typeof CreateSyncedPlaylistRoute
  '/dev/drag-drop': typeof DevDragDropRoute
  '/dev/flags': typeof DevFlagsRoute
  '/dev/playback': typeof DevPlaybackRoute
  '/dev/price-breakdown': typeof DevPriceBreakdownRoute
  '/dev/sandbox': typeof DevSandboxRoute
  '/dev/sentry': typeof DevSentryRoute
  '/dev/surveys': typeof DevSurveysRoute
  '/dev/toasts': typeof DevToastsRoute
  '/dev/tooltips': typeof DevTooltipsRoute
  '/discover/$': typeof DiscoverSplatRoute
  '/electron/browser-opened': typeof ElectronBrowserOpenedRoute
  '/electron/select': typeof ElectronSelectRouteWithChildren
  '/home/$page': typeof HomePageRoute
  '/partner-portal/$accountId': typeof PartnerPortalAccountIdRouteWithChildren
  '/search/$': typeof SearchSplatRoute
  '/setup-guide/business-description': typeof SetupGuideBusinessDescriptionRoute
  '/setup-guide/choose-plan': typeof SetupGuideChoosePlanRoute
  '/setup-guide/create-playlist': typeof SetupGuideCreatePlaylistRoute
  '/setup-guide/explicit-setting': typeof SetupGuideExplicitSettingRoute
  '/setup-guide/qr-app-login': typeof SetupGuideQrAppLoginRoute
  '/setup-guide/see-options': typeof SetupGuideSeeOptionsRoute
  '/setup-guide/spotify-import': typeof SetupGuideSpotifyImportRoute
  '/setup-guide/user-invite': typeof SetupGuideUserInviteRoute
  '/signup/$': typeof SignupSplatRoute
  '/user/change-email': typeof UserChangeEmailRoute
  '/user/change-password': typeof UserChangePasswordRoute
  '/user/edit': typeof UserEditRoute
  '/addons': typeof AddonsIndexRoute
  '/create': typeof CreateIndexRoute
  '/dev': typeof DevIndexRoute
  '/discover': typeof DiscoverIndexRoute
  '/home': typeof HomeIndexRoute
  '/partner-portal': typeof PartnerPortalIndexRoute
  '/accounts/$accountId/checkout': typeof AccountsAccountIdCheckoutRouteRouteWithChildren
  '/accounts/$accountId/onboarding': typeof AccountsAccountIdOnboardingRouteRouteWithChildren
  '/accounts/$accountId/zones': typeof AccountsAccountIdZonesRouteRouteWithChildren
  '/invitation/$token': typeof loggedOutInvitationTokenRoute
  '/reset-password/$token': typeof loggedOutResetPasswordTokenRoute
  '/verify-email/$token': typeof loggedOutVerifyEmailTokenRoute
  '/invoice/$uuid': typeof miscInvoiceUuidRoute
  '/legal-certificate/print': typeof miscLegalCertificatePrintRoute
  '/nps-survey/$variant': typeof miscNpsSurveyVariantRoute
  '/referral/$code': typeof miscReferralCodeRoute
  '/sso/$': typeof miscSsoSplatRoute
  '/addons/activated/$addon': typeof AddonsActivatedAddonRoute
  '/addons/promo/curation': typeof AddonsPromoCurationRoute
  '/addons/promo/hifi': typeof AddonsPromoHifiRoute
  '/addons/promo/messaging': typeof AddonsPromoMessagingRoute
  '/addons/promo/sso': typeof AddonsPromoSsoRoute
  '/create/station/from-playlist': typeof CreateStationFromPlaylistRoute
  '/create/station/from-tags': typeof CreateStationFromTagsRoute
  '/discover/album/$releaseId': typeof DiscoverAlbumReleaseIdRoute
  '/discover/schedule/$id': typeof DiscoverScheduleIdRouteWithChildren
  '/electron/login/email': typeof ElectronLoginEmailRoute
  '/electron/login/pairing-code': typeof ElectronLoginPairingCodeRoute
  '/electron/select/$accountId': typeof ElectronSelectAccountIdRouteWithChildren
  '/partner-portal/$accountId/create': typeof PartnerPortalAccountIdCreateRoute
  '/setup-guide/pair/$action': typeof SetupGuidePairActionRoute
  '/invitation': typeof loggedOutInvitationIndexRoute
  '/login': typeof loggedOutLoginIndexRoute
  '/legal-certificate': typeof miscLegalCertificateIndexRoute
  '/nps-survey': typeof miscNpsSurveyIndexRoute
  '/accounts/$accountId': typeof AccountsAccountIdIndexRoute
  '/electron/login': typeof ElectronLoginIndexRoute
  '/setup-guide/pair': typeof SetupGuidePairIndexRoute
  '/accounts/$accountId/settings/billing': typeof AccountsAccountIdSettingsBillingRouteRouteWithChildren
  '/login/saml/$orgSlug': typeof loggedOutLoginSamlOrgSlugRoute
  '/exchangerates/$date/$currency': typeof miscExchangeratesDateCurrencyRoute
  '/invoice/$accountId/$ref': typeof miscInvoiceAccountIdRefRoute
  '/connect/$vendor/$pairingCode': typeof pairingGuideConnectVendorPairingCodeRoute
  '/connect/$vendor/pair': typeof pairingGuideConnectVendorPairRoute
  '/accounts/$accountId/change-plan/$plan': typeof AccountsAccountIdChangePlanPlanRoute
  '/accounts/$accountId/checkout/$': typeof AccountsAccountIdCheckoutSplatRoute
  '/accounts/$accountId/locations/$': typeof AccountsAccountIdLocationsSplatRoute
  '/accounts/$accountId/messaging/messages': typeof AccountsAccountIdMessagingMessagesRouteWithChildren
  '/accounts/$accountId/onboarding/$step': typeof AccountsAccountIdOnboardingStepRoute
  '/accounts/$accountId/settings/overview': typeof AccountsAccountIdSettingsOverviewRouteWithChildren
  '/accounts/$accountId/settings/referrals': typeof AccountsAccountIdSettingsReferralsRoute
  '/accounts/$accountId/settings/saml': typeof AccountsAccountIdSettingsSamlRoute
  '/accounts/$accountId/settings/users': typeof AccountsAccountIdSettingsUsersRouteWithChildren
  '/accounts/$accountId/settings/zone-presets': typeof AccountsAccountIdSettingsZonePresetsRoute
  '/accounts/$accountId/voucher/$code': typeof AccountsAccountIdVoucherCodeRoute
  '/accounts/$accountId/your-music/$musicId': typeof AccountsAccountIdYourMusicMusicIdRoute
  '/discover/categories/$type/$categoryId': typeof DiscoverCategoriesTypeCategoryIdRoute
  '/discover/music/$musicId/edit': typeof DiscoverMusicMusicIdEditRoute
  '/discover/schedule/$id/edit': typeof DiscoverScheduleIdEditRoute
  '/electron/select/$accountId/$locationId': typeof ElectronSelectAccountIdLocationIdRoute
  '/login/saml': typeof loggedOutLoginSamlIndexRoute
  '/connect/$vendor': typeof pairingGuideConnectVendorIndexRoute
  '/accounts/$accountId/change-plan': typeof AccountsAccountIdChangePlanIndexRoute
  '/accounts/$accountId/choose-plan': typeof AccountsAccountIdChoosePlanIndexRoute
  '/accounts/$accountId/locations': typeof AccountsAccountIdLocationsIndexRoute
  '/accounts/$accountId/messaging': typeof AccountsAccountIdMessagingIndexRoute
  '/accounts/$accountId/settings': typeof AccountsAccountIdSettingsIndexRoute
  '/accounts/$accountId/upgrade-plan': typeof AccountsAccountIdUpgradePlanIndexRoute
  '/accounts/$accountId/your-music': typeof AccountsAccountIdYourMusicIndexRoute
  '/discover/music/$musicId': typeof DiscoverMusicMusicIdIndexRoute
  '/connect/$vendor/$pairingCode/pair': typeof pairingGuideConnectVendorPairingCodePairRouteWithChildren
  '/sonos/connect/v2/$householdId': typeof pairingGuideSonosConnectV2HouseholdIdRoute
  '/sonos/pair/v2/$householdId': typeof pairingGuideSonosPairV2HouseholdIdRoute
  '/accounts/$accountId/addons/$addonId/activate': typeof AccountsAccountIdAddonsAddonIdActivateRoute
  '/accounts/$accountId/change-plan/confirmed/$plan': typeof AccountsAccountIdChangePlanConfirmedPlanRoute
  '/accounts/$accountId/messaging/campaigns/$campaignId': typeof AccountsAccountIdMessagingCampaignsCampaignIdRoute
  '/accounts/$accountId/messaging/campaigns/create': typeof AccountsAccountIdMessagingCampaignsCreateRoute
  '/accounts/$accountId/messaging/messages/$messageId': typeof AccountsAccountIdMessagingMessagesMessageIdRoute
  '/accounts/$accountId/messaging/messages/create': typeof AccountsAccountIdMessagingMessagesCreateRoute
  '/accounts/$accountId/settings/billing/update-billing-email': typeof AccountsAccountIdSettingsBillingUpdateBillingEmailRoute
  '/accounts/$accountId/settings/overview/company-details': typeof AccountsAccountIdSettingsOverviewCompanyDetailsRoute
  '/accounts/$accountId/settings/subscriptions/streaming': typeof AccountsAccountIdSettingsSubscriptionsStreamingRouteWithChildren
  '/accounts/$accountId/settings/users/invite': typeof AccountsAccountIdSettingsUsersInviteRoute
  '/accounts/$accountId/your-music/playlists/$musicId': typeof AccountsAccountIdYourMusicPlaylistsMusicIdRoute
  '/accounts/$accountId/your-music/schedules/create': typeof AccountsAccountIdYourMusicSchedulesCreateRoute
  '/accounts/$accountId/your-music/schedules/edit': typeof AccountsAccountIdYourMusicSchedulesEditRouteWithChildren
  '/accounts/$accountId/your-music/schedules/$scheduleId': typeof AccountsAccountIdYourMusicSchedulesScheduleIdRoute
  '/accounts/$accountId/zones/$zoneId/control': typeof AccountsAccountIdZonesZoneIdControlRoute
  '/accounts/$accountId/zones/$zoneId/player': typeof AccountsAccountIdZonesZoneIdPlayerRouteWithChildren
  '/accounts/$accountId/zones/$zoneId/remote': typeof AccountsAccountIdZonesZoneIdRemoteRoute
  '/accounts/$accountId/zones/$zoneId/settings': typeof AccountsAccountIdZonesZoneIdSettingsRoute
  '/accounts/$accountId/zones/$zoneId/subscription': typeof AccountsAccountIdZonesZoneIdSubscriptionRouteWithChildren
  '/accounts/$accountId/zones/create/$locationId': typeof AccountsAccountIdZonesCreateLocationIdRoute
  '/accounts/$accountId/messaging/campaigns': typeof AccountsAccountIdMessagingCampaignsIndexRoute
  '/accounts/$accountId/settings/subscriptions': typeof AccountsAccountIdSettingsSubscriptionsIndexRoute
  '/accounts/$accountId/your-music/playlists': typeof AccountsAccountIdYourMusicPlaylistsIndexRoute
  '/accounts/$accountId/your-music/schedules': typeof AccountsAccountIdYourMusicSchedulesIndexRoute
  '/accounts/$accountId/zones/$zoneId': typeof AccountsAccountIdZonesZoneIdIndexRoute
  '/accounts/$accountId/zones/create': typeof AccountsAccountIdZonesCreateIndexRoute
  '/connect/$vendor/$pairingCode/pair/$zoneId': typeof pairingGuideConnectVendorPairingCodePairZoneIdRoute
  '/accounts/$accountId/settings/billing/update-payment/$type': typeof AccountsAccountIdSettingsBillingUpdatePaymentTypeRoute
  '/accounts/$accountId/settings/subscriptions/addons/$subscriptionId': typeof AccountsAccountIdSettingsSubscriptionsAddonsSubscriptionIdRoute
  '/accounts/$accountId/settings/subscriptions/streaming/$zoneId': typeof AccountsAccountIdSettingsSubscriptionsStreamingZoneIdRouteWithChildren
  '/accounts/$accountId/your-music/schedules/$scheduleId/copy': typeof AccountsAccountIdYourMusicSchedulesScheduleIdCopyRoute
  '/accounts/$accountId/your-music/schedules/$scheduleId/edit': typeof AccountsAccountIdYourMusicSchedulesScheduleIdEditRoute
  '/accounts/$accountId/your-music/schedules/edit/$scheduleId': typeof AccountsAccountIdYourMusicSchedulesEditScheduleIdRoute
  '/accounts/$accountId/zones/$zoneId/player/$action': typeof AccountsAccountIdZonesZoneIdPlayerActionRoute
  '/accounts/$accountId/zones/$zoneId/subscription/$action': typeof AccountsAccountIdZonesZoneIdSubscriptionActionRoute
  '/accounts/$accountId/zones/$zoneId/player/blocked-tracks': typeof AccountsAccountIdZonesZoneIdPlayerBlockedTracksRoute
  '/accounts/$accountId/zones/$zoneId/player/history': typeof AccountsAccountIdZonesZoneIdPlayerHistoryRoute
  '/accounts/$accountId/settings/billing/invoice-group/edit/$groupId': typeof AccountsAccountIdSettingsBillingInvoiceGroupEditGroupIdRoute
  '/accounts/$accountId/settings/subscriptions/streaming/$zoneId/$action': typeof AccountsAccountIdSettingsSubscriptionsStreamingZoneIdActionRoute
  '/accounts/$accountId/zones/$zoneId/player/pair/$player': typeof AccountsAccountIdZonesZoneIdPlayerPairPlayerRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRouteWithChildren
  '/(logged-out)': typeof loggedOutRouteRouteWithChildren
  '/create': typeof CreateRouteRouteWithChildren
  '/dev': typeof DevRouteRouteWithChildren
  '/discover': typeof DiscoverRouteRouteWithChildren
  '/electron': typeof ElectronRouteRouteWithChildren
  '/home': typeof HomeRouteRouteWithChildren
  '/partner-portal': typeof PartnerPortalRouteRouteWithChildren
  '/search': typeof SearchRouteRouteWithChildren
  '/setup-guide': typeof SetupGuideRouteRouteWithChildren
  '/user': typeof UserRouteRouteWithChildren
  '/account': typeof AccountRoute
  '/download': typeof DownloadRoute
  '/signup': typeof SignupRouteWithChildren
  '/spotify-callback': typeof SpotifyCallbackRoute
  '/accounts/$accountId': typeof AccountsAccountIdRouteRouteWithChildren
  '/(logged-out)/forgot-password': typeof loggedOutForgotPasswordRoute
  '/(misc)/logout': typeof miscLogoutRoute
  '/(misc)/nps-survey': typeof miscNpsSurveyRouteWithChildren
  '/(misc)/sso': typeof miscSsoRouteWithChildren
  '/create/ai-playlist': typeof CreateAiPlaylistRoute
  '/create/ai-station': typeof CreateAiStationRoute
  '/create/import-spotify-playlist': typeof CreateImportSpotifyPlaylistRoute
  '/create/playlist': typeof CreatePlaylistRoute
  '/create/schedule': typeof CreateScheduleRoute
  '/create/synced-playlist': typeof CreateSyncedPlaylistRoute
  '/dev/drag-drop': typeof DevDragDropRoute
  '/dev/flags': typeof DevFlagsRoute
  '/dev/playback': typeof DevPlaybackRoute
  '/dev/price-breakdown': typeof DevPriceBreakdownRoute
  '/dev/sandbox': typeof DevSandboxRoute
  '/dev/sentry': typeof DevSentryRoute
  '/dev/surveys': typeof DevSurveysRoute
  '/dev/toasts': typeof DevToastsRoute
  '/dev/tooltips': typeof DevTooltipsRoute
  '/discover/$': typeof DiscoverSplatRoute
  '/electron/browser-opened': typeof ElectronBrowserOpenedRoute
  '/electron/select': typeof ElectronSelectRouteWithChildren
  '/home/$page': typeof HomePageRoute
  '/partner-portal/$accountId': typeof PartnerPortalAccountIdRouteWithChildren
  '/search/$': typeof SearchSplatRoute
  '/setup-guide/business-description': typeof SetupGuideBusinessDescriptionRoute
  '/setup-guide/choose-plan': typeof SetupGuideChoosePlanRoute
  '/setup-guide/create-playlist': typeof SetupGuideCreatePlaylistRoute
  '/setup-guide/explicit-setting': typeof SetupGuideExplicitSettingRoute
  '/setup-guide/pair': typeof SetupGuidePairRouteWithChildren
  '/setup-guide/qr-app-login': typeof SetupGuideQrAppLoginRoute
  '/setup-guide/see-options': typeof SetupGuideSeeOptionsRoute
  '/setup-guide/spotify-import': typeof SetupGuideSpotifyImportRoute
  '/setup-guide/user-invite': typeof SetupGuideUserInviteRoute
  '/signup/$': typeof SignupSplatRoute
  '/user/change-email': typeof UserChangeEmailRoute
  '/user/change-password': typeof UserChangePasswordRoute
  '/user/edit': typeof UserEditRoute
  '/addons/': typeof AddonsIndexRoute
  '/create/': typeof CreateIndexRoute
  '/dev/': typeof DevIndexRoute
  '/discover/': typeof DiscoverIndexRoute
  '/home/': typeof HomeIndexRoute
  '/partner-portal/': typeof PartnerPortalIndexRoute
  '/accounts/$accountId/checkout': typeof AccountsAccountIdCheckoutRouteRouteWithChildren
  '/accounts/$accountId/messaging': typeof AccountsAccountIdMessagingRouteRouteWithChildren
  '/accounts/$accountId/onboarding': typeof AccountsAccountIdOnboardingRouteRouteWithChildren
  '/accounts/$accountId/settings': typeof AccountsAccountIdSettingsRouteRouteWithChildren
  '/accounts/$accountId/your-music': typeof AccountsAccountIdYourMusicRouteRouteWithChildren
  '/accounts/$accountId/zones': typeof AccountsAccountIdZonesRouteRouteWithChildren
  '/(logged-out)/invitation/$token': typeof loggedOutInvitationTokenRoute
  '/(logged-out)/reset-password/$token': typeof loggedOutResetPasswordTokenRoute
  '/(logged-out)/verify-email/$token': typeof loggedOutVerifyEmailTokenRoute
  '/(misc)/invoice/$uuid': typeof miscInvoiceUuidRoute
  '/(misc)/legal-certificate/print': typeof miscLegalCertificatePrintRoute
  '/(misc)/nps-survey/$variant': typeof miscNpsSurveyVariantRoute
  '/(misc)/referral/$code': typeof miscReferralCodeRoute
  '/(misc)/sso/$': typeof miscSsoSplatRoute
  '/addons/activated/$addon': typeof AddonsActivatedAddonRoute
  '/addons/promo/curation': typeof AddonsPromoCurationRoute
  '/addons/promo/hifi': typeof AddonsPromoHifiRoute
  '/addons/promo/messaging': typeof AddonsPromoMessagingRoute
  '/addons/promo/sso': typeof AddonsPromoSsoRoute
  '/create/station/from-playlist': typeof CreateStationFromPlaylistRoute
  '/create/station/from-tags': typeof CreateStationFromTagsRoute
  '/discover/album/$releaseId': typeof DiscoverAlbumReleaseIdRoute
  '/discover/music/$musicId': typeof DiscoverMusicMusicIdRouteWithChildren
  '/discover/schedule/$id': typeof DiscoverScheduleIdRouteWithChildren
  '/electron/login/email': typeof ElectronLoginEmailRoute
  '/electron/login/pairing-code': typeof ElectronLoginPairingCodeRoute
  '/electron/select/$accountId': typeof ElectronSelectAccountIdRouteWithChildren
  '/partner-portal/$accountId/create': typeof PartnerPortalAccountIdCreateRoute
  '/setup-guide/pair/$action': typeof SetupGuidePairActionRoute
  '/(logged-out)/invitation/': typeof loggedOutInvitationIndexRoute
  '/(logged-out)/login/': typeof loggedOutLoginIndexRoute
  '/(misc)/legal-certificate/': typeof miscLegalCertificateIndexRoute
  '/(misc)/nps-survey/': typeof miscNpsSurveyIndexRoute
  '/accounts/$accountId/': typeof AccountsAccountIdIndexRoute
  '/electron/login/': typeof ElectronLoginIndexRoute
  '/setup-guide/pair/': typeof SetupGuidePairIndexRoute
  '/accounts/$accountId/settings/billing': typeof AccountsAccountIdSettingsBillingRouteRouteWithChildren
  '/accounts/$accountId/settings/subscriptions': typeof AccountsAccountIdSettingsSubscriptionsRouteRouteWithChildren
  '/(logged-out)/login/saml/$orgSlug': typeof loggedOutLoginSamlOrgSlugRoute
  '/(misc)/exchangerates/$date/$currency': typeof miscExchangeratesDateCurrencyRoute
  '/(misc)/invoice/$accountId/$ref': typeof miscInvoiceAccountIdRefRoute
  '/(pairing-guide)/connect/$vendor/$pairingCode_': typeof pairingGuideConnectVendorPairingCodeRoute
  '/(pairing-guide)/connect/$vendor/pair': typeof pairingGuideConnectVendorPairRoute
  '/accounts/$accountId/change-plan/$plan': typeof AccountsAccountIdChangePlanPlanRoute
  '/accounts/$accountId/checkout/$': typeof AccountsAccountIdCheckoutSplatRoute
  '/accounts/$accountId/locations/$': typeof AccountsAccountIdLocationsSplatRoute
  '/accounts/$accountId/messaging/messages': typeof AccountsAccountIdMessagingMessagesRouteWithChildren
  '/accounts/$accountId/onboarding/$step': typeof AccountsAccountIdOnboardingStepRoute
  '/accounts/$accountId/settings/overview': typeof AccountsAccountIdSettingsOverviewRouteWithChildren
  '/accounts/$accountId/settings/referrals': typeof AccountsAccountIdSettingsReferralsRoute
  '/accounts/$accountId/settings/saml': typeof AccountsAccountIdSettingsSamlRoute
  '/accounts/$accountId/settings/users': typeof AccountsAccountIdSettingsUsersRouteWithChildren
  '/accounts/$accountId/settings/zone-presets': typeof AccountsAccountIdSettingsZonePresetsRoute
  '/accounts/$accountId/voucher/$code': typeof AccountsAccountIdVoucherCodeRoute
  '/accounts/$accountId/your-music_/$musicId': typeof AccountsAccountIdYourMusicMusicIdRoute
  '/accounts/$accountId/zones/$zoneId': typeof AccountsAccountIdZonesZoneIdRouteWithChildren
  '/discover/categories/$type/$categoryId': typeof DiscoverCategoriesTypeCategoryIdRoute
  '/discover/music/$musicId/edit': typeof DiscoverMusicMusicIdEditRoute
  '/discover/schedule/$id/edit': typeof DiscoverScheduleIdEditRoute
  '/electron/select/$accountId/$locationId': typeof ElectronSelectAccountIdLocationIdRoute
  '/(logged-out)/login/saml/': typeof loggedOutLoginSamlIndexRoute
  '/(pairing-guide)/connect/$vendor/': typeof pairingGuideConnectVendorIndexRoute
  '/accounts/$accountId/change-plan/': typeof AccountsAccountIdChangePlanIndexRoute
  '/accounts/$accountId/choose-plan/': typeof AccountsAccountIdChoosePlanIndexRoute
  '/accounts/$accountId/locations/': typeof AccountsAccountIdLocationsIndexRoute
  '/accounts/$accountId/messaging/': typeof AccountsAccountIdMessagingIndexRoute
  '/accounts/$accountId/settings/': typeof AccountsAccountIdSettingsIndexRoute
  '/accounts/$accountId/upgrade-plan/': typeof AccountsAccountIdUpgradePlanIndexRoute
  '/accounts/$accountId/your-music/': typeof AccountsAccountIdYourMusicIndexRoute
  '/discover/music/$musicId/': typeof DiscoverMusicMusicIdIndexRoute
  '/(pairing-guide)/connect/$vendor/$pairingCode/pair': typeof pairingGuideConnectVendorPairingCodePairRouteWithChildren
  '/(pairing-guide)/sonos/connect/v2/$householdId': typeof pairingGuideSonosConnectV2HouseholdIdRoute
  '/(pairing-guide)/sonos/pair/v2/$householdId': typeof pairingGuideSonosPairV2HouseholdIdRoute
  '/accounts/$accountId/addons/$addonId/activate': typeof AccountsAccountIdAddonsAddonIdActivateRoute
  '/accounts/$accountId/change-plan/confirmed/$plan': typeof AccountsAccountIdChangePlanConfirmedPlanRoute
  '/accounts/$accountId/messaging/campaigns/$campaignId': typeof AccountsAccountIdMessagingCampaignsCampaignIdRoute
  '/accounts/$accountId/messaging/campaigns/create': typeof AccountsAccountIdMessagingCampaignsCreateRoute
  '/accounts/$accountId/messaging/messages/$messageId': typeof AccountsAccountIdMessagingMessagesMessageIdRoute
  '/accounts/$accountId/messaging/messages/create': typeof AccountsAccountIdMessagingMessagesCreateRoute
  '/accounts/$accountId/settings/billing/update-billing-email': typeof AccountsAccountIdSettingsBillingUpdateBillingEmailRoute
  '/accounts/$accountId/settings/overview/company-details': typeof AccountsAccountIdSettingsOverviewCompanyDetailsRoute
  '/accounts/$accountId/settings/subscriptions/streaming': typeof AccountsAccountIdSettingsSubscriptionsStreamingRouteWithChildren
  '/accounts/$accountId/settings/users/invite': typeof AccountsAccountIdSettingsUsersInviteRoute
  '/accounts/$accountId/your-music/playlists/$musicId': typeof AccountsAccountIdYourMusicPlaylistsMusicIdRoute
  '/accounts/$accountId/your-music/schedules/create': typeof AccountsAccountIdYourMusicSchedulesCreateRoute
  '/accounts/$accountId/your-music/schedules/edit': typeof AccountsAccountIdYourMusicSchedulesEditRouteWithChildren
  '/accounts/$accountId/your-music_/schedules/$scheduleId': typeof AccountsAccountIdYourMusicSchedulesScheduleIdRoute
  '/accounts/$accountId/zones/$zoneId/control': typeof AccountsAccountIdZonesZoneIdControlRoute
  '/accounts/$accountId/zones/$zoneId/player': typeof AccountsAccountIdZonesZoneIdPlayerRouteWithChildren
  '/accounts/$accountId/zones/$zoneId/remote': typeof AccountsAccountIdZonesZoneIdRemoteRoute
  '/accounts/$accountId/zones/$zoneId/settings': typeof AccountsAccountIdZonesZoneIdSettingsRoute
  '/accounts/$accountId/zones/$zoneId/subscription': typeof AccountsAccountIdZonesZoneIdSubscriptionRouteWithChildren
  '/accounts/$accountId/zones/create/$locationId': typeof AccountsAccountIdZonesCreateLocationIdRoute
  '/accounts/$accountId/messaging/campaigns/': typeof AccountsAccountIdMessagingCampaignsIndexRoute
  '/accounts/$accountId/settings/subscriptions/': typeof AccountsAccountIdSettingsSubscriptionsIndexRoute
  '/accounts/$accountId/your-music/playlists/': typeof AccountsAccountIdYourMusicPlaylistsIndexRoute
  '/accounts/$accountId/your-music/schedules/': typeof AccountsAccountIdYourMusicSchedulesIndexRoute
  '/accounts/$accountId/zones/$zoneId/': typeof AccountsAccountIdZonesZoneIdIndexRoute
  '/accounts/$accountId/zones/create/': typeof AccountsAccountIdZonesCreateIndexRoute
  '/(pairing-guide)/connect/$vendor/$pairingCode/pair/$zoneId': typeof pairingGuideConnectVendorPairingCodePairZoneIdRoute
  '/accounts/$accountId/settings/billing/update-payment/$type': typeof AccountsAccountIdSettingsBillingUpdatePaymentTypeRoute
  '/accounts/$accountId/settings/subscriptions/addons/$subscriptionId': typeof AccountsAccountIdSettingsSubscriptionsAddonsSubscriptionIdRoute
  '/accounts/$accountId/settings/subscriptions/streaming/$zoneId': typeof AccountsAccountIdSettingsSubscriptionsStreamingZoneIdRouteWithChildren
  '/accounts/$accountId/your-music/schedules/$scheduleId/copy': typeof AccountsAccountIdYourMusicSchedulesScheduleIdCopyRoute
  '/accounts/$accountId/your-music/schedules/$scheduleId/edit': typeof AccountsAccountIdYourMusicSchedulesScheduleIdEditRoute
  '/accounts/$accountId/your-music/schedules/edit/$scheduleId': typeof AccountsAccountIdYourMusicSchedulesEditScheduleIdRoute
  '/accounts/$accountId/zones/$zoneId/player/$action': typeof AccountsAccountIdZonesZoneIdPlayerActionRoute
  '/accounts/$accountId/zones/$zoneId/subscription/$action': typeof AccountsAccountIdZonesZoneIdSubscriptionActionRoute
  '/accounts/$accountId/zones/$zoneId_/player/blocked-tracks': typeof AccountsAccountIdZonesZoneIdPlayerBlockedTracksRoute
  '/accounts/$accountId/zones/$zoneId_/player/history': typeof AccountsAccountIdZonesZoneIdPlayerHistoryRoute
  '/accounts/$accountId/settings/billing/invoice-group/edit/$groupId': typeof AccountsAccountIdSettingsBillingInvoiceGroupEditGroupIdRoute
  '/accounts/$accountId/settings/subscriptions/streaming/$zoneId/$action': typeof AccountsAccountIdSettingsSubscriptionsStreamingZoneIdActionRoute
  '/accounts/$accountId/zones/$zoneId/player/pair/$player': typeof AccountsAccountIdZonesZoneIdPlayerPairPlayerRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/create'
    | '/dev'
    | '/discover'
    | '/electron'
    | '/home'
    | '/partner-portal'
    | '/search'
    | '/setup-guide'
    | '/user'
    | '/account'
    | '/download'
    | '/signup'
    | '/spotify-callback'
    | '/accounts/$accountId'
    | '/forgot-password'
    | '/logout'
    | '/nps-survey'
    | '/sso'
    | '/create/ai-playlist'
    | '/create/ai-station'
    | '/create/import-spotify-playlist'
    | '/create/playlist'
    | '/create/schedule'
    | '/create/synced-playlist'
    | '/dev/drag-drop'
    | '/dev/flags'
    | '/dev/playback'
    | '/dev/price-breakdown'
    | '/dev/sandbox'
    | '/dev/sentry'
    | '/dev/surveys'
    | '/dev/toasts'
    | '/dev/tooltips'
    | '/discover/$'
    | '/electron/browser-opened'
    | '/electron/select'
    | '/home/$page'
    | '/partner-portal/$accountId'
    | '/search/$'
    | '/setup-guide/business-description'
    | '/setup-guide/choose-plan'
    | '/setup-guide/create-playlist'
    | '/setup-guide/explicit-setting'
    | '/setup-guide/pair'
    | '/setup-guide/qr-app-login'
    | '/setup-guide/see-options'
    | '/setup-guide/spotify-import'
    | '/setup-guide/user-invite'
    | '/signup/$'
    | '/user/change-email'
    | '/user/change-password'
    | '/user/edit'
    | '/addons'
    | '/create/'
    | '/dev/'
    | '/discover/'
    | '/home/'
    | '/partner-portal/'
    | '/accounts/$accountId/checkout'
    | '/accounts/$accountId/messaging'
    | '/accounts/$accountId/onboarding'
    | '/accounts/$accountId/settings'
    | '/accounts/$accountId/your-music'
    | '/accounts/$accountId/zones'
    | '/invitation/$token'
    | '/reset-password/$token'
    | '/verify-email/$token'
    | '/invoice/$uuid'
    | '/legal-certificate/print'
    | '/nps-survey/$variant'
    | '/referral/$code'
    | '/sso/$'
    | '/addons/activated/$addon'
    | '/addons/promo/curation'
    | '/addons/promo/hifi'
    | '/addons/promo/messaging'
    | '/addons/promo/sso'
    | '/create/station/from-playlist'
    | '/create/station/from-tags'
    | '/discover/album/$releaseId'
    | '/discover/music/$musicId'
    | '/discover/schedule/$id'
    | '/electron/login/email'
    | '/electron/login/pairing-code'
    | '/electron/select/$accountId'
    | '/partner-portal/$accountId/create'
    | '/setup-guide/pair/$action'
    | '/invitation'
    | '/login'
    | '/legal-certificate'
    | '/nps-survey/'
    | '/accounts/$accountId/'
    | '/electron/login'
    | '/setup-guide/pair/'
    | '/accounts/$accountId/settings/billing'
    | '/accounts/$accountId/settings/subscriptions'
    | '/login/saml/$orgSlug'
    | '/exchangerates/$date/$currency'
    | '/invoice/$accountId/$ref'
    | '/connect/$vendor/$pairingCode'
    | '/connect/$vendor/pair'
    | '/accounts/$accountId/change-plan/$plan'
    | '/accounts/$accountId/checkout/$'
    | '/accounts/$accountId/locations/$'
    | '/accounts/$accountId/messaging/messages'
    | '/accounts/$accountId/onboarding/$step'
    | '/accounts/$accountId/settings/overview'
    | '/accounts/$accountId/settings/referrals'
    | '/accounts/$accountId/settings/saml'
    | '/accounts/$accountId/settings/users'
    | '/accounts/$accountId/settings/zone-presets'
    | '/accounts/$accountId/voucher/$code'
    | '/accounts/$accountId/your-music/$musicId'
    | '/accounts/$accountId/zones/$zoneId'
    | '/discover/categories/$type/$categoryId'
    | '/discover/music/$musicId/edit'
    | '/discover/schedule/$id/edit'
    | '/electron/select/$accountId/$locationId'
    | '/login/saml'
    | '/connect/$vendor'
    | '/accounts/$accountId/change-plan'
    | '/accounts/$accountId/choose-plan'
    | '/accounts/$accountId/locations'
    | '/accounts/$accountId/messaging/'
    | '/accounts/$accountId/settings/'
    | '/accounts/$accountId/upgrade-plan'
    | '/accounts/$accountId/your-music/'
    | '/discover/music/$musicId/'
    | '/connect/$vendor/$pairingCode/pair'
    | '/sonos/connect/v2/$householdId'
    | '/sonos/pair/v2/$householdId'
    | '/accounts/$accountId/addons/$addonId/activate'
    | '/accounts/$accountId/change-plan/confirmed/$plan'
    | '/accounts/$accountId/messaging/campaigns/$campaignId'
    | '/accounts/$accountId/messaging/campaigns/create'
    | '/accounts/$accountId/messaging/messages/$messageId'
    | '/accounts/$accountId/messaging/messages/create'
    | '/accounts/$accountId/settings/billing/update-billing-email'
    | '/accounts/$accountId/settings/overview/company-details'
    | '/accounts/$accountId/settings/subscriptions/streaming'
    | '/accounts/$accountId/settings/users/invite'
    | '/accounts/$accountId/your-music/playlists/$musicId'
    | '/accounts/$accountId/your-music/schedules/create'
    | '/accounts/$accountId/your-music/schedules/edit'
    | '/accounts/$accountId/your-music/schedules/$scheduleId'
    | '/accounts/$accountId/zones/$zoneId/control'
    | '/accounts/$accountId/zones/$zoneId/player'
    | '/accounts/$accountId/zones/$zoneId/remote'
    | '/accounts/$accountId/zones/$zoneId/settings'
    | '/accounts/$accountId/zones/$zoneId/subscription'
    | '/accounts/$accountId/zones/create/$locationId'
    | '/accounts/$accountId/messaging/campaigns'
    | '/accounts/$accountId/settings/subscriptions/'
    | '/accounts/$accountId/your-music/playlists'
    | '/accounts/$accountId/your-music/schedules'
    | '/accounts/$accountId/zones/$zoneId/'
    | '/accounts/$accountId/zones/create'
    | '/connect/$vendor/$pairingCode/pair/$zoneId'
    | '/accounts/$accountId/settings/billing/update-payment/$type'
    | '/accounts/$accountId/settings/subscriptions/addons/$subscriptionId'
    | '/accounts/$accountId/settings/subscriptions/streaming/$zoneId'
    | '/accounts/$accountId/your-music/schedules/$scheduleId/copy'
    | '/accounts/$accountId/your-music/schedules/$scheduleId/edit'
    | '/accounts/$accountId/your-music/schedules/edit/$scheduleId'
    | '/accounts/$accountId/zones/$zoneId/player/$action'
    | '/accounts/$accountId/zones/$zoneId/subscription/$action'
    | '/accounts/$accountId/zones/$zoneId/player/blocked-tracks'
    | '/accounts/$accountId/zones/$zoneId/player/history'
    | '/accounts/$accountId/settings/billing/invoice-group/edit/$groupId'
    | '/accounts/$accountId/settings/subscriptions/streaming/$zoneId/$action'
    | '/accounts/$accountId/zones/$zoneId/player/pair/$player'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/electron'
    | '/search'
    | '/setup-guide'
    | '/user'
    | '/account'
    | '/download'
    | '/signup'
    | '/spotify-callback'
    | '/forgot-password'
    | '/logout'
    | '/sso'
    | '/create/ai-playlist'
    | '/create/ai-station'
    | '/create/import-spotify-playlist'
    | '/create/playlist'
    | '/create/schedule'
    | '/create/synced-playlist'
    | '/dev/drag-drop'
    | '/dev/flags'
    | '/dev/playback'
    | '/dev/price-breakdown'
    | '/dev/sandbox'
    | '/dev/sentry'
    | '/dev/surveys'
    | '/dev/toasts'
    | '/dev/tooltips'
    | '/discover/$'
    | '/electron/browser-opened'
    | '/electron/select'
    | '/home/$page'
    | '/partner-portal/$accountId'
    | '/search/$'
    | '/setup-guide/business-description'
    | '/setup-guide/choose-plan'
    | '/setup-guide/create-playlist'
    | '/setup-guide/explicit-setting'
    | '/setup-guide/qr-app-login'
    | '/setup-guide/see-options'
    | '/setup-guide/spotify-import'
    | '/setup-guide/user-invite'
    | '/signup/$'
    | '/user/change-email'
    | '/user/change-password'
    | '/user/edit'
    | '/addons'
    | '/create'
    | '/dev'
    | '/discover'
    | '/home'
    | '/partner-portal'
    | '/accounts/$accountId/checkout'
    | '/accounts/$accountId/onboarding'
    | '/accounts/$accountId/zones'
    | '/invitation/$token'
    | '/reset-password/$token'
    | '/verify-email/$token'
    | '/invoice/$uuid'
    | '/legal-certificate/print'
    | '/nps-survey/$variant'
    | '/referral/$code'
    | '/sso/$'
    | '/addons/activated/$addon'
    | '/addons/promo/curation'
    | '/addons/promo/hifi'
    | '/addons/promo/messaging'
    | '/addons/promo/sso'
    | '/create/station/from-playlist'
    | '/create/station/from-tags'
    | '/discover/album/$releaseId'
    | '/discover/schedule/$id'
    | '/electron/login/email'
    | '/electron/login/pairing-code'
    | '/electron/select/$accountId'
    | '/partner-portal/$accountId/create'
    | '/setup-guide/pair/$action'
    | '/invitation'
    | '/login'
    | '/legal-certificate'
    | '/nps-survey'
    | '/accounts/$accountId'
    | '/electron/login'
    | '/setup-guide/pair'
    | '/accounts/$accountId/settings/billing'
    | '/login/saml/$orgSlug'
    | '/exchangerates/$date/$currency'
    | '/invoice/$accountId/$ref'
    | '/connect/$vendor/$pairingCode'
    | '/connect/$vendor/pair'
    | '/accounts/$accountId/change-plan/$plan'
    | '/accounts/$accountId/checkout/$'
    | '/accounts/$accountId/locations/$'
    | '/accounts/$accountId/messaging/messages'
    | '/accounts/$accountId/onboarding/$step'
    | '/accounts/$accountId/settings/overview'
    | '/accounts/$accountId/settings/referrals'
    | '/accounts/$accountId/settings/saml'
    | '/accounts/$accountId/settings/users'
    | '/accounts/$accountId/settings/zone-presets'
    | '/accounts/$accountId/voucher/$code'
    | '/accounts/$accountId/your-music/$musicId'
    | '/discover/categories/$type/$categoryId'
    | '/discover/music/$musicId/edit'
    | '/discover/schedule/$id/edit'
    | '/electron/select/$accountId/$locationId'
    | '/login/saml'
    | '/connect/$vendor'
    | '/accounts/$accountId/change-plan'
    | '/accounts/$accountId/choose-plan'
    | '/accounts/$accountId/locations'
    | '/accounts/$accountId/messaging'
    | '/accounts/$accountId/settings'
    | '/accounts/$accountId/upgrade-plan'
    | '/accounts/$accountId/your-music'
    | '/discover/music/$musicId'
    | '/connect/$vendor/$pairingCode/pair'
    | '/sonos/connect/v2/$householdId'
    | '/sonos/pair/v2/$householdId'
    | '/accounts/$accountId/addons/$addonId/activate'
    | '/accounts/$accountId/change-plan/confirmed/$plan'
    | '/accounts/$accountId/messaging/campaigns/$campaignId'
    | '/accounts/$accountId/messaging/campaigns/create'
    | '/accounts/$accountId/messaging/messages/$messageId'
    | '/accounts/$accountId/messaging/messages/create'
    | '/accounts/$accountId/settings/billing/update-billing-email'
    | '/accounts/$accountId/settings/overview/company-details'
    | '/accounts/$accountId/settings/subscriptions/streaming'
    | '/accounts/$accountId/settings/users/invite'
    | '/accounts/$accountId/your-music/playlists/$musicId'
    | '/accounts/$accountId/your-music/schedules/create'
    | '/accounts/$accountId/your-music/schedules/edit'
    | '/accounts/$accountId/your-music/schedules/$scheduleId'
    | '/accounts/$accountId/zones/$zoneId/control'
    | '/accounts/$accountId/zones/$zoneId/player'
    | '/accounts/$accountId/zones/$zoneId/remote'
    | '/accounts/$accountId/zones/$zoneId/settings'
    | '/accounts/$accountId/zones/$zoneId/subscription'
    | '/accounts/$accountId/zones/create/$locationId'
    | '/accounts/$accountId/messaging/campaigns'
    | '/accounts/$accountId/settings/subscriptions'
    | '/accounts/$accountId/your-music/playlists'
    | '/accounts/$accountId/your-music/schedules'
    | '/accounts/$accountId/zones/$zoneId'
    | '/accounts/$accountId/zones/create'
    | '/connect/$vendor/$pairingCode/pair/$zoneId'
    | '/accounts/$accountId/settings/billing/update-payment/$type'
    | '/accounts/$accountId/settings/subscriptions/addons/$subscriptionId'
    | '/accounts/$accountId/settings/subscriptions/streaming/$zoneId'
    | '/accounts/$accountId/your-music/schedules/$scheduleId/copy'
    | '/accounts/$accountId/your-music/schedules/$scheduleId/edit'
    | '/accounts/$accountId/your-music/schedules/edit/$scheduleId'
    | '/accounts/$accountId/zones/$zoneId/player/$action'
    | '/accounts/$accountId/zones/$zoneId/subscription/$action'
    | '/accounts/$accountId/zones/$zoneId/player/blocked-tracks'
    | '/accounts/$accountId/zones/$zoneId/player/history'
    | '/accounts/$accountId/settings/billing/invoice-group/edit/$groupId'
    | '/accounts/$accountId/settings/subscriptions/streaming/$zoneId/$action'
    | '/accounts/$accountId/zones/$zoneId/player/pair/$player'
  id:
    | '__root__'
    | '/'
    | '/(logged-out)'
    | '/create'
    | '/dev'
    | '/discover'
    | '/electron'
    | '/home'
    | '/partner-portal'
    | '/search'
    | '/setup-guide'
    | '/user'
    | '/account'
    | '/download'
    | '/signup'
    | '/spotify-callback'
    | '/accounts/$accountId'
    | '/(logged-out)/forgot-password'
    | '/(misc)/logout'
    | '/(misc)/nps-survey'
    | '/(misc)/sso'
    | '/create/ai-playlist'
    | '/create/ai-station'
    | '/create/import-spotify-playlist'
    | '/create/playlist'
    | '/create/schedule'
    | '/create/synced-playlist'
    | '/dev/drag-drop'
    | '/dev/flags'
    | '/dev/playback'
    | '/dev/price-breakdown'
    | '/dev/sandbox'
    | '/dev/sentry'
    | '/dev/surveys'
    | '/dev/toasts'
    | '/dev/tooltips'
    | '/discover/$'
    | '/electron/browser-opened'
    | '/electron/select'
    | '/home/$page'
    | '/partner-portal/$accountId'
    | '/search/$'
    | '/setup-guide/business-description'
    | '/setup-guide/choose-plan'
    | '/setup-guide/create-playlist'
    | '/setup-guide/explicit-setting'
    | '/setup-guide/pair'
    | '/setup-guide/qr-app-login'
    | '/setup-guide/see-options'
    | '/setup-guide/spotify-import'
    | '/setup-guide/user-invite'
    | '/signup/$'
    | '/user/change-email'
    | '/user/change-password'
    | '/user/edit'
    | '/addons/'
    | '/create/'
    | '/dev/'
    | '/discover/'
    | '/home/'
    | '/partner-portal/'
    | '/accounts/$accountId/checkout'
    | '/accounts/$accountId/messaging'
    | '/accounts/$accountId/onboarding'
    | '/accounts/$accountId/settings'
    | '/accounts/$accountId/your-music'
    | '/accounts/$accountId/zones'
    | '/(logged-out)/invitation/$token'
    | '/(logged-out)/reset-password/$token'
    | '/(logged-out)/verify-email/$token'
    | '/(misc)/invoice/$uuid'
    | '/(misc)/legal-certificate/print'
    | '/(misc)/nps-survey/$variant'
    | '/(misc)/referral/$code'
    | '/(misc)/sso/$'
    | '/addons/activated/$addon'
    | '/addons/promo/curation'
    | '/addons/promo/hifi'
    | '/addons/promo/messaging'
    | '/addons/promo/sso'
    | '/create/station/from-playlist'
    | '/create/station/from-tags'
    | '/discover/album/$releaseId'
    | '/discover/music/$musicId'
    | '/discover/schedule/$id'
    | '/electron/login/email'
    | '/electron/login/pairing-code'
    | '/electron/select/$accountId'
    | '/partner-portal/$accountId/create'
    | '/setup-guide/pair/$action'
    | '/(logged-out)/invitation/'
    | '/(logged-out)/login/'
    | '/(misc)/legal-certificate/'
    | '/(misc)/nps-survey/'
    | '/accounts/$accountId/'
    | '/electron/login/'
    | '/setup-guide/pair/'
    | '/accounts/$accountId/settings/billing'
    | '/accounts/$accountId/settings/subscriptions'
    | '/(logged-out)/login/saml/$orgSlug'
    | '/(misc)/exchangerates/$date/$currency'
    | '/(misc)/invoice/$accountId/$ref'
    | '/(pairing-guide)/connect/$vendor/$pairingCode_'
    | '/(pairing-guide)/connect/$vendor/pair'
    | '/accounts/$accountId/change-plan/$plan'
    | '/accounts/$accountId/checkout/$'
    | '/accounts/$accountId/locations/$'
    | '/accounts/$accountId/messaging/messages'
    | '/accounts/$accountId/onboarding/$step'
    | '/accounts/$accountId/settings/overview'
    | '/accounts/$accountId/settings/referrals'
    | '/accounts/$accountId/settings/saml'
    | '/accounts/$accountId/settings/users'
    | '/accounts/$accountId/settings/zone-presets'
    | '/accounts/$accountId/voucher/$code'
    | '/accounts/$accountId/your-music_/$musicId'
    | '/accounts/$accountId/zones/$zoneId'
    | '/discover/categories/$type/$categoryId'
    | '/discover/music/$musicId/edit'
    | '/discover/schedule/$id/edit'
    | '/electron/select/$accountId/$locationId'
    | '/(logged-out)/login/saml/'
    | '/(pairing-guide)/connect/$vendor/'
    | '/accounts/$accountId/change-plan/'
    | '/accounts/$accountId/choose-plan/'
    | '/accounts/$accountId/locations/'
    | '/accounts/$accountId/messaging/'
    | '/accounts/$accountId/settings/'
    | '/accounts/$accountId/upgrade-plan/'
    | '/accounts/$accountId/your-music/'
    | '/discover/music/$musicId/'
    | '/(pairing-guide)/connect/$vendor/$pairingCode/pair'
    | '/(pairing-guide)/sonos/connect/v2/$householdId'
    | '/(pairing-guide)/sonos/pair/v2/$householdId'
    | '/accounts/$accountId/addons/$addonId/activate'
    | '/accounts/$accountId/change-plan/confirmed/$plan'
    | '/accounts/$accountId/messaging/campaigns/$campaignId'
    | '/accounts/$accountId/messaging/campaigns/create'
    | '/accounts/$accountId/messaging/messages/$messageId'
    | '/accounts/$accountId/messaging/messages/create'
    | '/accounts/$accountId/settings/billing/update-billing-email'
    | '/accounts/$accountId/settings/overview/company-details'
    | '/accounts/$accountId/settings/subscriptions/streaming'
    | '/accounts/$accountId/settings/users/invite'
    | '/accounts/$accountId/your-music/playlists/$musicId'
    | '/accounts/$accountId/your-music/schedules/create'
    | '/accounts/$accountId/your-music/schedules/edit'
    | '/accounts/$accountId/your-music_/schedules/$scheduleId'
    | '/accounts/$accountId/zones/$zoneId/control'
    | '/accounts/$accountId/zones/$zoneId/player'
    | '/accounts/$accountId/zones/$zoneId/remote'
    | '/accounts/$accountId/zones/$zoneId/settings'
    | '/accounts/$accountId/zones/$zoneId/subscription'
    | '/accounts/$accountId/zones/create/$locationId'
    | '/accounts/$accountId/messaging/campaigns/'
    | '/accounts/$accountId/settings/subscriptions/'
    | '/accounts/$accountId/your-music/playlists/'
    | '/accounts/$accountId/your-music/schedules/'
    | '/accounts/$accountId/zones/$zoneId/'
    | '/accounts/$accountId/zones/create/'
    | '/(pairing-guide)/connect/$vendor/$pairingCode/pair/$zoneId'
    | '/accounts/$accountId/settings/billing/update-payment/$type'
    | '/accounts/$accountId/settings/subscriptions/addons/$subscriptionId'
    | '/accounts/$accountId/settings/subscriptions/streaming/$zoneId'
    | '/accounts/$accountId/your-music/schedules/$scheduleId/copy'
    | '/accounts/$accountId/your-music/schedules/$scheduleId/edit'
    | '/accounts/$accountId/your-music/schedules/edit/$scheduleId'
    | '/accounts/$accountId/zones/$zoneId/player/$action'
    | '/accounts/$accountId/zones/$zoneId/subscription/$action'
    | '/accounts/$accountId/zones/$zoneId_/player/blocked-tracks'
    | '/accounts/$accountId/zones/$zoneId_/player/history'
    | '/accounts/$accountId/settings/billing/invoice-group/edit/$groupId'
    | '/accounts/$accountId/settings/subscriptions/streaming/$zoneId/$action'
    | '/accounts/$accountId/zones/$zoneId/player/pair/$player'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRouteWithChildren
  CreateRouteRoute: typeof CreateRouteRouteWithChildren
  DevRouteRoute: typeof DevRouteRouteWithChildren
  DiscoverRouteRoute: typeof DiscoverRouteRouteWithChildren
  ElectronRouteRoute: typeof ElectronRouteRouteWithChildren
  HomeRouteRoute: typeof HomeRouteRouteWithChildren
  PartnerPortalRouteRoute: typeof PartnerPortalRouteRouteWithChildren
  SearchRouteRoute: typeof SearchRouteRouteWithChildren
  SetupGuideRouteRoute: typeof SetupGuideRouteRouteWithChildren
  UserRouteRoute: typeof UserRouteRouteWithChildren
  AccountRoute: typeof AccountRoute
  DownloadRoute: typeof DownloadRoute
  SignupRoute: typeof SignupRouteWithChildren
  SpotifyCallbackRoute: typeof SpotifyCallbackRoute
  AccountsAccountIdRouteRoute: typeof AccountsAccountIdRouteRouteWithChildren
  miscLogoutRoute: typeof miscLogoutRoute
  miscNpsSurveyRoute: typeof miscNpsSurveyRouteWithChildren
  miscSsoRoute: typeof miscSsoRouteWithChildren
  AddonsIndexRoute: typeof AddonsIndexRoute
  miscInvoiceUuidRoute: typeof miscInvoiceUuidRoute
  miscLegalCertificatePrintRoute: typeof miscLegalCertificatePrintRoute
  miscReferralCodeRoute: typeof miscReferralCodeRoute
  AddonsActivatedAddonRoute: typeof AddonsActivatedAddonRoute
  AddonsPromoCurationRoute: typeof AddonsPromoCurationRoute
  AddonsPromoHifiRoute: typeof AddonsPromoHifiRoute
  AddonsPromoMessagingRoute: typeof AddonsPromoMessagingRoute
  AddonsPromoSsoRoute: typeof AddonsPromoSsoRoute
  miscLegalCertificateIndexRoute: typeof miscLegalCertificateIndexRoute
  miscExchangeratesDateCurrencyRoute: typeof miscExchangeratesDateCurrencyRoute
  miscInvoiceAccountIdRefRoute: typeof miscInvoiceAccountIdRefRoute
  pairingGuideConnectVendorPairingCodeRoute: typeof pairingGuideConnectVendorPairingCodeRoute
  pairingGuideConnectVendorPairRoute: typeof pairingGuideConnectVendorPairRoute
  pairingGuideConnectVendorIndexRoute: typeof pairingGuideConnectVendorIndexRoute
  pairingGuideConnectVendorPairingCodePairRoute: typeof pairingGuideConnectVendorPairingCodePairRouteWithChildren
  pairingGuideSonosConnectV2HouseholdIdRoute: typeof pairingGuideSonosConnectV2HouseholdIdRoute
  pairingGuideSonosPairV2HouseholdIdRoute: typeof pairingGuideSonosPairV2HouseholdIdRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRouteWithChildren,
  CreateRouteRoute: CreateRouteRouteWithChildren,
  DevRouteRoute: DevRouteRouteWithChildren,
  DiscoverRouteRoute: DiscoverRouteRouteWithChildren,
  ElectronRouteRoute: ElectronRouteRouteWithChildren,
  HomeRouteRoute: HomeRouteRouteWithChildren,
  PartnerPortalRouteRoute: PartnerPortalRouteRouteWithChildren,
  SearchRouteRoute: SearchRouteRouteWithChildren,
  SetupGuideRouteRoute: SetupGuideRouteRouteWithChildren,
  UserRouteRoute: UserRouteRouteWithChildren,
  AccountRoute: AccountRoute,
  DownloadRoute: DownloadRoute,
  SignupRoute: SignupRouteWithChildren,
  SpotifyCallbackRoute: SpotifyCallbackRoute,
  AccountsAccountIdRouteRoute: AccountsAccountIdRouteRouteWithChildren,
  miscLogoutRoute: miscLogoutRoute,
  miscNpsSurveyRoute: miscNpsSurveyRouteWithChildren,
  miscSsoRoute: miscSsoRouteWithChildren,
  AddonsIndexRoute: AddonsIndexRoute,
  miscInvoiceUuidRoute: miscInvoiceUuidRoute,
  miscLegalCertificatePrintRoute: miscLegalCertificatePrintRoute,
  miscReferralCodeRoute: miscReferralCodeRoute,
  AddonsActivatedAddonRoute: AddonsActivatedAddonRoute,
  AddonsPromoCurationRoute: AddonsPromoCurationRoute,
  AddonsPromoHifiRoute: AddonsPromoHifiRoute,
  AddonsPromoMessagingRoute: AddonsPromoMessagingRoute,
  AddonsPromoSsoRoute: AddonsPromoSsoRoute,
  miscLegalCertificateIndexRoute: miscLegalCertificateIndexRoute,
  miscExchangeratesDateCurrencyRoute: miscExchangeratesDateCurrencyRoute,
  miscInvoiceAccountIdRefRoute: miscInvoiceAccountIdRefRoute,
  pairingGuideConnectVendorPairingCodeRoute:
    pairingGuideConnectVendorPairingCodeRoute,
  pairingGuideConnectVendorPairRoute: pairingGuideConnectVendorPairRoute,
  pairingGuideConnectVendorIndexRoute: pairingGuideConnectVendorIndexRoute,
  pairingGuideConnectVendorPairingCodePairRoute:
    pairingGuideConnectVendorPairingCodePairRouteWithChildren,
  pairingGuideSonosConnectV2HouseholdIdRoute:
    pairingGuideSonosConnectV2HouseholdIdRoute,
  pairingGuideSonosPairV2HouseholdIdRoute:
    pairingGuideSonosPairV2HouseholdIdRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/create",
        "/dev",
        "/discover",
        "/electron",
        "/home",
        "/partner-portal",
        "/search",
        "/setup-guide",
        "/user",
        "/account",
        "/download",
        "/signup",
        "/spotify-callback",
        "/accounts/$accountId",
        "/(misc)/logout",
        "/(misc)/nps-survey",
        "/(misc)/sso",
        "/addons/",
        "/(misc)/invoice/$uuid",
        "/(misc)/legal-certificate/print",
        "/(misc)/referral/$code",
        "/addons/activated/$addon",
        "/addons/promo/curation",
        "/addons/promo/hifi",
        "/addons/promo/messaging",
        "/addons/promo/sso",
        "/(misc)/legal-certificate/",
        "/(misc)/exchangerates/$date/$currency",
        "/(misc)/invoice/$accountId/$ref",
        "/(pairing-guide)/connect/$vendor/$pairingCode_",
        "/(pairing-guide)/connect/$vendor/pair",
        "/(pairing-guide)/connect/$vendor/",
        "/(pairing-guide)/connect/$vendor/$pairingCode/pair",
        "/(pairing-guide)/sonos/connect/v2/$householdId",
        "/(pairing-guide)/sonos/pair/v2/$householdId"
      ]
    },
    "/": {
      "filePath": "index.tsx",
      "children": [
        "/(logged-out)"
      ]
    },
    "/(logged-out)": {
      "filePath": "(logged-out)/route.tsx",
      "parent": "/",
      "children": [
        "/(logged-out)/forgot-password",
        "/(logged-out)/invitation/$token",
        "/(logged-out)/reset-password/$token",
        "/(logged-out)/verify-email/$token",
        "/(logged-out)/invitation/",
        "/(logged-out)/login/",
        "/(logged-out)/login/saml/$orgSlug",
        "/(logged-out)/login/saml/"
      ]
    },
    "/create": {
      "filePath": "create/route.tsx",
      "children": [
        "/create/ai-playlist",
        "/create/ai-station",
        "/create/import-spotify-playlist",
        "/create/playlist",
        "/create/schedule",
        "/create/synced-playlist",
        "/create/",
        "/create/station/from-playlist",
        "/create/station/from-tags"
      ]
    },
    "/dev": {
      "filePath": "dev/route.tsx",
      "children": [
        "/dev/drag-drop",
        "/dev/flags",
        "/dev/playback",
        "/dev/price-breakdown",
        "/dev/sandbox",
        "/dev/sentry",
        "/dev/surveys",
        "/dev/toasts",
        "/dev/tooltips",
        "/dev/"
      ]
    },
    "/discover": {
      "filePath": "discover/route.tsx",
      "children": [
        "/discover/$",
        "/discover/",
        "/discover/album/$releaseId",
        "/discover/music/$musicId",
        "/discover/schedule/$id",
        "/discover/categories/$type/$categoryId"
      ]
    },
    "/electron": {
      "filePath": "electron/route.tsx",
      "children": [
        "/electron/browser-opened",
        "/electron/select",
        "/electron/login/email",
        "/electron/login/pairing-code",
        "/electron/login/"
      ]
    },
    "/home": {
      "filePath": "home/route.tsx",
      "children": [
        "/home/$page",
        "/home/"
      ]
    },
    "/partner-portal": {
      "filePath": "partner-portal/route.tsx",
      "children": [
        "/partner-portal/$accountId",
        "/partner-portal/"
      ]
    },
    "/search": {
      "filePath": "search/route.tsx",
      "children": [
        "/search/$"
      ]
    },
    "/setup-guide": {
      "filePath": "setup-guide/route.tsx",
      "children": [
        "/setup-guide/business-description",
        "/setup-guide/choose-plan",
        "/setup-guide/create-playlist",
        "/setup-guide/explicit-setting",
        "/setup-guide/pair",
        "/setup-guide/qr-app-login",
        "/setup-guide/see-options",
        "/setup-guide/spotify-import",
        "/setup-guide/user-invite"
      ]
    },
    "/user": {
      "filePath": "user/route.tsx",
      "children": [
        "/user/change-email",
        "/user/change-password",
        "/user/edit"
      ]
    },
    "/account": {
      "filePath": "account.tsx"
    },
    "/download": {
      "filePath": "download.tsx"
    },
    "/signup": {
      "filePath": "signup.tsx",
      "children": [
        "/signup/$"
      ]
    },
    "/spotify-callback": {
      "filePath": "spotify-callback.tsx"
    },
    "/accounts/$accountId": {
      "filePath": "accounts.$accountId/route.tsx",
      "children": [
        "/accounts/$accountId/checkout",
        "/accounts/$accountId/messaging",
        "/accounts/$accountId/onboarding",
        "/accounts/$accountId/settings",
        "/accounts/$accountId/your-music",
        "/accounts/$accountId/zones",
        "/accounts/$accountId/",
        "/accounts/$accountId/change-plan/$plan",
        "/accounts/$accountId/locations/$",
        "/accounts/$accountId/voucher/$code",
        "/accounts/$accountId/your-music_/$musicId",
        "/accounts/$accountId/change-plan/",
        "/accounts/$accountId/choose-plan/",
        "/accounts/$accountId/locations/",
        "/accounts/$accountId/upgrade-plan/",
        "/accounts/$accountId/addons/$addonId/activate",
        "/accounts/$accountId/change-plan/confirmed/$plan",
        "/accounts/$accountId/your-music_/schedules/$scheduleId"
      ]
    },
    "/(logged-out)/forgot-password": {
      "filePath": "(logged-out)/forgot-password.tsx",
      "parent": "/(logged-out)"
    },
    "/(misc)/logout": {
      "filePath": "(misc)/logout.tsx"
    },
    "/(misc)/nps-survey": {
      "filePath": "(misc)/nps-survey.tsx",
      "children": [
        "/(misc)/nps-survey/$variant",
        "/(misc)/nps-survey/"
      ]
    },
    "/(misc)/sso": {
      "filePath": "(misc)/sso.tsx",
      "children": [
        "/(misc)/sso/$"
      ]
    },
    "/create/ai-playlist": {
      "filePath": "create/ai-playlist.tsx",
      "parent": "/create"
    },
    "/create/ai-station": {
      "filePath": "create/ai-station.tsx",
      "parent": "/create"
    },
    "/create/import-spotify-playlist": {
      "filePath": "create/import-spotify-playlist.tsx",
      "parent": "/create"
    },
    "/create/playlist": {
      "filePath": "create/playlist.tsx",
      "parent": "/create"
    },
    "/create/schedule": {
      "filePath": "create/schedule.tsx",
      "parent": "/create"
    },
    "/create/synced-playlist": {
      "filePath": "create/synced-playlist.tsx",
      "parent": "/create"
    },
    "/dev/drag-drop": {
      "filePath": "dev/drag-drop.tsx",
      "parent": "/dev"
    },
    "/dev/flags": {
      "filePath": "dev/flags.tsx",
      "parent": "/dev"
    },
    "/dev/playback": {
      "filePath": "dev/playback.tsx",
      "parent": "/dev"
    },
    "/dev/price-breakdown": {
      "filePath": "dev/price-breakdown.tsx",
      "parent": "/dev"
    },
    "/dev/sandbox": {
      "filePath": "dev/sandbox.tsx",
      "parent": "/dev"
    },
    "/dev/sentry": {
      "filePath": "dev/sentry.tsx",
      "parent": "/dev"
    },
    "/dev/surveys": {
      "filePath": "dev/surveys.tsx",
      "parent": "/dev"
    },
    "/dev/toasts": {
      "filePath": "dev/toasts.tsx",
      "parent": "/dev"
    },
    "/dev/tooltips": {
      "filePath": "dev/tooltips.tsx",
      "parent": "/dev"
    },
    "/discover/$": {
      "filePath": "discover/$.tsx",
      "parent": "/discover"
    },
    "/electron/browser-opened": {
      "filePath": "electron/browser-opened.tsx",
      "parent": "/electron"
    },
    "/electron/select": {
      "filePath": "electron/select.tsx",
      "parent": "/electron",
      "children": [
        "/electron/select/$accountId"
      ]
    },
    "/home/$page": {
      "filePath": "home/$page.tsx",
      "parent": "/home"
    },
    "/partner-portal/$accountId": {
      "filePath": "partner-portal/$accountId.tsx",
      "parent": "/partner-portal",
      "children": [
        "/partner-portal/$accountId/create"
      ]
    },
    "/search/$": {
      "filePath": "search/$.tsx",
      "parent": "/search"
    },
    "/setup-guide/business-description": {
      "filePath": "setup-guide/business-description.tsx",
      "parent": "/setup-guide"
    },
    "/setup-guide/choose-plan": {
      "filePath": "setup-guide/choose-plan.tsx",
      "parent": "/setup-guide"
    },
    "/setup-guide/create-playlist": {
      "filePath": "setup-guide/create-playlist.tsx",
      "parent": "/setup-guide"
    },
    "/setup-guide/explicit-setting": {
      "filePath": "setup-guide/explicit-setting.tsx",
      "parent": "/setup-guide"
    },
    "/setup-guide/pair": {
      "filePath": "setup-guide/pair.tsx",
      "parent": "/setup-guide",
      "children": [
        "/setup-guide/pair/$action",
        "/setup-guide/pair/"
      ]
    },
    "/setup-guide/qr-app-login": {
      "filePath": "setup-guide/qr-app-login.tsx",
      "parent": "/setup-guide"
    },
    "/setup-guide/see-options": {
      "filePath": "setup-guide/see-options.tsx",
      "parent": "/setup-guide"
    },
    "/setup-guide/spotify-import": {
      "filePath": "setup-guide/spotify-import.tsx",
      "parent": "/setup-guide"
    },
    "/setup-guide/user-invite": {
      "filePath": "setup-guide/user-invite.tsx",
      "parent": "/setup-guide"
    },
    "/signup/$": {
      "filePath": "signup.$.tsx",
      "parent": "/signup"
    },
    "/user/change-email": {
      "filePath": "user/change-email.tsx",
      "parent": "/user"
    },
    "/user/change-password": {
      "filePath": "user/change-password.tsx",
      "parent": "/user"
    },
    "/user/edit": {
      "filePath": "user/edit.tsx",
      "parent": "/user"
    },
    "/addons/": {
      "filePath": "addons/index.tsx"
    },
    "/create/": {
      "filePath": "create/index.tsx",
      "parent": "/create"
    },
    "/dev/": {
      "filePath": "dev/index.tsx",
      "parent": "/dev"
    },
    "/discover/": {
      "filePath": "discover/index.tsx",
      "parent": "/discover"
    },
    "/home/": {
      "filePath": "home/index.tsx",
      "parent": "/home"
    },
    "/partner-portal/": {
      "filePath": "partner-portal/index.tsx",
      "parent": "/partner-portal"
    },
    "/accounts/$accountId/checkout": {
      "filePath": "accounts.$accountId/checkout/route.tsx",
      "parent": "/accounts/$accountId",
      "children": [
        "/accounts/$accountId/checkout/$"
      ]
    },
    "/accounts/$accountId/messaging": {
      "filePath": "accounts.$accountId/messaging/route.tsx",
      "parent": "/accounts/$accountId",
      "children": [
        "/accounts/$accountId/messaging/messages",
        "/accounts/$accountId/messaging/",
        "/accounts/$accountId/messaging/campaigns/$campaignId",
        "/accounts/$accountId/messaging/campaigns/create",
        "/accounts/$accountId/messaging/campaigns/"
      ]
    },
    "/accounts/$accountId/onboarding": {
      "filePath": "accounts.$accountId/onboarding/route.tsx",
      "parent": "/accounts/$accountId",
      "children": [
        "/accounts/$accountId/onboarding/$step"
      ]
    },
    "/accounts/$accountId/settings": {
      "filePath": "accounts.$accountId/settings/route.tsx",
      "parent": "/accounts/$accountId",
      "children": [
        "/accounts/$accountId/settings/billing",
        "/accounts/$accountId/settings/subscriptions",
        "/accounts/$accountId/settings/overview",
        "/accounts/$accountId/settings/referrals",
        "/accounts/$accountId/settings/saml",
        "/accounts/$accountId/settings/users",
        "/accounts/$accountId/settings/zone-presets",
        "/accounts/$accountId/settings/"
      ]
    },
    "/accounts/$accountId/your-music": {
      "filePath": "accounts.$accountId/your-music/route.tsx",
      "parent": "/accounts/$accountId",
      "children": [
        "/accounts/$accountId/your-music/",
        "/accounts/$accountId/your-music/playlists/$musicId",
        "/accounts/$accountId/your-music/schedules/create",
        "/accounts/$accountId/your-music/schedules/edit",
        "/accounts/$accountId/your-music/playlists/",
        "/accounts/$accountId/your-music/schedules/",
        "/accounts/$accountId/your-music/schedules/$scheduleId/copy",
        "/accounts/$accountId/your-music/schedules/$scheduleId/edit"
      ]
    },
    "/accounts/$accountId/zones": {
      "filePath": "accounts.$accountId/zones/route.tsx",
      "parent": "/accounts/$accountId",
      "children": [
        "/accounts/$accountId/zones/$zoneId",
        "/accounts/$accountId/zones/create/$locationId",
        "/accounts/$accountId/zones/create/",
        "/accounts/$accountId/zones/$zoneId_/player/blocked-tracks",
        "/accounts/$accountId/zones/$zoneId_/player/history"
      ]
    },
    "/(logged-out)/invitation/$token": {
      "filePath": "(logged-out)/invitation.$token.tsx",
      "parent": "/(logged-out)"
    },
    "/(logged-out)/reset-password/$token": {
      "filePath": "(logged-out)/reset-password.$token.tsx",
      "parent": "/(logged-out)"
    },
    "/(logged-out)/verify-email/$token": {
      "filePath": "(logged-out)/verify-email.$token.tsx",
      "parent": "/(logged-out)"
    },
    "/(misc)/invoice/$uuid": {
      "filePath": "(misc)/invoice.$uuid.tsx"
    },
    "/(misc)/legal-certificate/print": {
      "filePath": "(misc)/legal-certificate.print.tsx"
    },
    "/(misc)/nps-survey/$variant": {
      "filePath": "(misc)/nps-survey.$variant.tsx",
      "parent": "/(misc)/nps-survey"
    },
    "/(misc)/referral/$code": {
      "filePath": "(misc)/referral.$code.tsx"
    },
    "/(misc)/sso/$": {
      "filePath": "(misc)/sso.$.tsx",
      "parent": "/(misc)/sso"
    },
    "/addons/activated/$addon": {
      "filePath": "addons/activated.$addon.tsx"
    },
    "/addons/promo/curation": {
      "filePath": "addons/promo.curation.tsx"
    },
    "/addons/promo/hifi": {
      "filePath": "addons/promo.hifi.tsx"
    },
    "/addons/promo/messaging": {
      "filePath": "addons/promo.messaging.tsx"
    },
    "/addons/promo/sso": {
      "filePath": "addons/promo.sso.tsx"
    },
    "/create/station/from-playlist": {
      "filePath": "create/station.from-playlist.tsx",
      "parent": "/create"
    },
    "/create/station/from-tags": {
      "filePath": "create/station.from-tags.tsx",
      "parent": "/create"
    },
    "/discover/album/$releaseId": {
      "filePath": "discover/album.$releaseId.tsx",
      "parent": "/discover"
    },
    "/discover/music/$musicId": {
      "filePath": "discover/music.$musicId.tsx",
      "parent": "/discover",
      "children": [
        "/discover/music/$musicId/edit",
        "/discover/music/$musicId/"
      ]
    },
    "/discover/schedule/$id": {
      "filePath": "discover/schedule.$id.tsx",
      "parent": "/discover",
      "children": [
        "/discover/schedule/$id/edit"
      ]
    },
    "/electron/login/email": {
      "filePath": "electron/login.email.tsx",
      "parent": "/electron"
    },
    "/electron/login/pairing-code": {
      "filePath": "electron/login.pairing-code.tsx",
      "parent": "/electron"
    },
    "/electron/select/$accountId": {
      "filePath": "electron/select.$accountId.tsx",
      "parent": "/electron/select",
      "children": [
        "/electron/select/$accountId/$locationId"
      ]
    },
    "/partner-portal/$accountId/create": {
      "filePath": "partner-portal/$accountId.create.tsx",
      "parent": "/partner-portal/$accountId"
    },
    "/setup-guide/pair/$action": {
      "filePath": "setup-guide/pair.$action.tsx",
      "parent": "/setup-guide/pair"
    },
    "/(logged-out)/invitation/": {
      "filePath": "(logged-out)/invitation.index.tsx",
      "parent": "/(logged-out)"
    },
    "/(logged-out)/login/": {
      "filePath": "(logged-out)/login.index.tsx",
      "parent": "/(logged-out)"
    },
    "/(misc)/legal-certificate/": {
      "filePath": "(misc)/legal-certificate.index.tsx"
    },
    "/(misc)/nps-survey/": {
      "filePath": "(misc)/nps-survey.index.tsx",
      "parent": "/(misc)/nps-survey"
    },
    "/accounts/$accountId/": {
      "filePath": "accounts.$accountId/index.tsx",
      "parent": "/accounts/$accountId"
    },
    "/electron/login/": {
      "filePath": "electron/login.index.tsx",
      "parent": "/electron"
    },
    "/setup-guide/pair/": {
      "filePath": "setup-guide/pair.index.tsx",
      "parent": "/setup-guide/pair"
    },
    "/accounts/$accountId/settings/billing": {
      "filePath": "accounts.$accountId/settings/billing/route.tsx",
      "parent": "/accounts/$accountId/settings",
      "children": [
        "/accounts/$accountId/settings/billing/update-billing-email",
        "/accounts/$accountId/settings/billing/update-payment/$type",
        "/accounts/$accountId/settings/billing/invoice-group/edit/$groupId"
      ]
    },
    "/accounts/$accountId/settings/subscriptions": {
      "filePath": "accounts.$accountId/settings/subscriptions/route.tsx",
      "parent": "/accounts/$accountId/settings",
      "children": [
        "/accounts/$accountId/settings/subscriptions/streaming",
        "/accounts/$accountId/settings/subscriptions/",
        "/accounts/$accountId/settings/subscriptions/addons/$subscriptionId"
      ]
    },
    "/(logged-out)/login/saml/$orgSlug": {
      "filePath": "(logged-out)/login.saml.$orgSlug.tsx",
      "parent": "/(logged-out)"
    },
    "/(misc)/exchangerates/$date/$currency": {
      "filePath": "(misc)/exchangerates.$date.$currency.tsx"
    },
    "/(misc)/invoice/$accountId/$ref": {
      "filePath": "(misc)/invoice.$accountId.$ref.tsx"
    },
    "/(pairing-guide)/connect/$vendor/$pairingCode_": {
      "filePath": "(pairing-guide)/connect.$vendor/$pairingCode_.tsx"
    },
    "/(pairing-guide)/connect/$vendor/pair": {
      "filePath": "(pairing-guide)/connect.$vendor/pair.tsx"
    },
    "/accounts/$accountId/change-plan/$plan": {
      "filePath": "accounts.$accountId/change-plan/$plan.tsx",
      "parent": "/accounts/$accountId"
    },
    "/accounts/$accountId/checkout/$": {
      "filePath": "accounts.$accountId/checkout/$.tsx",
      "parent": "/accounts/$accountId/checkout"
    },
    "/accounts/$accountId/locations/$": {
      "filePath": "accounts.$accountId/locations/$.tsx",
      "parent": "/accounts/$accountId"
    },
    "/accounts/$accountId/messaging/messages": {
      "filePath": "accounts.$accountId/messaging/messages.tsx",
      "parent": "/accounts/$accountId/messaging",
      "children": [
        "/accounts/$accountId/messaging/messages/$messageId",
        "/accounts/$accountId/messaging/messages/create"
      ]
    },
    "/accounts/$accountId/onboarding/$step": {
      "filePath": "accounts.$accountId/onboarding/$step.tsx",
      "parent": "/accounts/$accountId/onboarding"
    },
    "/accounts/$accountId/settings/overview": {
      "filePath": "accounts.$accountId/settings/overview.tsx",
      "parent": "/accounts/$accountId/settings",
      "children": [
        "/accounts/$accountId/settings/overview/company-details"
      ]
    },
    "/accounts/$accountId/settings/referrals": {
      "filePath": "accounts.$accountId/settings/referrals.tsx",
      "parent": "/accounts/$accountId/settings"
    },
    "/accounts/$accountId/settings/saml": {
      "filePath": "accounts.$accountId/settings/saml.tsx",
      "parent": "/accounts/$accountId/settings"
    },
    "/accounts/$accountId/settings/users": {
      "filePath": "accounts.$accountId/settings/users.tsx",
      "parent": "/accounts/$accountId/settings",
      "children": [
        "/accounts/$accountId/settings/users/invite"
      ]
    },
    "/accounts/$accountId/settings/zone-presets": {
      "filePath": "accounts.$accountId/settings/zone-presets.tsx",
      "parent": "/accounts/$accountId/settings"
    },
    "/accounts/$accountId/voucher/$code": {
      "filePath": "accounts.$accountId/voucher.$code.tsx",
      "parent": "/accounts/$accountId"
    },
    "/accounts/$accountId/your-music_/$musicId": {
      "filePath": "accounts.$accountId/your-music_.$musicId.tsx",
      "parent": "/accounts/$accountId"
    },
    "/accounts/$accountId/zones/$zoneId": {
      "filePath": "accounts.$accountId/zones/$zoneId.tsx",
      "parent": "/accounts/$accountId/zones",
      "children": [
        "/accounts/$accountId/zones/$zoneId/control",
        "/accounts/$accountId/zones/$zoneId/player",
        "/accounts/$accountId/zones/$zoneId/remote",
        "/accounts/$accountId/zones/$zoneId/settings",
        "/accounts/$accountId/zones/$zoneId/subscription",
        "/accounts/$accountId/zones/$zoneId/"
      ]
    },
    "/discover/categories/$type/$categoryId": {
      "filePath": "discover/categories.$type.$categoryId.tsx",
      "parent": "/discover"
    },
    "/discover/music/$musicId/edit": {
      "filePath": "discover/music.$musicId.edit.tsx",
      "parent": "/discover/music/$musicId"
    },
    "/discover/schedule/$id/edit": {
      "filePath": "discover/schedule.$id.edit.tsx",
      "parent": "/discover/schedule/$id"
    },
    "/electron/select/$accountId/$locationId": {
      "filePath": "electron/select.$accountId.$locationId.tsx",
      "parent": "/electron/select/$accountId"
    },
    "/(logged-out)/login/saml/": {
      "filePath": "(logged-out)/login.saml.index.tsx",
      "parent": "/(logged-out)"
    },
    "/(pairing-guide)/connect/$vendor/": {
      "filePath": "(pairing-guide)/connect.$vendor/index.tsx"
    },
    "/accounts/$accountId/change-plan/": {
      "filePath": "accounts.$accountId/change-plan/index.tsx",
      "parent": "/accounts/$accountId"
    },
    "/accounts/$accountId/choose-plan/": {
      "filePath": "accounts.$accountId/choose-plan/index.tsx",
      "parent": "/accounts/$accountId"
    },
    "/accounts/$accountId/locations/": {
      "filePath": "accounts.$accountId/locations/index.tsx",
      "parent": "/accounts/$accountId"
    },
    "/accounts/$accountId/messaging/": {
      "filePath": "accounts.$accountId/messaging/index.tsx",
      "parent": "/accounts/$accountId/messaging"
    },
    "/accounts/$accountId/settings/": {
      "filePath": "accounts.$accountId/settings/index.tsx",
      "parent": "/accounts/$accountId/settings"
    },
    "/accounts/$accountId/upgrade-plan/": {
      "filePath": "accounts.$accountId/upgrade-plan/index.tsx",
      "parent": "/accounts/$accountId"
    },
    "/accounts/$accountId/your-music/": {
      "filePath": "accounts.$accountId/your-music/index.tsx",
      "parent": "/accounts/$accountId/your-music"
    },
    "/discover/music/$musicId/": {
      "filePath": "discover/music.$musicId.index.tsx",
      "parent": "/discover/music/$musicId"
    },
    "/(pairing-guide)/connect/$vendor/$pairingCode/pair": {
      "filePath": "(pairing-guide)/connect.$vendor/$pairingCode.pair.tsx",
      "children": [
        "/(pairing-guide)/connect/$vendor/$pairingCode/pair/$zoneId"
      ]
    },
    "/(pairing-guide)/sonos/connect/v2/$householdId": {
      "filePath": "(pairing-guide)/sonos.connect.v2.$householdId.tsx"
    },
    "/(pairing-guide)/sonos/pair/v2/$householdId": {
      "filePath": "(pairing-guide)/sonos.pair.v2.$householdId.tsx"
    },
    "/accounts/$accountId/addons/$addonId/activate": {
      "filePath": "accounts.$accountId/addons.$addonId.activate.tsx",
      "parent": "/accounts/$accountId"
    },
    "/accounts/$accountId/change-plan/confirmed/$plan": {
      "filePath": "accounts.$accountId/change-plan/confirmed.$plan.tsx",
      "parent": "/accounts/$accountId"
    },
    "/accounts/$accountId/messaging/campaigns/$campaignId": {
      "filePath": "accounts.$accountId/messaging/campaigns.$campaignId.tsx",
      "parent": "/accounts/$accountId/messaging"
    },
    "/accounts/$accountId/messaging/campaigns/create": {
      "filePath": "accounts.$accountId/messaging/campaigns.create.tsx",
      "parent": "/accounts/$accountId/messaging"
    },
    "/accounts/$accountId/messaging/messages/$messageId": {
      "filePath": "accounts.$accountId/messaging/messages.$messageId.tsx",
      "parent": "/accounts/$accountId/messaging/messages"
    },
    "/accounts/$accountId/messaging/messages/create": {
      "filePath": "accounts.$accountId/messaging/messages.create.tsx",
      "parent": "/accounts/$accountId/messaging/messages"
    },
    "/accounts/$accountId/settings/billing/update-billing-email": {
      "filePath": "accounts.$accountId/settings/billing/update-billing-email.tsx",
      "parent": "/accounts/$accountId/settings/billing"
    },
    "/accounts/$accountId/settings/overview/company-details": {
      "filePath": "accounts.$accountId/settings/overview.company-details.tsx",
      "parent": "/accounts/$accountId/settings/overview"
    },
    "/accounts/$accountId/settings/subscriptions/streaming": {
      "filePath": "accounts.$accountId/settings/subscriptions/streaming.tsx",
      "parent": "/accounts/$accountId/settings/subscriptions",
      "children": [
        "/accounts/$accountId/settings/subscriptions/streaming/$zoneId"
      ]
    },
    "/accounts/$accountId/settings/users/invite": {
      "filePath": "accounts.$accountId/settings/users.invite.tsx",
      "parent": "/accounts/$accountId/settings/users"
    },
    "/accounts/$accountId/your-music/playlists/$musicId": {
      "filePath": "accounts.$accountId/your-music/playlists.$musicId.tsx",
      "parent": "/accounts/$accountId/your-music"
    },
    "/accounts/$accountId/your-music/schedules/create": {
      "filePath": "accounts.$accountId/your-music/schedules.create.tsx",
      "parent": "/accounts/$accountId/your-music"
    },
    "/accounts/$accountId/your-music/schedules/edit": {
      "filePath": "accounts.$accountId/your-music/schedules.edit.tsx",
      "parent": "/accounts/$accountId/your-music",
      "children": [
        "/accounts/$accountId/your-music/schedules/edit/$scheduleId"
      ]
    },
    "/accounts/$accountId/your-music_/schedules/$scheduleId": {
      "filePath": "accounts.$accountId/your-music_.schedules.$scheduleId.tsx",
      "parent": "/accounts/$accountId"
    },
    "/accounts/$accountId/zones/$zoneId/control": {
      "filePath": "accounts.$accountId/zones/$zoneId.control.tsx",
      "parent": "/accounts/$accountId/zones/$zoneId"
    },
    "/accounts/$accountId/zones/$zoneId/player": {
      "filePath": "accounts.$accountId/zones/$zoneId.player.tsx",
      "parent": "/accounts/$accountId/zones/$zoneId",
      "children": [
        "/accounts/$accountId/zones/$zoneId/player/$action",
        "/accounts/$accountId/zones/$zoneId/player/pair/$player"
      ]
    },
    "/accounts/$accountId/zones/$zoneId/remote": {
      "filePath": "accounts.$accountId/zones/$zoneId.remote.tsx",
      "parent": "/accounts/$accountId/zones/$zoneId"
    },
    "/accounts/$accountId/zones/$zoneId/settings": {
      "filePath": "accounts.$accountId/zones/$zoneId.settings.tsx",
      "parent": "/accounts/$accountId/zones/$zoneId"
    },
    "/accounts/$accountId/zones/$zoneId/subscription": {
      "filePath": "accounts.$accountId/zones/$zoneId.subscription.tsx",
      "parent": "/accounts/$accountId/zones/$zoneId",
      "children": [
        "/accounts/$accountId/zones/$zoneId/subscription/$action"
      ]
    },
    "/accounts/$accountId/zones/create/$locationId": {
      "filePath": "accounts.$accountId/zones/create.$locationId.tsx",
      "parent": "/accounts/$accountId/zones"
    },
    "/accounts/$accountId/messaging/campaigns/": {
      "filePath": "accounts.$accountId/messaging/campaigns.index.tsx",
      "parent": "/accounts/$accountId/messaging"
    },
    "/accounts/$accountId/settings/subscriptions/": {
      "filePath": "accounts.$accountId/settings/subscriptions/index.tsx",
      "parent": "/accounts/$accountId/settings/subscriptions"
    },
    "/accounts/$accountId/your-music/playlists/": {
      "filePath": "accounts.$accountId/your-music/playlists.index.tsx",
      "parent": "/accounts/$accountId/your-music"
    },
    "/accounts/$accountId/your-music/schedules/": {
      "filePath": "accounts.$accountId/your-music/schedules.index.tsx",
      "parent": "/accounts/$accountId/your-music"
    },
    "/accounts/$accountId/zones/$zoneId/": {
      "filePath": "accounts.$accountId/zones/$zoneId.index.tsx",
      "parent": "/accounts/$accountId/zones/$zoneId"
    },
    "/accounts/$accountId/zones/create/": {
      "filePath": "accounts.$accountId/zones/create.index.tsx",
      "parent": "/accounts/$accountId/zones"
    },
    "/(pairing-guide)/connect/$vendor/$pairingCode/pair/$zoneId": {
      "filePath": "(pairing-guide)/connect.$vendor/$pairingCode.pair.$zoneId.tsx",
      "parent": "/(pairing-guide)/connect/$vendor/$pairingCode/pair"
    },
    "/accounts/$accountId/settings/billing/update-payment/$type": {
      "filePath": "accounts.$accountId/settings/billing/update-payment.$type.tsx",
      "parent": "/accounts/$accountId/settings/billing"
    },
    "/accounts/$accountId/settings/subscriptions/addons/$subscriptionId": {
      "filePath": "accounts.$accountId/settings/subscriptions/addons.$subscriptionId.tsx",
      "parent": "/accounts/$accountId/settings/subscriptions"
    },
    "/accounts/$accountId/settings/subscriptions/streaming/$zoneId": {
      "filePath": "accounts.$accountId/settings/subscriptions/streaming.$zoneId.tsx",
      "parent": "/accounts/$accountId/settings/subscriptions/streaming",
      "children": [
        "/accounts/$accountId/settings/subscriptions/streaming/$zoneId/$action"
      ]
    },
    "/accounts/$accountId/your-music/schedules/$scheduleId/copy": {
      "filePath": "accounts.$accountId/your-music/schedules.$scheduleId.copy.tsx",
      "parent": "/accounts/$accountId/your-music"
    },
    "/accounts/$accountId/your-music/schedules/$scheduleId/edit": {
      "filePath": "accounts.$accountId/your-music/schedules.$scheduleId.edit.tsx",
      "parent": "/accounts/$accountId/your-music"
    },
    "/accounts/$accountId/your-music/schedules/edit/$scheduleId": {
      "filePath": "accounts.$accountId/your-music/schedules.edit.$scheduleId.tsx",
      "parent": "/accounts/$accountId/your-music/schedules/edit"
    },
    "/accounts/$accountId/zones/$zoneId/player/$action": {
      "filePath": "accounts.$accountId/zones/$zoneId.player.$action.tsx",
      "parent": "/accounts/$accountId/zones/$zoneId/player"
    },
    "/accounts/$accountId/zones/$zoneId/subscription/$action": {
      "filePath": "accounts.$accountId/zones/$zoneId.subscription.$action.tsx",
      "parent": "/accounts/$accountId/zones/$zoneId/subscription"
    },
    "/accounts/$accountId/zones/$zoneId_/player/blocked-tracks": {
      "filePath": "accounts.$accountId/zones/$zoneId_.player.blocked-tracks.tsx",
      "parent": "/accounts/$accountId/zones"
    },
    "/accounts/$accountId/zones/$zoneId_/player/history": {
      "filePath": "accounts.$accountId/zones/$zoneId_.player.history.tsx",
      "parent": "/accounts/$accountId/zones"
    },
    "/accounts/$accountId/settings/billing/invoice-group/edit/$groupId": {
      "filePath": "accounts.$accountId/settings/billing/invoice-group.edit.$groupId.tsx",
      "parent": "/accounts/$accountId/settings/billing"
    },
    "/accounts/$accountId/settings/subscriptions/streaming/$zoneId/$action": {
      "filePath": "accounts.$accountId/settings/subscriptions/streaming.$zoneId.$action.tsx",
      "parent": "/accounts/$accountId/settings/subscriptions/streaming/$zoneId"
    },
    "/accounts/$accountId/zones/$zoneId/player/pair/$player": {
      "filePath": "accounts.$accountId/zones/$zoneId.player.pair.$player.tsx",
      "parent": "/accounts/$accountId/zones/$zoneId/player"
    }
  }
}
ROUTE_MANIFEST_END */
