/**
 * Relay connection `pageInfo` object.
 *
 * @group Typescript
 * @private
 */
export type RelayPageInfo = {
  hasPreviousPage: boolean
  hasNextPage: boolean
  startCursor: string
  endCursor: string
}

/**
 * Resolves a fragment type from a union of fragments.
 *
 * @param Union - The union of fragments.
 * @param Typename - Typename for the fragment to resolve.
 * @group Typescript
 * @example
 * ```ts
 * type Union = { __typename: 'A', a: string } | { __typename: 'B', b: string }
 * type A = FragmentOf<Union, 'A'>
 * // => { __typename: 'A', a: string }
 * ```
 */
export type FragmentOf<
  Union extends { __typename: string },
  Typename extends Union['__typename'],
> = Extract<Union, { __typename: Typename }>

/**
 * Casts the provided object (which is expected to be a discriminated union)
 * into a merged type with all possible fields from each distinct union type
 * using {@link UnionMerge}.
 *
 * @group Typescript
 * @example
 * ```ts
 * const source: { __typename: 'A', a: string } | { __typename: 'B', b: string } = ...
 * const union = unionMerge(source)
 * const value = union.a || union.b
 * ```
 */
export function unionMerge<T extends object>(input: T): UnionMerge<T> {
  return input as any
}

/**
 * Merges a discriminated union into a single type with all possible fields.
 * Fields not present in all types are made optional.
 *
 * Use {@link unionMerge} to easily apply this type cast.
 *
 * @group Typescript
 * @example
 * ```ts
 * type A = { __typename: 'A', a: string }
 * type B = { __typename: 'B', b: string }
 * type Merged = UnionMerge<A | B>
 * // => { __typename: 'A' | 'B', a?: string, b?: string }
 * ```
 */
export type UnionMerge<T extends object> = {
  [k in keyof T]: PickTypeOf<T, k>
} & {
  [k in NonCommonKeys<T>]?: PickTypeOf<T, k>
}

type PickTypeOf<T, K extends string | number | symbol> = K extends AllKeys<T>
  ? PickType<T, K>
  : never
type PickType<T, K extends AllKeys<T>> = T extends { [k in K]?: any }
  ? T[K]
  : undefined
type Subtract<A, C> = A extends C ? never : A
type NonCommonKeys<T extends object> = Subtract<AllKeys<T>, keyof T>
type AllKeys<T> = T extends any ? keyof T : never

/**
 * Recursively removes the `__typename` property from objects and arrays.
 *
 * Adapted from {@link https://stackoverflow.com/a/55539616/1527562 this SO answer}.
 *
 * @group Typescript
 * @example
 * ```ts
 * type Input = {
 *     __typename: 'Root'
 *     id: string
 *     nested?: { __typename: 'Nested'; id: string }
 *     ary: { __typename: 'Array'; id: string }[]
 * }
 * type Result = OmitTypename<Input>
 * type Result = {
 *     id: string
 *     nested?: { id: string }
 *     ary: { id: string }[]
 * }
 * ```
 */
export type OmitTypename<T> = T extends Primitive
  ? T
  : T extends any[]
    ? OmitTypenameArray<T>
    : OmitTypenameHelper<T>

type OmitTypenameHelper<T> = {
  [P in keyof Pick<T, Exclude<keyof T, '__typename'>>]: T[P] extends infer TP
    ? TP extends Primitive
      ? TP
      : TP extends any[]
        ? OmitTypenameArray<TP>
        : OmitTypename<TP>
    : never
}
type OmitTypenameArray<T extends any[]> = {
  [P in keyof T]: OmitTypename<T[P]>
}

/**
 * Union of all possible primitive value types.
 *
 * @group Typescript
 * @private
 */
export type Primitive =
  | string
  | Function
  | number
  | boolean
  | Symbol
  | undefined
  | null

/** Mirror of packages/utils/src/log.ts API */
export interface Logger {
  debug(message?: any, ...optionalParams: any[]): void
  info(message?: any, ...optionalParams: any[]): void
  // notice(message?: any, ...optionalParams: any[]): void
  warn(message?: any, ...optionalParams: any[]): void
  error(message?: any, ...optionalParams: any[]): void
}
