import type { Falsy } from '@soundtrack/utils/typePredicates'
import { type VirtualItem, useVirtualizer } from '@tanstack/react-virtual'
import { type RefObject, useCallback, useRef, useState } from 'react'
// eslint-disable-next-line no-restricted-imports
import {
  type DragItem,
  type DropTarget,
  ListDropTargetDelegate,
  ListKeyboardDelegate,
  useDraggableCollection,
  useDroppableCollection,
  useGridList,
} from 'react-aria'
// eslint-disable-next-line no-restricted-imports
import {
  type DroppableCollectionStateOptions,
  type Key,
  type ListProps,
  type ListState,
  useDraggableCollectionState,
  useDroppableCollectionState,
  useListState,
} from 'react-stately'
import { findScrollParent } from '#app/lib/scroll'
import { useDragContext } from '../../context'
import { getDefaultDragData } from '../drag/default-drag-data'
import type { DragOptions, DropOptions } from '../drag/types'
import { getDragData, getDragTypes } from '../drag/utils'
import type { DNDItem } from '../schema'

type UseListReturn<T, U extends HTMLElement> = {
  state: ListState<T>
  ref: RefObject<U>
  gridProps: React.HTMLAttributes<HTMLElement>
}

export function useList<T extends object, U extends HTMLElement>({
  items,
  selectionMode = 'none',
  children,
  isVirtualized = false,
  ...props
}: { isVirtualized?: boolean } & Pick<
  ListProps<T>,
  'selectionMode' | 'children' | 'onSelectionChange' | 'items'
>): UseListReturn<T, U> {
  const state = useListState({
    selectionMode,
    items,
    children,
    onSelectionChange: props.onSelectionChange,
    // Using 'replace' selection behavior. Default behavior is 'toggle' which behaves like a checkbox group,
    // where each clicked item adds to the selection.
    // https://react-spectrum.adobe.com/react-aria/useGridList.html#selection-behavior
    selectionBehavior: 'replace',
  })

  const ref = useRef<U>(null)
  const { gridProps } = useGridList(
    {
      isVirtualized,
      items,
      'aria-label': props['aria-label'],
    },
    state,
    ref,
  )

  return { state, ref, gridProps }
}

export function useDroppable<T>({
  dropOptions,
  ref,
  state,
  isVirtualized,
  virtualRowSize,
}: {
  dropOptions: DropOptions
  ref: RefObject<HTMLElement>
  state: ListState<T>
  isVirtualized?: boolean
  virtualRowSize?: number
}) {
  // Default drop operation logic.
  const getDropOperation: DroppableCollectionStateOptions['getDropOperation'] =
    (target, types, allowedOperations) => {
      if (
        !dropOptions.enabled &&
        'dropPosition' in target &&
        target.dropPosition !== 'on'
      ) {
        return 'cancel'
      }

      if (dropOptions.getDropOperation) {
        const operation = dropOptions.getDropOperation(
          target,
          types,
          allowedOperations,
        )
        if (operation) {
          return operation
        }
      }

      if (allowedOperations.includes('move')) {
        return 'move'
      }

      if (allowedOperations.includes('copy')) {
        return 'copy'
      }

      return 'cancel'
    }

  const dropState = useDroppableCollectionState({
    ...dropOptions,
    ...state,
    isDisabled: !dropOptions.enabled,
    getDropOperation,
  })

  const dropTargetDelegate = new ListDropTargetDelegate(state.collection, ref)

  if (isVirtualized && virtualRowSize) {
    /**
     * Overriding this method when using virtualized rendering to get the correct drop
     * position. The built in implementation is based on the actually rendered items and
     * not the virtualized list items.
     *
     * Assumption: The list is displayed vertically.
     **/
    dropTargetDelegate.getDropTargetFromPoint = (
      x: number,
      y: number,
      isValidDropTarget: (target: DropTarget) => boolean,
    ) => {
      const index = Math.floor(y / virtualRowSize)
      const closest = Math.round(y / virtualRowSize)
      const dropPosition = closest > index ? 'after' : 'before'
      const item = state.collection.at(index)

      if (
        !item ||
        !isValidDropTarget({ type: 'item', key: item.key, dropPosition })
      ) {
        return { type: 'root' }
      }

      return {
        type: 'item',
        key: item.key,
        dropPosition,
      }
    }
  }

  const { collectionProps } = useDroppableCollection(
    {
      ...dropOptions,
      // Provide drop targets for keyboard and pointer-based drag and drop.
      keyboardDelegate: new ListKeyboardDelegate(
        state.collection,
        state.disabledKeys,
        ref,
      ),
      dropTargetDelegate,
      getDropOperation,
    },
    dropState,
    ref,
  )

  return {
    dropState,
    collectionProps,
  }
}

export function useDraggable<T extends object>({
  state,
  ref,
  dragOptions,
}: {
  state: ListState<T>
  ref: RefObject<HTMLElement>
  dragOptions: DragOptions
}) {
  const { startDrag, previewRef: preview } = useDragContext()

  const getItems = (keys: Set<Key>): DragItem[] =>
    [...keys].map((key) => {
      const item = state.collection.getItem(key)

      // If the value is wrapped in a node property, use that as the node.
      const node = (
        item?.value && 'node' in item.value ? item.value.node : item?.value
      ) as DNDItem | Falsy

      const dragData = node ? getDefaultDragData(node) : null

      if (!dragData || !item) {
        return {
          'text/plain': key.toString(),
        } as DragItem
      }

      return getDragData(dragData, item.textValue)
    })

  // Setup drag state for the collection.
  const dragState = useDraggableCollectionState({
    ...dragOptions,
    ...state,
    isDisabled: !dragOptions.enabled,
    onDragStart: (e) => {
      const items = getItems(e.keys)
      startDrag?.(getDragTypes(items))
      dragOptions.onDragStart?.(e)
    },
    getItems,
    preview,
  })

  useDraggableCollection({}, dragState, ref)

  return { dragState }
}

interface TrackListBodyVirtualizerProps {
  ref: React.RefObject<HTMLDivElement>
  count: number
  estimatedSize: number
  overscan?: number
}

/**
 * Hook used to set up row virtualization, as well as generating the necessary
 * props (styles + ref) for the wrapping body & row elements.
 */
export function useListVirtualizer({
  ref,
  count,
  estimatedSize,
  overscan = 5,
}: TrackListBodyVirtualizerProps) {
  const [rowSize, setRowSize] = useState(estimatedSize)

  const virtualizer = useVirtualizer({
    overscan,
    count,
    getScrollElement: () => findScrollParent(ref.current),
    estimateSize: useCallback(() => rowSize, [rowSize]),
    scrollMargin: ref.current?.offsetTop,
  })

  // Calculate row size from first item only
  const sizedElementRef = useRef<HTMLDivElement | null>(null)
  const estimateSizeRef = useCallback((element: HTMLDivElement | null) => {
    sizedElementRef.current = element
    if (!element) {
      return
    }
    setRowSize(element.getBoundingClientRect()['height'] + 1)
  }, [])

  return {
    virtualizer,
    estimateSizeRef,
    rowSize,
    bodyProps: {
      style: {
        position: 'relative' as const,
        height: virtualizer.getTotalSize() + 'px',
      },
    },
    rowProps: (virtualRow: VirtualItem, offset: number) => ({
      ref: offset === 0 ? estimateSizeRef : undefined,
      style: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        transform: `translateY(${virtualRow.start - virtualizer.options.scrollMargin}px)`,
      },
    }),
  }
}
