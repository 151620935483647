import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
import { modifier } from './lib/modifier';
const TYPES = [
    'navigation',
    'fixed',
    'header',
    'tight',
    'no-bottom',
    'scrolled',
    'padded',
];
const WIDTHS = [
    false,
    'normal',
    'wide',
    'medium',
    'thin',
    'thinner',
    'standalone',
];
/**
 * <Section>
 * = section > .layout-row.normal-element
 *
 * <Section width="medium">
 * = section > .layout-row.medium-element
 *
 * <Section row>
 * = section > .layout-row.normal-element > .row
 *
 * <Section row="collapse">
 * = section > .layout-row.normal-element > .row.collapse
 *
 * <Section type="navigation" className="custom-class">
 * = section.section--navigation.custom-class > .layout-row.normal-element
 *
 * <Section layout="relative">
 * = section > .layout-row.normal-element.relative
 */
export const Section = React.forwardRef(function Section({ type, row, layout, width = 'normal', className, innerContainerClassName, children, ...rest }, ref) {
    const content = row ? (_jsx(Row, { className: typeof row === 'string' ? row : undefined, children: children })) : (children);
    const innerCx = layout || width || innerContainerClassName
        ? classNames([
            'layout-row',
            layout,
            width && `${width}-element`,
            innerContainerClassName,
        ])
        : null;
    return (_jsx("section", { ref: ref, className: classNames(className, type && modifier('section', ...type.split(' '))), ...rest, children: innerCx ? _jsx("div", { className: innerCx, children: content }) : content }));
});
function Row({ className, children, }) {
    return _jsx("div", { className: classNames('row', className), children: children });
}
Section.propTypes = {
    type(props, propName, componentName) {
        // @ts-ignore
        const stringError = PropTypes.string.apply(this, arguments);
        if (stringError || !props[propName]) {
            return stringError;
        }
        return props[propName].split(' ').reduce((err, t) => {
            return err || TYPES.indexOf(t) >= 0
                ? null
                : new Error(`Unsupported value '${t}' for prop 'type' in '${componentName}', supported types are: ${TYPES.join(', ')}.`);
        }, null);
    },
    row: PropTypes.any,
    layout: PropTypes.any,
    width: PropTypes.any,
};
