import { debounce } from '@soundtrack/utils/debounce'
import { expandPlaceholderUri } from '@soundtrack/utils/display'
import { scope } from '@soundtrack/utils/log'
import { onlyHasKey } from '@soundtrack/utils/typePredicates'
import type {
  DeepOmit,
  DeepPartial,
  Mutable,
  Optional,
} from '@soundtrack/utils/types'
import { unionMerge } from '@soundtrackyourbrand/apollo-client'
import { getIn } from '@soundtrackyourbrand/object-utils.js'
import * as im from 'immutable'
import type {
  Display,
  PlaybackDisplayFragment,
  PlaybackOrder,
  PlaylistSourceFragment,
  Reason,
  ScheduleSourceFragment,
  TrackInfoFragment,
} from '../graphql/graphql.js'
import { graphql } from '../graphql/index.js'
import { COMPOSER_SUBTYPE_MAP } from './composers.js'
import type {
  OptimisticState,
  OptimisticUpdater,
  PlaybackSlice,
  State as PlaybackState,
  Source,
  Track,
} from './slice.js'
import type { RootState, Store } from './storeUtils.js'
import { calculateProgress } from './trackProgress.js'
import type * as Tyson from './tyson/index.js'

const log = scope('playback')

export interface BasePlaybackConfig {
  store: Store
  playbackSlice: PlaybackSlice

  /** Mixpanel tracking function */
  track(
    event: string,
    props: Record<string, string | number | boolean | undefined>,
  ): void
}

export interface BasePlaybackEvents {
  /**
   * Called when a user with an expired or never activated subscription
   * attempts to assign a source
   */
  onAssignSourceWithoutLicenseError(
    playback: Playback,
    input: AssignSourceInput,
  ): void
  /**
   * Called when an essential user is attempting to assign a source they
   * don't have a license to play (e.g. a manual playlist)
   */
  onAssignInvalidSourceError(playback: Playback, input: AssignSourceInput): void
  /**
   * Called when an essential user is attempting to assign a source staring
   * at a specific track
   */
  onAssignSourceWithTrackOnEssentialError(
    playback: Playback,
    input: AssignSourceInput,
  ): void
  /**
   * Called on any other assign source error
   */
  onAssignSourceError(
    playback: Playback,
    input: AssignSourceInput,
    error: Error,
  ): void
  /**
   * Called when a source has been successfully assigned
   */
  onAssignSourceSuccess?(playback: Playback, input: AssignSourceInput): void

  /**
   * Called when an essential user is attempting to queue tracks
   */
  onQueueTracksOnEssentialError(
    playback: Playback,
    input: QueueTracksInput,
  ): void
  /**
   * Called on any other assign source error
   */
  onQueueTracksError(
    playback: Playback,
    input: QueueTracksInput,
    error: Error,
  ): void
}

// RadioSource is still used for schedules in business
export type RadioSource = im.Map<string, any>
export type GraphqlPlaylist = Optional<
  PlaylistSourceFragment,
  'presets' | 'trackStatistics'
>
export type GraphqlSchedule = ScheduleSourceFragment
export type AnySource = GraphqlPlaylist | GraphqlSchedule | Source

export type GraphqlTrack =
  | TrackInfoFragment
  | DeepOmit<TrackInfoFragment, '__typename'>
export type AnyTrack = Tyson.Sequencing.Track | GraphqlTrack | Track

export type SkipTrackInput = {
  /**
   * default respects the zone setting `crossfade_on_skip`.
   * alternative is the reverse of the default.
   */
  transition?: 'default' | 'default-reverse' | 'immediate' | 'crossfade'
  tracksToSkip?: number
  trackingProperties: Record<string, any>
}

export type BlockTrackInput = {
  trackId: string
  reasons: Reason[]
  sourceId: string
  trackingProperties: Record<string, any>
}

export type AssignSourceInput = {
  source: AnySource
  immediate?: boolean
  keepQueue?: boolean
  trackingMeta?: { eventName?: string; [prop: string]: any }
  trackIndex?: number
  /** Track object or id. Prefer track object if possible */
  track?: AnyTrack | string
}

export type QueueTracksInput = {
  /** Track objects or ids to queue. Prefer track objects if possible */
  tracks: Array<AnyTrack | string>
  immediate?: boolean
  /** Replace existing queue with the new tracks (otherwise they are queued after) */
  clearQueue?: boolean
  trackingMeta?: { eventName: string; [prop: string]: any }
}

export type AssignSourceImplInput = {
  source: GraphqlPlaylist | GraphqlSchedule | Source
  immediate?: boolean
  keepQueue?: boolean
  trackIndex?: number
  track?: null | string
}

export type QueueTracksImplInput = {
  tracks: Array<string>
  immediate?: boolean
  /** Replace existing queue with the new tracks (otherwise they are queued after) */
  clearQueue?: boolean
}

export type SkipTrackImplInput = {
  crossfade: boolean
  tracksToSkip: number
}

type StoreSelectors = PlaybackSlice['selectors']
export type Selectors = {
  [K in keyof StoreSelectors]: StoreSelectors[K] extends (
    playerId: string,
    state: RootState,
  ) => infer R
    ? R
    : never
}

export abstract class Playback {
  #selectors: Selectors | undefined
  #trackVolume: BasePlaybackConfig['track']

  constructor(
    public readonly playerId: string,
    protected config: BasePlaybackConfig,
    protected events: BasePlaybackEvents,
  ) {
    this.#trackVolume = debounce(config.track, 500)
  }

  protected get store() {
    return this.config.store
  }
  protected get playbackSlice() {
    return this.config.playbackSlice
  }
  protected get track() {
    return this.config.track
  }

  get selectors(): Selectors {
    if (this.#selectors) return this.#selectors
    const selectors = this.playbackSlice.selectors
    this.#selectors = Object.fromEntries(
      Object.entries(selectors).map(([key, selector]) =>
        selector.length >= 2
          ? [key, (state: RootState) => (selector as any)(this.playerId, state)]
          : [key, selector],
      ),
    ) as any
    return this.#selectors!
  }

  async play(trackingProperties: Record<string, any>): Promise<void> {
    await this.setPlaybackStatus('playing')
    this.track('Control - Resume Music', {
      ...trackingProperties,
    })
  }
  async pause(trackingProperties: Record<string, any>): Promise<void> {
    await this.setPlaybackStatus('paused')
    this.track('Control - Pause Music', {
      ...trackingProperties,
    })
  }
  async playPause(trackingProperties: Record<string, any>): Promise<void> {
    if (this.selectors.status(this.store.getState()) === 'playing') {
      await this.pause(trackingProperties)
    } else {
      await this.play(trackingProperties)
    }
  }
  async skip(input: SkipTrackInput): Promise<void> {
    // TODO: Fetch sound zone settings
    // const soundZone = this.selectors.zone(this.store.getState())?.settings
    const soundZoneSettings = {
      crossfade: true,
      crossfade_on_skip: false,
    }
    const crossfadeOnSkip =
      soundZoneSettings?.crossfade && soundZoneSettings?.crossfade_on_skip
    const crossfade =
      input?.transition === 'crossfade'
        ? true
        : input?.transition === 'immediate'
          ? false
          : input?.transition === 'default-reverse'
            ? !crossfadeOnSkip
            : !!crossfadeOnSkip
    const tracksToSkip = input?.tracksToSkip ?? 1
    const upcoming = this.selectors.upcoming(this.store.getState())
    const nextTrack = upcoming?.[tracksToSkip - 1] ?? null
    const newUpcoming = nextTrack
      ? (upcoming?.slice(tracksToSkip) ?? null)
      : upcoming
    await this.store.dispatch(
      this.playbackSlice.actions.optimisticUpdate(
        this.skipImpl({ crossfade, tracksToSkip }),
        {
          status: 'playing',
          trackId: this.forCurrentTrack(
            'trackId',
            nextTrack?.track?.id ?? null,
          ),
          track: this.forCurrentTrack('track', nextTrack?.track ?? null),
          progress: this.forCurrentTrack('progress', null),
          current: this.forCurrentTrack('current', nextTrack),
          upcoming: (state) =>
            state.upcoming?.[tracksToSkip - 1]?.id === nextTrack?.id
              ? newUpcoming
              : state.upcoming,
        },
        this.playerId,
      ),
    )
    this.track('Control - Skip Track', {
      ...input?.trackingProperties,
      ...(crossfade ? { Crossfade: 'true' } : {}),
    })
  }

  async setPlayOrder(
    playOrder: PlaybackOrder,
    trackingProperties: Record<string, any>,
  ): Promise<void> {
    await this.store.dispatch(
      this.playbackSlice.actions.optimisticUpdate(
        this.setPlayOrderImpl(playOrder),
        {
          playOrder,
        },
        this.playerId,
      ),
    )
    this.track('Control - Shuffle', {
      ...trackingProperties,
      Shuffle: playOrder === 'SHUFFLE',
    })
  }

  async clearQueue(trackingProperties: Record<string, any>): Promise<void> {
    const upcoming = this.selectors.upcoming(this.store.getState())
    const emptyQueue = upcoming?.filter((e) => !e.fromQueue) ?? null
    await this.store.dispatch(
      this.playbackSlice.actions.optimisticUpdate(
        this.clearQueueImpl(),
        {
          upcoming: (state) =>
            state.upcoming === upcoming ? emptyQueue : state.upcoming,
        },
        this.playerId,
      ),
    )
    this.track('Control - Clear Queue', trackingProperties)
  }

  abstract get volumeResolution(): number
  async setVolume(
    volume: number,
    trackingProperties: false | Record<string, any>,
  ): Promise<void> {
    volume = Math.min(Math.max(volume, 0), this.volumeResolution)
    const updatesToReplace = this.selectors
      .rawPlaybackState(this.store.getState())
      .optimisticUpdates.filter(
        (u) => u.state.volume !== undefined && onlyHasKey(u.state, 'volume'),
      )
      .map((u) => u.id)
    await this.store.dispatch(
      this.playbackSlice.actions.optimisticUpdate(
        this.setVolumeImpl(volume),
        { volume },
        this.playerId,
        { updatesToReplace },
      ),
    )
    if (trackingProperties) {
      this.#trackVolume('Control - Set Volume', {
        ...trackingProperties,
        Volume: volume,
      })
    }
  }

  async blockTrack(input: BlockTrackInput): Promise<void> {
    const currentTrack = this.selectors.trackId(this.store.getState())
    const upcoming = this.selectors.upcoming(this.store.getState())
    let filteredUpcoming = upcoming?.filter((e) => e.track.id !== input.trackId)
    const action = this.blockTrackImpl(input)

    if (input.trackId === currentTrack) {
      // If we were listening on the track, skip it
      const nextTrack = filteredUpcoming?.[0] ?? null
      filteredUpcoming = filteredUpcoming?.slice(1)
      await this.store.dispatch(
        this.playbackSlice.actions.optimisticUpdate(
          action,
          {
            trackId: this.forCurrentTrack(
              'trackId',
              nextTrack?.track?.id ?? null,
            ),
            track: this.forCurrentTrack('track', nextTrack?.track ?? null),
            progress: this.forCurrentTrack('progress', null),
            current: this.forCurrentTrack('current', nextTrack),
            upcoming: (state) =>
              state.upcoming?.[0]?.id === nextTrack?.id
                ? (filteredUpcoming ?? null)
                : state.upcoming,
          },
          this.playerId,
        ),
      )
    } else if (
      filteredUpcoming &&
      filteredUpcoming.length !== upcoming?.length
    ) {
      // If it were in the queue, remove it
      await this.store.dispatch(
        this.playbackSlice.actions.optimisticUpdate(
          action,
          {
            upcoming: (state) =>
              state.upcoming?.some((e) => e.id === input.trackId)
                ? (filteredUpcoming ?? null)
                : state.upcoming,
          },
          this.playerId,
        ),
      )
    } else {
      // Otherwise, no state updates are needed
      await action
    }
    this.track('Control - Block Track', {
      ...input.trackingProperties,
      'Track ID': input.trackId,
      'Music ID': input.sourceId,
    })
  }

  async unblockTrack(
    trackId: string,
    trackingProperties: Record<string, any>,
  ): Promise<void> {
    await this.unblockTrackImpl(trackId)
    this.track('Control - Unblock Track', {
      ...trackingProperties,
      'Track ID': trackId,
    })
  }

  async assignSource(input: AssignSourceInput) {
    const isUnlimited = this.selectors.isUnlimited(this.store.getState())
    // Optimistically default to true if unknown, to avoid upsetting any paying customers in edge cases
    const canPlayMusic =
      this.selectors.canPlayMusic(this.store.getState()) ?? true
    const source = unionMerge(
      im.isMap(input.source)
        ? convertSource(input.source as any)
        : input.source,
    )
    if (!canPlayMusic) {
      this.events.onAssignSourceWithoutLicenseError(this, input)
      return false
    }

    // Don't assign manual playlists if insufficient tier level and not curated
    if (
      !isUnlimited &&
      source.__typename === 'Playlist' &&
      (!source.curated || source.type === 'playlist') &&
      source.composerType === 'manual'
    ) {
      this.events.onAssignInvalidSourceError(this, input)
      return false
    }

    // Accounts below T3 can never play from a specific track
    if (!isUnlimited && (input.trackIndex != null || input.track)) {
      this.events.onAssignSourceWithTrackOnEssentialError(this, input)
      return
    }

    const track =
      input.track && typeof input.track !== 'string'
        ? convertTrack(input.track)
        : null
    const trackId =
      track?.id ?? (typeof input.track === 'string' ? input.track : null)
    const sourceType =
      source.__typename === 'Schedule'
        ? 'schedule'
        : (source.presentAs ?? source.type)

    const isAlreadyActiveSource =
      this.selectors.sourceId(this.store.getState()) === source.id
    if (input.keepQueue === undefined) {
      input = { ...input, keepQueue: isAlreadyActiveSource }
    }

    const action = this.assignSourceImpl({
      ...input,
      source,
      track: trackId,
    })
    if (input.immediate) {
      const newSource: Source = {
        ...source,
        type: sourceType,
        display: source.display && convertDisplay(source.display),
      } as const
      const optimisticUpdate: Mutable<OptimisticState> = {
        status: 'playing',
      }
      // Skip applying optimistic updates if the source is already active
      // since depending on the source type and the collections play order
      // Tyson may choose to do nothing, making us stuck on the optimistic
      // state until timeout.
      if (track || !isAlreadyActiveSource) {
        optimisticUpdate.trackId = this.forCurrentTrack('trackId', trackId)
        optimisticUpdate.track = this.forCurrentTrack('track', track)

        optimisticUpdate.progress = this.forCurrentTrack('progress', null)
      }
      if (!isAlreadyActiveSource) {
        optimisticUpdate.source = (state) =>
          state.source?.id === source.id ? state.source : newSource
        optimisticUpdate.nextSlotStartsAt = 'unknown'
      }
      this.store.dispatch(
        this.playbackSlice.actions.optimisticUpdate(
          action,
          optimisticUpdate,
          this.playerId,
        ),
      )
    }
    try {
      await action

      this.events.onAssignSourceSuccess?.(this, input)
      const {
        eventName = `Music - Assign To Zones${input.immediate ? ' Now' : ''}`,
        ...trackingProperties
      } = input.trackingMeta ?? {}
      this.track(eventName, {
        'Music ID': source.id,
        'Music Name': source.name,
        'Music Type': sourceType,
        'Music Subtype': COMPOSER_SUBTYPE_MAP[source.composerType!],
        'Music Composer Type': source.composerType,
        ...trackingProperties,
      })
    } catch (error) {
      log.error('Assign source error', error)
      this.events.onAssignSourceError(this, input, error as Error)
    }
  }

  async queueTracks(input: QueueTracksInput) {
    const isUnlimited = this.selectors.isUnlimited(this.store.getState())

    if (!isUnlimited) {
      log.warn('Essential users cannot queue tracks')
      this.events.onQueueTracksOnEssentialError(this, input)
      return
    }

    const tracks = input.tracks.map((track) =>
      typeof track === 'string' ? track : track,
    )

    const action = this.queueTracksImpl({
      ...input,
      tracks: tracks.map((track) =>
        typeof track === 'string' ? track : (getIn(track, 'id') as string),
      ),
    })
    if (input.immediate) {
      const track = tracks[0]
      this.store.dispatch(
        this.playbackSlice.actions.optimisticUpdate(
          action,
          {
            status: 'playing',
            trackId: typeof track === 'string' ? track : getIn(track, ['id']),
            track:
              typeof track === 'string'
                ? (state) => (state.track?.id === track ? state.track : null)
                : (convertTrack(track) ?? null),
            progress: this.forCurrentTrack('progress', null),
          },
          this.playerId,
        ),
      )
    }
    try {
      await action
      if (input.trackingMeta) {
        const { eventName, ...trackingProperties } = input.trackingMeta
        this.track(eventName, trackingProperties)
      }
    } catch (error) {
      log.error('Queue tracks error', error)
      this.events.onQueueTracksError(this, input, error as Error)
    }
  }

  prevVolume: number | null = null
  async toggleMute(trackingProperties: Record<string, any>) {
    const volume = this.selectors.volume(this.store.getState())
    if (volume === 0) {
      const toVolume = this.prevVolume ?? 10
      this.setVolume(toVolume, false)
      this.track('Control - Set Volume Mute', {
        ...trackingProperties,
        Volume: toVolume,
        'Volume Mute': 'Off',
      })
      this.prevVolume = null
    } else {
      this.prevVolume = volume
      this.setVolume(0, false)
      this.track('Control - Set Volume Mute', {
        ...trackingProperties,
        Volume: 0,
        'Volume Mute': 'On',
      })
    }
  }

  private setPlaybackStatus(newStatus: 'playing' | 'paused'): Promise<void> {
    const currentState = this.playbackSlice.selectors.playbackState(
      this.playerId,
      this.store.getState(),
    )
    const position = calculateProgress(currentState)
    const newProgress: PlaybackState['progress'] = position.positionMs
      ? {
          updatedAt: new Date().toISOString(),
          receivedAt: new Date().toISOString(),
          progressMs: position.positionMs,
        }
      : null
    return this.store.dispatch(
      this.playbackSlice.actions.optimisticUpdate(
        this[newStatus === 'paused' ? 'pauseImpl' : 'playImpl'](),
        {
          status: newStatus,
          progress: (state) =>
            state.progress === currentState.progress
              ? newProgress
              : state.progress,
        },
        this.playerId,
      ),
    )
  }

  /**
   * Optimistic override a field, valid only until the track changes
   * Replaces all other active optimistic overrides for the same field
   */
  private forCurrentTrack<
    K extends Exclude<keyof PlaybackState, 'optimisticUpdates'>,
  >(field: K, value: PlaybackState[K]): OptimisticUpdater<PlaybackState[K]> {
    type OptimisticUpdaterForTrack<T> = ((current: PlaybackState) => T) & {
      timelineId: string
    }
    const current = this.selectors.currentEntry(this.store.getState())
    const optimisticUpdatesToReplace = this.playbackSlice.selectors
      .rawPlaybackState(this.playerId, this.store.getState())
      .optimisticUpdates.map((update) => {
        const updater = update.state[field]
        if (typeof updater === 'function' && 'timelineId' in updater) {
          return (updater as OptimisticUpdaterForTrack<PlaybackState[K]>)
            .timelineId
        }
        return undefined
      })
      .filter(Boolean)
    return Object.assign(
      (state: PlaybackState) =>
        state.current?.id === current?.id ||
        optimisticUpdatesToReplace.includes(state.current?.id)
          ? value
          : state[field],
      { timelineId: current?.id ?? null },
    )
  }

  protected abstract playImpl(): Promise<void>
  protected abstract pauseImpl(): Promise<void>
  protected abstract skipImpl(input: SkipTrackImplInput): Promise<void>
  protected abstract setPlayOrderImpl(playOrder: PlaybackOrder): Promise<void>
  protected abstract clearQueueImpl(): Promise<void>
  protected abstract setVolumeImpl(volume: number): Promise<void>
  protected abstract blockTrackImpl(input: BlockTrackInput): Promise<void>
  protected abstract unblockTrackImpl(trackId: string): Promise<void>
  protected abstract assignSourceImpl(
    input: AssignSourceImplInput,
  ): Promise<void>
  protected abstract queueTracksImpl(input: QueueTracksImplInput): Promise<void>

  abstract init(): void
  abstract dispose(): void
}

export function convertSource(
  source: RadioSource,
): (GraphqlPlaylist & { curated?: boolean }) | GraphqlSchedule {
  const { type } = sourceInfo(source)
  if (type === 'schedule') {
    return {
      __typename: 'Schedule',
      id: getIn(source, ['id']),
      name: getIn(source, ['name']),
      composerType:
        getIn(source, ['composer']) ?? getIn(source, ['composerType']),
    } satisfies GraphqlSchedule
  } else {
    const playbackMode =
      getIn(source, ['presets', 'playbackMode']) ??
      getIn(source, ['play_order'])
    return {
      __typename: 'Playlist',
      id: getIn(source, ['id']),
      name: getIn(source, ['name']),
      snapshot: getIn(source, ['snapshot']),
      presentAs: type ?? 'playlist',
      composerType:
        getIn(source, ['composer']) ?? getIn(source, ['composerType']),
      curated: getIn(source, ['curated']),
      presets: playbackMode
        ? {
            __typename: 'Presets',
            playbackMode,
          }
        : undefined,
    } satisfies GraphqlPlaylist
  }
}

export function convertDisplay(
  display: DeepPartial<PlaybackDisplayFragment & Display>,
) {
  return display?.image?.thumbnail
    ? {
        colors: display.palette?.backgroundPrimary?.hex
          ? {
              primary: {
                hex: display.palette.backgroundPrimary?.hex,
              },
            }
          : display.colors && {
              primary: display.colors.primary?.hex
                ? { hex: display.colors.primary.hex }
                : null,
            },
        image: {
          thumbnail:
            display.image.thumbnail ??
            expandPlaceholderUri(display.image.placeholder, 150),

          hero:
            display.image.hero ??
            expandPlaceholderUri(display.image.placeholder, 1200) ??
            display.image.thumbnail,
        },
      }
    : null
}

/** Normalizes any type of Track (Radio, HistoryTrack, GraphQL, Tyson) */
function convertTrack(track: AnyTrack): Track {
  const thumbnail =
    getIn(track, ['album', 'display', 'image', 'thumbnail']) ?? // TrackInfoFragment
    getIn(track, ['album', 'display', 'image', 'sizes', 'thumbnail']) ?? // GraphQL
    getIn(track, ['album', 'display', 'image', 'sizes', 'tysonUrl']) // Tyson

  // Intentionally skipping radio or history track colors since those wont
  // match the GraphQL colors
  const primaryColor = getIn(track, [
    'album',
    'display',
    'colors',
    'primary',
    'hex',
  ])
  const secondaryColor = getIn(track, [
    'album',
    'display',
    'colors',
    'secondary',
    'hex',
  ])

  return {
    id: track.id,
    title: track.title ?? '',
    durationMs:
      track.durationMs ?? // GraphQL
      (getIn(track, ['properties', 'duration']) // Tyson
        ? (getIn(track, ['properties', 'duration']) as number) / 1e6
        : null),
    album: {
      id: getIn(track, ['album', 'id']) as string,
      title: track.album?.title,
      display: {
        colors: {
          primary: primaryColor ? { hex: primaryColor } : null,
          secondary: secondaryColor ? { hex: secondaryColor } : null,
        },
        image: thumbnail
          ? {
              thumbnail,
              hero:
                getIn(track, ['album', 'display', 'image', 'hero']) ?? // Track & TrackInfoFragment
                getIn(track, ['album', 'display', 'image', 'size']) ?? // GraphQL custom size
                thumbnail,
            }
          : null,
      },
    },
    artists: track.artists,
  }
}

graphql(/* GraphQL */ `
  fragment PlaylistSource on Playlist {
    id
    name
    snapshot
    presentAs
    composerType
    curated
    presets {
      playbackMode
    }
    trackStatistics(market: $market) {
      playable
    }
    display {
      ...PlaybackDisplay
    }
  }
`)

graphql(/* GraphQL */ `
  fragment ScheduleSource on Schedule {
    id
    name
    composerType
    display {
      ...PlaybackDisplay
    }
  }
`)

graphql(/* GraphQL */ `
  fragment TrackInfo on Track {
    id
    title
    durationMs
    explicit
    isAvailable(market: $market)
    album {
      id
      title
      display {
        ...PlaybackDisplay
      }
    }
    artists {
      id
      name
    }
  }
`)

graphql(/* GraphQL */ `
  fragment PlaybackDisplay on Display {
    image {
      hero: size(height: 1200, width: 1200)
      thumbnail: size(height: 150, width: 150)
    }
    colors {
      primary {
        hex
      }
    }
    palette(theme: DarkTheme) {
      backgroundPrimary {
        hex
      }
    }
  }
`)

type SourceInfo = {
  type?: 'playlist' | 'station' | 'schedule'
}

// Copy of functions from capsule. Importing capsule in electron causes build issues.
// Business should hopefully be migrated to graphql soon so we can remove the need for this

function sourceInfo(
  source: im.Map<string, any> | object | undefined,
): SourceInfo {
  if (!source) {
    return {}
  }
  const type = (getIn(source, 'type') as string)?.toLowerCase()
  if (type !== 'collection' && type !== 'schedule' && type !== 'track') {
    return {}
  }
  const result: SourceInfo = {
    type: type as SourceInfo['type'],
  }
  if (type !== 'collection') {
    return result
  }
  result.type =
    getIn(source, 'collection_type') === 'soundtrack'
      ? 'station'
      : (getIn(source, 'collection_type') as SourceInfo['type'])

  return result
}

function image(track: im.Map<string, any>, preferredSize = 0) {
  return (
    (track.getIn(['album', 'images']) as im.List<im.Map<string, any>>)
      ?.reduce((best: im.Map<string, any>, candidate: im.Map<string, any>) =>
        Math.abs(candidate.get('width') - preferredSize) <
        Math.abs(best.get('width') - preferredSize)
          ? candidate
          : best,
      )
      .get('url') ||
    track.getIn(['display', 'image', 'sizes', 'thumbnail']) ||
    track.getIn(['album', 'display', 'image', 'sizes', 'thumbnail']) ||
    track.getIn(['album', 'album_image', 'url'])
  )
}
