const $$splitComponentImporter = () => import(/* webpackChunkName: "route-pairing-guide" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/(pairing-guide)/sonos.pair.v2.$householdId.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { ensureLoggedIn } from '#app/route-lib';
export const Route = createFileRoute('/(pairing-guide)/sonos/pair/v2/$householdId')({
  staticData: {
    meta: 'pairingGuide.pairing.meta',
    showNotifications: false
  },
  beforeLoad: ({
    location
  }) => {
    ensureLoggedIn(location);
  },
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr)
});