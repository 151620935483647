import { parseId } from '@soundtrackyourbrand/apollo-client/keys'
import {
  type Identifiable,
  type ImmutableEntity,
  entityId,
} from '@soundtrackyourbrand/capsule/dist/utils/immutable'
import { getIn } from '@soundtrackyourbrand/object-utils.js'
import { redirect } from '@tanstack/react-router'
import base64 from './base64'
import type { IdentifiableEditorial, IdentifiableWithType } from './types'

export const cookieDomain = window.location.hostname
  .split('.')
  .slice(-2)
  .join('.')

export function isActive(
  props: { location: { pathname: string } },
  ...routes: string[]
): boolean {
  const regex = new RegExp('/(' + routes.join('|') + ')(?:/|$)')
  return regex.test(props.location.pathname)
}

export function anonymizeIds(path: string): string {
  return path.replace(/\/([^/?]*[A-Z][^/]*)/g, '/{id}')
}

export function shareUrlFor(id: string): string {
  return window.location.origin + urlForId(id)
}

function resolveSoundtrackURI(uri: string) {
  switch (uri) {
    case 'soundtrack://SpotifyImportRedirect':
      // "import from spotify" banner
      return '/create/copy-spotify-playlist'
    case 'soundtrack://MarvinPlaylistModal':
      return '/create/ai-playlist'
    default:
      return null
  }
}

/**
 * Convert an editorial entity URI / syb-core legacy id to the corresponding /discover URL.
 */
export function urlForId(uri: string): string {
  const resolvedURI = resolveSoundtrackURI(uri)
  if (resolvedURI) return resolvedURI

  const parts = uri.split(':')
  if (parts[0] === 'soundtrack') {
    parts.shift()
  } else if (parts.length === 1) {
    try {
      const kind = parseId(uri).__typename
      switch (kind) {
        case 'Playlist':
          parts.unshift('music')
          break
        case 'Schedule':
          parts.unshift('schedule')
          break
        case 'Album':
          parts.unshift('release')
          break
      }
    } catch (e) {
      // Ignore
    }
  }
  return '/discover/' + parts.join('/')
}

export function urlFor(
  item: IdentifiableWithType,
  account?: Identifiable | false | null | undefined,
): string

export function urlFor(
  item: null | undefined,
  account?: Identifiable | false | null | undefined,
): undefined

/**
 * Generate URL to the specified `item`, supporting all Editorial/music related entities.
 *
 * URL starts with /discover/*, unless `account` is provided in which case it
 * may point towards /accounts/$accountId/your-music/* if the given item can also be
 * viewed from the account's library.
 */
export function urlFor(
  item: IdentifiableWithType | null | undefined,
  account?: Identifiable | false | null,
) {
  if (!item) {
    return undefined
  }
  return account ? urlForYourMusic(item, account) : urlForDiscover(item)
}

export function urlForDiscover(
  item: ImmutableEntity | IdentifiableEditorial,
): string {
  if (!(item as unknown)) {
    return ''
  }

  const id = getIn(item, ['id'])
  const shortId = getIn(item, ['shortId']) || shortIdFromUri(id)
  const type = getIn(item, ['__typename']) || getIn(item, ['type'])

  switch (type) {
    case 'Collection': // syb-core
    case 'Playlist':
      return `/discover/music/${id}`
    case 'Schedule':
      return `/discover/schedule/${id}`
    case 'Artist':
      return `/discover/artist/${id}`
    case 'Album':
      return `/discover/album/${shortId}`
    case 'Track':
      const albumId = shortIdFromUri(getIn(item, 'album.id'))
      return albumId ? `/discover/album/${albumId}#${id}` : ''
    case 'BrowseCategory':
      return `/discover/categories/${type}/${id}`
  }

  // Fallback URL
  return urlForId(id)
}

export function urlForSearch(query = '', section?: string): string {
  let suffix = query ? '/' + encodeURIComponent(query) : ''

  // Only add section if query is present
  if (query && section) {
    // `section` can be in format 'search:$query:section:$section'
    const idParts = section.split(':')
    if (idParts.length === 4) {
      suffix += `/${idParts[idParts.length - 1]}`
    } else if (section && idParts.length === 1) {
      suffix += '/' + section
    }
  }

  return `/search${suffix}`
}

export function urlForYourMusic(
  item: IdentifiableWithType,
  account: Identifiable,
) {
  const type = getIn(item, '__typename') || getIn(item, 'type')
  if (type !== 'Collection' && type !== 'Schedule') {
    return urlForDiscover(item)
  }
  const urlType = type === 'Schedule' ? 'schedules/' : ''
  return `/accounts/${entityId(account)}/your-music/${urlType}${entityId(item)}`
}

/** Strips leading `soundtrack:*:` prefixes to return the "short id" for an URI */
export function shortIdFromUri(uri: string): string | undefined {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return uri?.replace(/^soundtrack:[^:+]*:/i, '')
}

export function extractDomain(url: string) {
  try {
    const { hostname } = new URL(url)
    return hostname
  } catch (e) {
    return undefined
  }
}

export const ACCOUNT_ID_PLACEHOLDER = '_'

/** Updates the given path param if it needs padding */
export function ensurePaddedId<P extends Record<string, string>>(
  params: P,
  paramName: keyof P,
): void {
  const id = params[paramName]

  // Don't pad placeholder IDs
  if (!id || id === ACCOUNT_ID_PLACEHOLDER) {
    return
  }

  const paddedId = base64.pad(id)
  if (paddedId !== id) {
    throw redirect({
      // @ts-expect-error Specifying `to: '.'` causes an infinite redirect loop: https://github.com/TanStack/router/issues/2418
      // And since it's mainly there for typesafety, we're swallowing this error until the above issue is fixed
      params: (curr) => ({ ...curr, [paramName]: paddedId }),
    })
  }
}
