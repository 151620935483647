import { z } from 'zod'

// A list of all the types that can either be dragged or act as drop targets.
export const DNDTypeSchema = z.enum([
  'Track',
  'Playlist',
  'Description',
  'Schedule',
  'Library',
  'SpotifyPlaylists',
])

export type DNDItemType = z.infer<typeof DNDTypeSchema>
export type DNDItem = { __typename: DNDItemType; id: string }
export type MaybeDNDItem = { __typename: string; id?: string | null }

/**
 * This a map between draggable types and custom MIME types that is used to identify the type of data being dragged.
 * When an item is being dragged, the browser will send the MIME type to the drop target.
 *
 * The description MIME type is used to identify the description of a track or playlist so we can display
 * the description in the drag preview.
 */
export const mappedMimeType = {
  Track: 'text/x.soundtrack-track',
  Playlist: 'text/x.soundtrack-playlist',
  // Spotify
  SpotifyPlaylists: 'text/x-spotify-playlists',
  /**
   * This MIME type is used to identify the description of a track or playlist so we can display
   * the description in the drag preview.
   */
  Description: 'text/x.soundtrack-description',
} as const

/**
 * Acts as a reverse lookup for the MIME type of a draggable type.
 */
export function getTypeFromMimeType(
  mimeType: string,
): keyof typeof mappedMimeType | undefined {
  for (const type in mappedMimeType) {
    if (mappedMimeType[type] === mimeType) {
      return type as keyof typeof mappedMimeType
    }
  }
}
