import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import * as React from 'react';
const FALLBACK_BREAKPOINT = {
    min: 0,
    max: Infinity,
    mql: null,
};
const BreakpointContext = React.createContext(FALLBACK_BREAKPOINT);
BreakpointContext.displayName = 'BreakpointContext';
/**
 * Allows different content to be rendered depending on active breakpoint.
 * Must be inside of a <Breakpoint.Provider /> context.
 *
 * Expects a function as child prop, which will be called with the starting
 * width of the active breakpoint (0 for smallest breakpoint).
 *
 * The provided function should then render content depending on the active
 * breakpoint, for example:
 *
 * <Breakpoint>
 *   {w => (w >= 720) ? <DesktopComponent /> : <MobileComponent />}
 * </Breakpoint>
 */
export const Breakpoint = Object.assign(withBreakpoint(null), {
    Provider: BreakpointProvider,
    withBreakpoint,
    useBreakpoint,
});
BreakpointProvider.displayName = 'BreakpointProvider';
BreakpointProvider.propTypes = {
    breaks(props, propName, componentName) {
        const value = props[propName];
        if (!Array.isArray(value) ||
            !value.length ||
            !value.every((x) => typeof x === 'number' && Math.floor(x) === x)) {
            return new Error(`'${propName}' must be an sorted array of integers (1 or more) in ${componentName}`);
        }
    },
};
/**
 * Context provider for useBreakpoint / <Breakpoint>.
 * Doesn't do or render anything by itself, but attaches the media query
 * listeners which in turn triggers <Breakpoint> updates.
 *
 * Example usage:
 * <Breakpoint.Provider breaks={[400, 720, 1000]}>
 *   <Breakpoint>
 *     {w => (w >= 720) ? <DesktopComponent /> : <MobileComponent />}
 *   </Breakpoint>
 * </Breakpoint.Provider>
 */
export function BreakpointProvider({ breaks, children }) {
    const breaksString = breaks.join(',');
    const checksRef = React.useRef({});
    const [active, setActive] = React.useState(() => {
        const checks = {};
        let min = 0;
        let current = FALLBACK_BREAKPOINT;
        breaksString
            .split(',')
            .map(Number)
            .concat(Number.MAX_VALUE)
            .forEach((max) => {
            const maxWidth = max - 1;
            const mql = window.matchMedia(widthQueryString({ min, max: maxWidth }));
            const breakpoint = { min, max: maxWidth, mql };
            checks[mql.media] = breakpoint;
            if (mql.matches) {
                current = breakpoint;
            }
            min = max;
        });
        checksRef.current = checks;
        return current;
    });
    React.useLayoutEffect(() => {
        const onUpdate = (event) => {
            if (event.matches && checksRef.current[event.media]) {
                setActive(checksRef.current[event.media]);
            }
        };
        // Add listeners to all breakpoints
        Object.values(checksRef.current).forEach(({ mql }) => {
            // Safari <14 doesn't support `addEventListener`, only `addListener`
            if (mql?.addEventListener) {
                mql.addEventListener('change', onUpdate);
            }
            else {
                mql?.addListener(onUpdate);
            }
        });
        return () => {
            Object.values(checksRef.current).forEach(({ mql }) => {
                if (mql?.removeEventListener) {
                    mql.removeEventListener('change', onUpdate);
                }
                else {
                    mql?.removeListener(onUpdate);
                }
            });
        };
    }, [breaksString]);
    return _jsx(BreakpointContext.Provider, { value: active, children: children });
}
/**
 * Creates a component wrapper which re-renders when the active breakpoint
 * changes (defined by an ancestor BreakpointProvider).
 *
 * All props with be passed along to the WrappedComponent, along with the
 * active breakpoint through the specified `propName`.
 *
 * Omitting WrappedComponent generates a component which uses render callbacks,
 * which is utilizing to generate the default export <Breakpoint>.
 * See https://reactpatterns.com/#render-callback for more info.
 *
 * @param {function} [WrappedComponent] - React component to wrap,
 * @param {string} [propName='breakpoint'] - Property name to inject active breakpoint value as
 * @return {function} Breakpoint-aware React component
 */
export function withBreakpoint(WrappedComponent, propName = 'breakpoint') {
    // Omitting WrappedComponent generates a component which uses render callbacks
    const isHOC = WrappedComponent !== null;
    const propTypes = {};
    if (!isHOC) {
        propTypes.children = PropTypes.func.isRequired;
    }
    const displayName = isHOC && WrappedComponent.displayName
        ? `Breakpoint(${WrappedComponent.displayName})`
        : 'Breakpoint';
    return class Breakpoint extends React.Component {
        static displayName = displayName;
        static propTypes = propTypes;
        static contextType = BreakpointContext;
        render() {
            const breakpoint = this.context.min;
            if (isHOC) {
                return React.createElement(WrappedComponent, {
                    ...this.props,
                    [propName]: breakpoint,
                });
            }
            // Ignore typescript error about `children` possibly being undefined
            // @ts-ignore
            return this.props.children(breakpoint);
        }
    };
}
/**
 * Hook that returns the active breakpoint, updated whenever it changes.
 * Requires a <BreakpointProvider> in the React component hierarchy.
 *
 * @return Active breakpoint
 */
export function useBreakpoint() {
    return React.useContext(BreakpointContext).min;
}
/**
 * Generates a CSS @media query string for a given width interval;
 * (min-max), (min) or (max).
 *
 * @param bounds - Min and max widths
 * @return Valid CSS @media query string
 */
function widthQueryString({ min, max }) {
    const query = [];
    if (min > 0) {
        query.push(`(min-width: ${min}px)`);
    }
    if (max < Number.MAX_VALUE) {
        query.push(`(max-width: ${max}px)`);
    }
    if (!query.length) {
        throw new Error('widthQueryString: Invalid values for min/max');
    }
    return query.join(' and ');
}
