const $$splitLoaderImporter = () => import(/* webpackChunkName: "accounts-zones" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/zones/route.tsx?tsr-split');
import { lazyFn } from '@tanstack/react-router';
const $$splitComponentImporter = () => import(/* webpackChunkName: "accounts-zones" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/accounts.$accountId/zones/route.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { fallback, zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';
import ChangeMusic from '#app/components/change-music';
import ApplyDefaultsModal from '#app/components/zone/apply-defaults-modal';
export const SEARCH_DEFAULTS = {
  page: 1,
  perPage: 20
};
const searchSchema = z.object({
  region: fallback(z.string(), '').optional(),
  q: z.coerce.string().optional().catch(undefined),
  status: z.enum(['OK', 'ERROR', 'INACTIVE', 'NOT_SETUP']).optional().catch(undefined),
  page: z.number().positive().optional().catch(undefined),
  perPage: z.number().min(1).max(100).optional().catch(undefined)
});
export const Route = createFileRoute('/accounts/$accountId/zones')({
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr),
  validateSearch: zodSearchValidator(searchSchema),
  staticData: {
    meta: 'zones.meta',
    accountSwitch: '~/zones'
  },
  loader: lazyFn($$splitLoaderImporter, 'loader')
});
export const ZONE_ACTIONS = ['assignMusic', 'applyDefaultSettings'];
export const ACTION_COMPONENTS = {
  assignMusic: ChangeMusic,
  applyDefaultSettings: ApplyDefaultsModal
};
export const BULK_USAGE_CONTEXT = {
  Context: 'Zones Bulk Actions'
};