const $$splitComponentImporter = () => import(/* webpackChunkName: "route-partner-portal" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/partner-portal/route.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { ensureLoggedIn } from '#app/route-lib';
export const Route = createFileRoute('/partner-portal')({
  staticData: {
    meta: 'partnerPortal.dashboard.meta',
    accountSwitch: '/partner-portal/$accountId'
  },
  beforeLoad: async ({
    location
  }) => {
    ensureLoggedIn(location);
  },
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr)
});