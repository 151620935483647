const $$splitComponentImporter = () => import(/* webpackChunkName: "route-user" */'tsr-split:/home/runner/work/js/js/apps/business/app/routes/user/route.tsx?tsr-split');
import { lazyRouteComponent } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';
import { ensureLoggedIn } from '#app/route-lib';
export const Route = createFileRoute('/user')({
  staticData: {
    meta: 'user.settings.meta'
  },
  beforeLoad: ({
    location
  }) => {
    ensureLoggedIn(location);
  },
  component: lazyRouteComponent($$splitComponentImporter, 'component', () => Route.ssr)
});